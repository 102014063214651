import { API_HOST, API_ALL_RECRUITERS_ADMINISTRATOR, API_ONE_RECRUITER_ADMINISTRATOR } from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import { ALL_RECRUITERS_ADMIN, ONE_RECRUITER_ADMIN } from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list recruiters for Admin */
const getAllRecruiters = (dispatch) => () => {
    // URL get list recruiters for Admin
    const URL = API_HOST + API_ALL_RECRUITERS_ADMINISTRATOR;
    // Call API list recruiters for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({
                type: ALL_RECRUITERS_ADMIN,
                payload: res.data,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste Entreprise s" }
            });
        }
    );
}

/* Get detail recruiter for Admin */
const getOneRecruiter = (dispatch) => (id) => {
    // URL get detail recruiter for Admin
    const URL = API_HOST + API_ONE_RECRUITER_ADMINISTRATOR + id;
    // Call API detail recruiter for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ONE_RECRUITER_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de recupérer détail Entreprise " }
            });
        }
    );
}


/* Export Action Recruiters Profils Administrator */
export { getAllRecruiters, getOneRecruiter };