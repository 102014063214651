import React, {useContext, useEffect} from "react";
import PasswordForm from "../../components/PasswordForm/PasswordForm";
import '../Login/Login.scss'
import Header from "../../components/Header/Header";
import {useParams} from "react-router-dom";
import {Context as forgetContext} from '../../context/reducers/AuthReducer'
import ToastService from "../../components/Toast/ToastService";
export default function ResetPassword() {
    const {token}=useParams()
    const {hideToast,resetPassword, state} = useContext(forgetContext)
    /* Hide Alert */
    useEffect(() => {
        if (state.toastContent) hideToast();
    }, [hideToast, state.toastContent]);
    return (
        <div className="loginPage">
            <div className="LoginSection">
                <Header />
                <ToastService data={state.toastContent}/>
                <div className="container-fluid mt-5 mb-5">
                    <div className="d-lg-flex flex-lg-row flex-md-wrap flex-sm-wrap flex-sm-column  mt-5 pl-3">
                        <div className="col-md-12 col-sm-12 col-lg-6 col-xl-6 col-xxl-6  d-flex flex-column  align-items-center mt-5">
                            <div className="col-md-12 col-lg-10 col-xl-10 col-sm-12  text-justify   mt-4">
                                <p className="astro-text  roboto-meduim text-uppercase">Pour la réinitialisation de votre mot de passe</p>
                            </div>
                            <div
                                className="col-md-10 col-lg-8 col-xl-8 col-sm-10  text-justify d-flex flex-column  align-items-center mb-4 mt-1">
                                <p className="it-Description fontSize-25 roboto-light">veuillez saisir votre nouveau mot de passe</p>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5 col-xl-6 col-xxl-6 col-sm-12  mt-sm-3 mb-md-5 p-0 ">
                            <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-8 col-sm-12  mt-sm-3 mb-md-5 ">
                                <PasswordForm token={token} ResetPassword={resetPassword}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
