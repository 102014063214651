import { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { FaMapMarkerAlt, FaRegCalendarMinus } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import ModalUploadContract from "../../../components/UploadContract/UploadContact";
import { Context as DocumentContext } from "../../../context/reducers/DocumentReducer";
import {
  getUserId,
  isAdmin,
  isCosmonaute,
  isRecruiter,
} from "../../../services/Controller/CurrentUserServices";
import {
  formatDate,
  formatDateTime,
  getDuration,
} from "../../../services/Controller/DateFormatter";
import ToastService from "../../../components/Toast/ToastService";
import ModalUploadCra from "../../../components/UploadCra/UploadCra";
import Paginate from "../../../components/Paginate/Paginate";
function DetailsContart() {
  const {
    state,
    getOneMission,
    getContract,
    addContract,
    addCra,
    dumpCra,
    importContratRecruiter,
    getMissionDetail,
    envoieMailCraContrat,
    envoieMailInvoiceContrat,
    getMissionDetailAdmin,
    sendInvoices,
    hideToast,
  } = useContext(DocumentContext);
  const history = useHistory();
  const [shwoModal, setShowModal] = useState(false);
  const [listeMission, setListeMission] = useState(null);
  const [craShow, setCraShow] = useState(false);
  const [classActive, setClassActive] = useState("display-button");
  const [listContrat, setListContrat] = useState(null);
  const [classinActive, setClassinActive] = useState("display-button");
  const [oneMission, setOneMission] = useState(null);
  const [disable, setDisable] = useState(false)
  const [disableVoices, setDisableVoices] = useState(false)
  const [currentItems, setCurrentItems] = useState([]);
  const lengthContrat =
    listContrat && listContrat.contrats ? listContrat.contrats.length : 0;
  const lengtgthRecruiter =
    oneMission && oneMission.contrat ? oneMission.contrat.length : 0;
  /* use Location */
  const location = useLocation();
  const idMission = location.pathname.split("/")[3];
  const name = location.pathname.split("/")[2];
  const reload = false;
  /* eslint-disable */
  useEffect(() => {
    const idMission = location.pathname.split("/")[5];
    const idAdmin = location.pathname.split("/")[5];
    const idUser = location.pathname.split("/")[3];
    //getAllMissions();
    if (isAdmin() && name === "user") {
      getContract(idMission, idUser);
    } else if (isCosmonaute()) {
      getContract(idUser, getUserId());
    }
    if(reload === true) {
      if (isAdmin() && name === "user") {
        getContract(idMission, idUser);
      } else if (isCosmonaute()) {
        getContract(idUser, getUserId());
      }
    }
    //getDocumentRecruter();
    if (isCosmonaute()) {
      getOneMission(idUser);
    } else if (isRecruiter()) {
      getMissionDetail(idUser);
    } else if (isAdmin()) {
      getOneMission(idAdmin);
      getMissionDetailAdmin(idAdmin, idUser);
    }
  }, []);
  /* eslint-enable */
  useEffect(() => {
    if (isCosmonaute()) {
      setListeMission(state.oneMission);
      setListContrat(state.docContract);
    } else if (isRecruiter()) {
      setOneMission(state.missionDetail);
    } else if (isAdmin()) {
      setListeMission(state.oneMission);
      setListContrat(state.docContract);
      setOneMission(state.detailMissionAdmin);
    }
  }, [state, name]);
  /* refresh list docs */
  useEffect(() => {
    if (state.cra) {
      const idAdmin = location.pathname.split("/")[5];
      let idUser = location.pathname.split("/")[3];
      let id = getUserId()
      getContract(idUser, id);
      getMissionDetailAdmin(idAdmin, idUser);
      getMissionDetail(idUser)
      dumpCra()
    }
  }, [state.cra,location.pathname,getContract,dumpCra,getMissionDetailAdmin,getMissionDetail]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* dispaly button if contract empty else display contract*/
  const dispalyRow = () => {
    if (isCosmonaute() || (isAdmin() && name === "user")) {
      if (lengthContrat > 0) {
        setClassActive("display-button-import");
        setClassinActive("");
        setDisable(false);
      } else {
        setClassinActive("display-button-import");
        setClassActive("");
        setDisable(true);
      }
    } else if (isRecruiter() || (isAdmin() && name === "recruted")) {
      if (lengtgthRecruiter !== 0) {
        setClassActive("display-button-import");
        setClassinActive("");
        setDisableVoices(false);
      } else {
        setClassinActive("display-button-import");
        setClassActive("");
        setDisableVoices(true);
      }
    }
  };
  useEffect(() => {
    dispalyRow();
  });
  function DisplayBlockUser() {
    return (
      <div className={"col-lg-6 col-md-12 col-sm-12 "}>
        <div className="card-content mb-45 mr-2">
          <div>
            <Row className="mb-2">
              <Col lg={10} md={10} xs={10}>
                <label className="text-header-cosmonaute mr-20">
                  {listeMission ? listeMission.title : "..."}
                </label>
                <label className="text-tag blue">
                  {getDuration(
                    listeMission ? listeMission.date_start : null,
                    listeMission ? listeMission.date_end : null
                  )}
                </label>
              </Col>
            </Row>
            <Row>
              <div className="text-detail d-flex ">
                <FaRegCalendarMinus />
                <span className="ml-10 mr-20">
                  <p>
                    date de début :
                    {formatDateTime(
                      listeMission ? listeMission.date_start : null
                    ) + " "}
                    - date de fin :{" "}
                    {formatDateTime(
                      listeMission ? listeMission.date_end : null
                    )}
                  </p>
                </span>
              </div>
              <div className="text-detail  d-flex ">
                <FaMapMarkerAlt />
                <span className="ml-10 mr-20">
                  <p>Lieu: {listeMission ? listeMission.place : "..."}</p>
                </span>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  function DisplayBlockRecruiter() {
    return (
      <div className={"col-lg-6 col-md-12 col-sm-12 "}>
        <div className="card-content mb-45 mr-2">
          <div>
            <Row className="mb-2">
              <Col lg={10} md={10} xs={10}>
                <label className="text-header-cosmonaute mr-20">
                  {oneMission ? oneMission.mission_title : "..."}
                </label>
                <label className="text-tag blue">
                  {getDuration(
                    oneMission ? oneMission.dateStart : null,
                    oneMission ? oneMission.dateEnd : null
                  )}
                </label>
              </Col>
            </Row>
            <Row>
              <div className="text-detail d-flex ">
                <FaRegCalendarMinus />
                <span className="ml-10 mr-20">
                  <p>
                    date de début :
                    {formatDate(oneMission ? oneMission.dateStart : null) + " "}
                    - date de fin :{" "}
                    {formatDate(oneMission ? oneMission.dateEnd : null)}
                  </p>
                </span>
              </div>
              <div className="text-detail  d-flex ">
                <FaMapMarkerAlt />
                <span className="ml-10 mr-20">
                  <p>Lieu: {oneMission ? oneMission.place : "..."}</p>
                </span>
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  function DisplayBlockImportContrat() {
    return (
      <div className="border-div-contract mb-5">
        <p className="title">
          {" "}
          {isAdmin() ? "Envoyer le contrat à signer" : "Déposer ou consulter votre contrat signé."}
        </p>
        <p className="text">
        Le contrat est un engagement ou une convention qui crée des
          obligations bien définies entre vous et Inspire talents.
        </p>
        <div className={classinActive + " margin-top-bottom-contrat"}>
          <div className="float-left text-contract-actif">
            <a
              target="_blank"
              rel="noreferrer noopener"
              media="true"
              href={
                listContrat && listContrat.contrats
                  ? listContrat.contrats[0].doc_url
                  : ".."
              }
            >
              {listContrat && listContrat.contrats
                ? listContrat.contrats[0].doc_name
                : ".."}
            </a>
          </div>
          <div className="float-right text-right-active">
            {listContrat && listContrat.contrats
              ? (listContrat.contrats[0]?.date_updat)
              : ".."}
          </div>
        </div>
        <Button
          className={"button-import margint-top-user " + classActive}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {isAdmin() ? "Envoyer" : "Importer"}
        </Button>
      </div>
    );
  }
  function DisplayBlockImportContratRecruiter() {
    return (
      <div className="border-div-contract mb-5">
        <p className="title">{isAdmin() ? "Envoyer le contrat à signer" : "Déposer ou consulter votre contrat signé."}</p>
        <p className="text">
          Le contrat est un engagement ou une convention qui crée des
          obligations bien définies entre vous et Inspire talents.
        </p>
        <div className={classinActive + " margin-bottom-doc"}>
          <div className="float-left text-contract-actif">
            <a
              target="_blank"
              rel="noreferrer noopener"
              media="true"
              href={
                oneMission && oneMission.contrat
                  ? oneMission.contrat.doc_url
                  : ".."
              }
            >
              {oneMission && oneMission.contrat
                ? oneMission.contrat.doc_name
                : ".."}
            </a>
          </div>
          <div className="float-right text-right-active">
            {oneMission && oneMission.contrat
              ? (oneMission.contrat.date_updat) : ".."}
          </div>
        </div>
        <Button
          className={"button-import " + classActive}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {isAdmin() ? "Envoyer" : "Importer"}
        </Button>
      </div>
    );
  }
  /*admin import factures */
  function BlockSendVoicesAdmin() {
    return (
      <div className="border-div-contract">
        <p className="title">{isAdmin() ? "Envoyer une facture" : "Contrat"}</p>
        <p className="text">La facture représente le document de comptabilité générale par excellence. Elle implique à sa réception l'obligation de payer à échéance.</p>
        <Button
          disabled={disableVoices}
          className={"button-import"}
          onClick={() => {
            setCraShow(true);
          }}
        >
          {isAdmin() ? "Envoyer" : "Importer"}
        </Button>
      </div>
    );
  }
  function DisplayBlockImportCra() {
    return (
      <div className="border-div-contract">
        <p className="title">
          {isAdmin()
            ? "Envoyer le modèle de CRA"
            : "Déposer votre CRA pour ce mois"}
        </p>
        <div className="text">
          <div>
            Un CRA est rempli par l'inspirnaute; il a pour objectif de rendre
            des comptes, de faire le point sur l’activité du mois passé, des
            tâches accomplies et du temps passé, auxquels s’ajoutent des
            remarques éventuelles.
          </div>
        </div>
        <Button
          className="button-import"
          onClick={() => {
            setCraShow(true);
          }}
          disabled={disable}
        >
          {isAdmin() ? "Envoyer" : "Importer"}
        </Button>
      </div>
    );
  }
  function DisplayHistorique() {
    return (
      <Row>
        <Row className="title-table-contrat mt-5 mb-4">
          {isAdmin()
            ? "Consulter les CRA du candidat"
            : "Consulter votre Historique"}
        </Row>
        <Row>
          <Table className="table user-table">
            <thead>
              <tr>
                <th>CRA</th>
                <th>Date</th>
                <th>Taille</th>
              </tr>
            </thead>
            <tbody>
              {listContrat && listContrat.cras && listContrat.cras.length ? (
                <>
                  {listContrat.cras.map((item, index) => (
                    <tr key={index}>
                      <td className="text-contract-actif color-cra padding-top-bottom-td">
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          media="true"
                          href={item.doc_url}
                        >
                          {item ? item.doc_name : "..."}
                        </a>
                      </td>
                      <td className="padding-top-bottom-td">
                        {item ? (item.date_updat) : "..."}
                      </td>
                      <td className="padding-top-bottom-td">
                        {item ? item.doc_size + " ko" : "..."}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr ><td className="empty-block">Aucun CRA à afficher</td></tr>
              )}
            </tbody>
          </Table>
        </Row>
      </Row>
    );
  }
// list invoices recruiter
  function getListInvoices() {
    return currentItems.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-font-td pt-4 pb-4">
            <a
              target="_blank"
              rel="noreferrer noopener"
              media="true"
              href={item.doc_url}
            >
              {item ? item.doc_name : "..."}
            </a>
          </td>
          <td className="pt-4">
            {item ? (item.date_updat) : "..."}
          </td>
          <td className="pt-4">
            {item ? item.doc_size + " ko" : "..."}
          </td>
        </tr>
      )
    })
  }
  function DisplayFacturesRecruiter() {
    return (
      <div className="border-col-invoices margin-left-col1">
        <div>
          <div className="text-mission-document color-1 mb-4">Factures</div>
          <Table className="table user-table">
            <thead>
              <tr>
                <th>Facture</th>
                <th>Date</th>
                <th>Taille</th>
              </tr>
            </thead>
            <tbody>
              {oneMission && oneMission.invoices?.length ? 
                  getListInvoices()
               :<tr ><td className="empty-block">Aucune Facture à afficher</td></tr>}
            </tbody>
          </Table>
          {!oneMission || !oneMission.invoices.length ? (
              null
            ) : (
              <Paginate
                items={oneMission.invoices}
                setCurrentItems={setCurrentItems}
                itemsPerPage={4}
              />
            )}
        </div>
      </div>
    );
  }
  return (
    <>
      <ToastService data={state.toastContent} />
      <ModalUploadContract
        modalShow={shwoModal}
        setShowModal={setShowModal}
        id={idMission}
        addContract={addContract}
        addCra={addCra}
        importContratRecruiter={importContratRecruiter}
        idRecruiter={idMission}
        envoieMailInvoiceContrat={envoieMailInvoiceContrat}
        reload={reload}
        name={name}
        envoieMailCraContrat={envoieMailCraContrat}
      />
      <ModalUploadCra
        craShow={craShow}
        setCraShow={setCraShow}
        addCra={addCra}
        id={listContrat?.id}
        idCosmonaute={idMission}
        envoieMailCraContrat={envoieMailCraContrat}
        reload={reload}
        name={name}
        sendInvoices={sendInvoices}
        savedContratId={oneMission?.savedContratId}
      />
      <div id="header-Content" className="header-Content"></div>
      <div id="main" className="main">
        <div
          className="arrow-back-mission pointer mb-3"
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="icon-back-arrow"></i>
        </div>
        {isRecruiter() ? DisplayBlockRecruiter() : DisplayBlockUser()}
        <Row lg={12} md={12} xs={12} className="display-row-admin">
          <Col lg={6} md={6} xs={12}>
            {isCosmonaute() || (isAdmin() && name === "user")
              ? DisplayBlockImportContrat()
              : DisplayBlockImportContratRecruiter()}
          </Col>
          <Col lg={6} md={6} xs={12}>
            {isRecruiter()
              ? DisplayFacturesRecruiter()
              : isCosmonaute()
              ? DisplayBlockImportCra()
              : isAdmin() && name === "user"
              ? DisplayBlockImportCra()
              : BlockSendVoicesAdmin()}
          </Col>
        </Row>
        {isRecruiter()
          ? null
          : isCosmonaute()
          ? DisplayHistorique()
          : isAdmin() && name === "user"
          ? DisplayHistorique()
          : DisplayFacturesRecruiter()}
      </div>
    </>
  );
}
export default DetailsContart;
