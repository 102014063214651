import React, { useEffect } from "react";
import "./ToastService.scss";
import { toast } from "react-toastify";
// import { ToastContainer} from 'react-toastify'; // pour desactiver tous les toasts jusqu'à nouvel ordre
import "react-toastify/dist/ReactToastify.css";

export default function ToastService({ data }) {
  useEffect(() => {
    if (data && data.status === "success") toast.success(data.body);
    else if (data && data.status === "error") toast.error(data.body);
    else if (data && data.status === "warning") toast.warn(data.body);
    else if (data && data.status === "information") toast.info(data.body);
  }, [data]);
  return (
    <>
      {/* // pour desactiver tous les toasts  */}
      {/* <ToastContainer theme="colored" autoClose={3000} /> */}
    </>
  );
}
