import "./ExperienceCosmo.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FaTrashAlt } from 'react-icons/fa';
import { useFormik, FieldArray, Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { Context as CosmonauteContext } from "../../../../../context/reducers/CosmonauteReducer";
import { ErrorFeild, ErrorClass, ErrorArrayFeild, ErrorArrayClass } from '../../../../../services/Controller/ValidationForm';
import { formatDate } from '../../../../../services/Controller/DateFormatter';

/* validation Form add experience */
const validationCosmonauteExperience = Yup.object({
    poste: Yup.string().required("Poste est obligatoire"),
    entreprise: Yup.string().required("Nom de l'entreprise est obligatoire"),
    toDate: Yup.date(),
    fromDate: Yup.date()
        .when("toDate", (toDate, schema) => toDate && schema.max(toDate, "La date de fin ne peut pas être antérieure à la date de début"))
        .required('Date début est obligatoire'),
    description: Yup.string().required("Description de l'éxperience est obligatoire"),
});

/* validation Form Edit experience */
const validationCosmonauteEditExperience = Yup.object({
    wrokexperiences: Yup.array().of(
        Yup.object({
            poste: Yup.string().required("Poste est obligatoire"),
            entreprise: Yup.string().required("Nom de l'entreprise est obligatoire"),
            toDate: Yup.date().nullable(),
            fromDate: Yup.date().required('Date début est obligatoire'),
            description: Yup.string().required("Description de l'éxperience est obligatoire"),
        })
    ),
})

export default function ExperienceCandidate({ allSkills, cosmonauteExperiences, id }) {
    /* Context API */
    const { saveExperience, deleteExperience } = useContext(CosmonauteContext);

    /* States */
    const [showExperience, setshowExperience] = useState(null);

    /* Form Add experience */
    const ExperinceForm = useFormik({
        initialValues: {
            id: null,
            poste: "",
            entreprise: "",
            fromDate: "",
            toDate: "",
            description: "",
            skills: []
        },
        validationSchema: validationCosmonauteExperience,
        onSubmit: (values) => {
            let allWorks = ExperinceFormEdit.values.wrokexperiences;
            if (allWorks && allWorks.length) allWorks.push(values);
            else allWorks = [values];
            let data = allWorks.map((el) => {
                return {
                    ...el,
                    skills: el.skills.map((el) => el.value)
                }
            });
            saveExperience(data, id);
            setshowExperience(null);
            ExperinceForm.resetForm();
        },
    });

    /* Array Form Experinces */
    const ExperienceFormArray = cosmonauteExperiences && cosmonauteExperiences.length
        ? cosmonauteExperiences.map((work) => ({
            ...{
                id: work.id,
                poste: work.poste,
                entreprise: work.entreprise,
                fromDate: work.from_date ? formatDate(work.from_date) : null,
                toDate: work.to_date ? formatDate(work.to_date) : null,
                description: work.description,
                skills: work.skills ? work.skills.map((el) => { return { value: el.id, label: el.name } }) : []
            },
        }))
        : null;

    /* Edit Work Form Array */
    const ExperinceFormEdit = useFormik({
        enableReinitialize: true,
        initialValues: {
            wrokexperiences: ExperienceFormArray,
        },

        validationSchema: validationCosmonauteEditExperience,
        onSubmit: (values) => {
            let data = values.wrokexperiences.map((el) => {
                return {
                    ...el,
                    skills: el.skills.map((el) => el.value)
                }
            });
            saveExperience(data, id);
            setshowExperience(null);
        },
    });

    /* Experience Add Form */
    function addFormExperiences() {
        return (
            <>
                {/* Add Experience */}
                <Form className="mb-5" onSubmit={ExperinceForm.handleSubmit}>
                    <Button variant="primary" type="submit"
                        className="shadow-unset float-right save-button border-dark">
                        Sauvegarder
                    </Button>
                    <label className="text-header-cosmonaute mb-4">Ajouter une nouvelle expérience</label>
                    <Form.Group className="formGroup mb-2">
                        <Form.Label>Poste</Form.Label>
                        <Form.Control className={ErrorClass(ExperinceForm, "poste")}
                            type="text" name="poste" id="poste"
                            placeholder="Entrez votre poste actuel"
                            onChange={ExperinceForm.handleChange}
                            value={ExperinceForm.values.poste}
                            onBlur={ExperinceForm.handleBlur} />
                        {ErrorFeild(ExperinceForm, "poste")}
                    </Form.Group>
                    {/* Entreprise */}
                    <Form.Group className="formGroup mb-2">
                        <Form.Label>Entreprise</Form.Label>
                        <Form.Control className={ErrorClass(ExperinceForm, "entreprise")}
                            type="text" name="entreprise" id="entreprise"
                            placeholder="Entrez le nom de la société"
                            onChange={ExperinceForm.handleChange}
                            value={ExperinceForm.values.entreprise}
                            onBlur={ExperinceForm.handleBlur} />
                        {ErrorFeild(ExperinceForm, "entreprise")}
                    </Form.Group>
                    <Row className="mb-2">
                        <Col>
                            <label className="period-edit">Période :</label>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-muted">À partir de : </Form.Label>
                                        <DatePicker
                                            id="fromDate" name="fromDate"
                                            className={ErrorClass(ExperinceForm, "fromDate")}
                                            selected={ExperinceForm.values.fromDate ? new Date(ExperinceForm.values.fromDate) : ""}
                                            onChange={(date) => ExperinceForm.setFieldValue("fromDate", date ? date : "")}
                                            onBlur={ExperinceForm.handleBlur}
                                            dateFormat={"MM/yyyy" || null} locale={fr}
                                            showMonthYearPicker
                                        />
                                        {ErrorFeild(ExperinceForm, "fromDate")}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-muted"> Jusqu'à :</Form.Label>
                                        <DatePicker
                                            id="toDate" name="toDate"
                                            className={ErrorClass(ExperinceForm, "toDate")}
                                            selected={ExperinceForm.values.toDate ? new Date(ExperinceForm.values.toDate) : ""}
                                            onChange={(date) => ExperinceForm.setFieldValue("toDate", date ? date : "")}
                                            onBlur={ExperinceForm.handleBlur}
                                            dateFormat={"MM/yyyy" || null} locale={fr}
                                            showMonthYearPicker
                                        />
                                        {ErrorFeild(ExperinceForm, "toDate")}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Skills */}
                    <Form.Group className="formGroup mb-2">
                        <Form.Label>Environnement technique</Form.Label>
                        <Select isMulti isSearchable placeholder=""
                            className={ErrorClass(ExperinceForm, 'skills')}
                            name="skills" options={allSkills}
                            closeMenuOnSelect={true}
                            value={ExperinceForm.values.skills}
                            onChange={(event) => {
                                ExperinceForm.setFieldValue("skills", event);
                            }}
                        />
                        {ErrorFeild(ExperinceForm, "skills")}
                    </Form.Group>
                    {/* Description */}
                    <Form.Group className="formGroup mb-2">
                        <Form.Label>Description</Form.Label>
                        <Form.Control className={ErrorClass(ExperinceForm, "description")}
                            as="textarea" rows={3} id="description" name="description"
                            placeholder="Écrivez sur votre règle et votre responsabilité"
                            onChange={ExperinceForm.handleChange}
                            value={ExperinceForm.values.description}
                            onBlur={ExperinceForm.handleBlur} />
                        {ErrorFeild(ExperinceForm, "description")}
                    </Form.Group>
                </Form>
            </>
        )
    }

    /* Experience Edit Form */
    function editFormExperiences() {
        return (
            < Formik onSubmit={ExperinceFormEdit.handleSubmit}>
                <FieldArray name="wrokexperiences"
                    render={(arrayHelpers) =>
                        ExperinceFormEdit.values.wrokexperiences &&
                        ExperinceFormEdit.values.wrokexperiences.length &&
                        ExperinceFormEdit.values.wrokexperiences.map((work, index) =>
                            arrayFormExperience(work, index)
                        )
                    }
                />
            </Formik>
        )
    }

    /* Edit Experience Form */
    const arrayFormExperience = (formExp, index) => {
        return (
            <div className="my-3" key={'Experience' + index}>
                <div className="border-top">
                    <div className="mt-3"></div>
                    <label className="text-roboto-bold blue">
                        {
                            ExperienceFormArray && ExperienceFormArray[index] && ExperienceFormArray[index].poste
                                ? ExperienceFormArray[index].poste
                                : "- - -"
                        }
                    </label>
                    <label className="text-regular blue text-underline float-right pointer"
                        onClick={() => setshowExperience(index === showExperience ? null : index)}>
                        {showExperience === index ? "voir moins" : "voir plus"}
                    </label>
                </div>
                {
                    showExperience === index ? (
                        <div className="my-3" id="collapseTarget">
                            <Form.Group className="mb-2">
                                <Form.Label>Poste</Form.Label>
                                <Form.Control
                                    className={ErrorArrayClass(ExperinceFormEdit, "wrokexperiences", index, "poste")}
                                    name={`wrokexperiences[${index}].poste`} id={`wrokexperiences[${index}].poste`}
                                    type="text" placeholder="Entrez votre poste"
                                    onChange={(e) => {
                                        setValueFormExperience(e.target.value, index, 'poste')
                                    }}
                                    value={formExp.poste}
                                    onBlur={ExperinceFormEdit.handleBlur}
                                />
                                {ErrorArrayFeild(ExperinceFormEdit, "wrokexperiences", index, "poste")}
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label>Entreprise</Form.Label>
                                <Form.Control
                                    className={ErrorArrayClass(ExperinceFormEdit, "wrokexperiences", index, "entreprise")}
                                    name={`wrokexperiences[${index}].entreprise`} id={`wrokexperiences[${index}].entreprise`}
                                    type="text" placeholder="Entrez le nom de l'entreprise"
                                    onChange={(e) => {
                                        setValueFormExperience(e.target.value, index, 'entreprise')
                                    }}
                                    value={formExp.entreprise}
                                    onBlur={ExperinceFormEdit.handleBlur}
                                />
                                {ErrorArrayFeild(ExperinceFormEdit, "wrokexperiences", index, "entreprise")}
                            </Form.Group>
                            <Row className="mb-2">
                                <div className="period-edit">Période :</div>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-muted"> À partir de: </Form.Label>
                                        <DatePicker
                                            id={`wrokexperiences[${index}].fromDate`} name={`wrokexperiences[${index}].fromDate`}
                                            className={ErrorArrayClass(ExperinceFormEdit, "wrokexperiences", index, "fromDate")}
                                            selected={formExp.fromDate ? new Date(formExp.fromDate) : ""}
                                            onChange={(date) => setValueFormExperience(date ? date : "", index, 'fromDate')}
                                            dateFormat={"MM/yyyy"} locale={fr}
                                            showMonthYearPicker
                                        />
                                        {ErrorArrayFeild(ExperinceFormEdit, "wrokexperiences", index, "fromDate")}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="text-muted"> jusqu'à: </Form.Label>
                                        <DatePicker
                                            id={`wrokexperiences[${index}].toDate`} name={`wrokexperiences[${index}].toDate`}
                                            className={ErrorArrayClass(ExperinceFormEdit, "wrokexperiences", index, "toDate")}
                                            selected={formExp.toDate ? new Date(formExp.toDate) : ""}
                                            onChange={(date) => setValueFormExperience(date ? date : "" , index, 'toDate') }
                                            onBlur={ExperinceFormEdit.handleBlur}
                                            dateFormat="MM/yyyy" locale={fr}
                                            showMonthYearPicker
                                        />
                                        {ErrorArrayFeild(ExperinceFormEdit, "wrokexperiences", index, "toDate")}
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* Skills */}
                            <Form.Group className="formGroup mb-2">
                                <Form.Label>Environnement technique</Form.Label>
                                <Select isMulti isSearchable placeholder=""
                                    className={ErrorArrayClass(ExperinceFormEdit, "wrokexperiences", index, "skills")}
                                    name={`wrokexperiences[${index}].skills`} id={`wrokexperiences[${index}].skills`}
                                    closeMenuOnSelect={true} options={allSkills}
                                    value={formExp.skills}
                                    onChange={(e) => {
                                        setValueFormExperience(e, index, 'skills')
                                    }}
                                />
                                {ErrorArrayFeild(ExperinceFormEdit, "wrokexperiences", index, "skills")}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea" rows={3} placeholder="Écrivez sur votre règle et votre responsabilité"
                                    name={`wrokexperiences[${index}].description`} id={`wrokexperiences[${index}].description`}
                                    className={ErrorArrayClass(ExperinceFormEdit, "wrokexperiences", index, "description")}
                                    onChange={(e) => {
                                        setValueFormExperience(e.target.value, index, 'description')
                                    }}
                                    value={formExp.description}
                                    onBlur={ExperinceFormEdit.handleBlur}
                                />
                                {ErrorArrayFeild(ExperinceFormEdit, "wrokexperiences", index, "description")}
                            </Form.Group>
                            <Button variant="primary" type="submit" onClick={ExperinceFormEdit.handleSubmit}
                                className="shadow-unset float-right mb-5 save-button border-dark">
                                Modifier
                            </Button>
                            <span className="btn-action red pointer" onClick={() => deleteExperience(formExp.id)}>
                                < FaTrashAlt className="mr-5 mb-1" /> Supprimer
                            </span>
                        </div>
                    ) : null
                }
            </div>
        );
    };

    /* Set Value Feild Experience Edit */
    function setValueFormExperience(e, index, control) {
        ExperinceFormEdit.setFieldValue(`wrokexperiences[${index}].${control}`, e );
    }

    return (
        <>
            <div className="title-profile-cosmonaute mb-5">EXPÉRIENCE PROFESSIONNELLE</div>
            {addFormExperiences()}
            {editFormExperiences()}
        </>
    );
}
