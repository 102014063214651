import * as Yup from "yup";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import {
  ErrorClass,
  ErrorFeild,
} from "../../services/Controller/ValidationForm";
import Select, { components } from "react-select";
import { useContext, useEffect, useState } from "react";
import { Context as MissionContext } from "../../context/reducers/RecruiterReducer";

const validationdeclineForm = Yup.object({
  desc: Yup.string().required("La description est obligatoire "),
  nom: Yup.string().required("Le nom est obligatoire "),
  domain: Yup.string().required("Le domaine d'expertise est obligatoire "),
  nbrheure: Yup.string().required("Le nombre d'heure est obligatoire "),
  nbr_participants: Yup.string().required(
    "Le nombre de participants est obligatoire "
  ),
  type:Yup.string().required("La catégtorie de formation est obligatoire "),
  skills: Yup.array().required("compétence est obligatoire"),
});

export const MissionFavorite = ({
  modalFavorite,
  setModalFavorite,
  postMissionFavorite,
  detail,
  missionFavoriteData,
}) => {
  const skillsOptions = detail?.skills
    ? detail?.skills.map((item) => {
        return { value: item.id, label: item.name };
      })
    : [];

  const { getAllSkills, getListRegions, getAllTypeSkills, state } =
    useContext(MissionContext);

  /**********state ******* */
  const [allTypeSkills, setAllTypeSkills] = useState([]);

  

  const lenghtMission = missionFavoriteData ? missionFavoriteData.length : 0;
  function lengthArray() {
    let lenghtMissionArray = 0;
    if (lenghtMission === 0) {
      lenghtMissionArray = 0;
    } else if (lenghtMission === 1) {
      lenghtMissionArray = 0;
    } else {
      lenghtMissionArray = lenghtMission - 1;
    }
    return lenghtMissionArray;
  }

  const skillsValue =
    missionFavoriteData && missionFavoriteData[lengthArray()]
      ? missionFavoriteData[lengthArray()]?.skills.map((item) => {
          return { value: item?.id, label: item?.label };
        })
      : [];
  /* Form Group Decline Interview */
  const contactForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      place:
        missionFavoriteData && missionFavoriteData[lengthArray()]
          ? missionFavoriteData[lengthArray()].place
          : "",
      missionType:
        missionFavoriteData && missionFavoriteData[lengthArray()]
          ? missionFavoriteData[lengthArray()].missionType
          : "",
      skills: skillsValue ? skillsValue : [],
    },
    validationSchema: validationdeclineForm,
    onSubmit: (values) => {
      let allSelectedSkills = [];
      values.skills.forEach((selected) => {
        allSelectedSkills.push({ id: selected.value });
      });
      const data = {
        skills: allSelectedSkills,
        place: values.place,
        nom: values.nom,
        missionType: values.missionType,
        domain: values.domain,
        nbrheure: values.nbrheure,
        type:values.type,
        nbr_participants: values.nbr_participants,
        desc: values.desc,
      };
      postMissionFavorite(data);
      contactForm.resetForm();
      setModalFavorite(false);
    },
  });

  /* Footer Decline */
  function ConfirmForm() {
    return (
      <>
        <Button className="save-button blue shadow-unset mr-64" type="submit">
          Confirmer
        </Button>
        <Button
          className="save-button red shadow-unset"
          type="button"
          onClick={() => {
            setModalFavorite(false);
            contactForm.resetForm();
          }}
        >
          Annuler
        </Button>
      </>
    );
  }
  const Menu = (props) => {
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < 3 ? props.children : null}
      </components.Menu>
    );
  };
  const isValidNewOption = (inputValue, selectValue) =>
    inputValue.length > 0 && selectValue.length < 3;
  
  useEffect(() => {
    
    getAllTypeSkills();
    
  }, []);

  useEffect(() => {
    setAllTypeSkills(state?.allTypeSkills);
  }, [state]);

  return (
    <>
      <Modal
        show={modalFavorite}
        size="xl"
        centered
        onHide={() => {
          setModalFavorite(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title"
          >
            Nouvelle Formation
          </Modal.Title>
        </Modal.Header>
        <Modal.Header>
          <span className="h4-modal">
            Décrivez nous votre formation et son contenu
          </span>
        </Modal.Header>

        {/* Modal Content */}
        {/* {FormContact()} */}
        <Form onSubmit={contactForm.handleSubmit}>
          <Modal.Body>
            {/* Card of Interview information */}
            {/* <div className="text_test mb-2">
              <span>Nom et prénom : </span>
              <span>{detail?.personalInfos[0].name + " "}</span>
              <span>{detail?.personalInfos[0].lastname}</span>
            </div> */}
            {/* <div className="text_test mb-4">
              <span>Email : </span>
              <span>{detail?.personalInfos[0].email}</span>
            </div> */}
            {/* Form Decline */}
            {/* <div className="d-flex justify-content-between"> */}
            {/* <div className="text_test">Mission : </div> */}
            {/* <Form.Check
                type="radio"
                label="Sur site"
                id="mission_site"
                name="missionType"
                value="Sur site"
                className="text-Label-modal form-label"
                checked={"Sur site" === contactForm.values.missionType}
                onChange={contactForm.handleChange}
              /> */}

            {/* <Form.Check
                type="radio"
                label="Télétravail"
                id="remote"
                name="missionType"
                value="Télétravail"
                className="text-Label-modal form-label"
                onChange={contactForm.handleChange}
                checked={"Télétravail" === contactForm.values.missionType}
              /> */}
            {/* <Form.Check
                type="radio"
                label="Mixte"
                value="Mixte"
                id="mixte"
                name="missionType"
                className="text-Label-modal form-label"
                onChange={contactForm.handleChange}
                checked={"Mixte" === contactForm.values.missionType}
              /> */}
            {/* </div> */}
            <Form.Group className="mb-2 formGroup">
              <Form.Label className="text-black required">
                Domaine d'expertise
              </Form.Label>
              {/* <Select
                isMulti
                isSearchable
                placeholder=""
                className={ErrorClass(contactForm, "skills mb-5")}
                name="skills"
                id="skills"
                options={skillsOptions}
                closeMenuOnSelect={false}
                value={contactForm.values.skills}
                onChange={(event) => {
                  contactForm.setFieldValue("skills", event);
                }}
                onBlur={contactForm.handleBlur}
                isValidNewOption={isValidNewOption}
                components={{ Menu }}
              /> */}
              {ErrorFeild(contactForm, "skills")}
              <Form.Select
                name="domain"
                id="domain"
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.domain}
              >
                <option className="text-bold" defaultValue value="">
                  {" "}
                  Sélectionnez une option{" "}
                </option>
                <option value="Finance et Comptabilité">
                  Finance et Comptabilité
                </option>
                <option value="Ressources Humaines">Ressources Humaines</option>
                <option value="Marketing et Communication">
                  Marketing et Communication
                </option>
                <option value="Vente et Développement Commercial">
                  Vente et Développement Commercial
                </option>
                <option value="Technologies de l'Information (IT)">
                  Technologies de l'Information (IT)
                </option>
                <option value="Ingénierie et Production">
                  Ingénierie et Production
                </option>
                <option value="Logistique et Supply Chain">
                  Logistique et Supply Chain
                </option>
                <option value="Droit et Juridique">Droit et Juridique</option>
                <option value="Gestion de Projet">Gestion de Projet</option>
                <option value="Santé et Services Sociaux">
                  Santé et Services Sociaux
                </option>
                <option value="Expertise numérique">Expertise numérique</option>
                <option value="Bien être">Bien être</option>
                <option value="Soft Skill">Soft Skill</option>
                <option value="Musique">Musique</option>
                <option value="Art">Art</option>
                <option value="Sport">Sport</option>
                <option value="Autre">Autre</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="pt-4">
              <Form.Label className="text-Label-modal required">
                {" "}
                Choisissez une catégorie de formation{" "}
              </Form.Label>
              <Form.Select
                className={ErrorClass(contactForm, "type")}
                name="type"
                id="type"
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.type}
              >
                <>
                  <option className="text-bold" defaultValue value="">
                    {" "}
                    Sélectionnez une option{" "}
                  </option>
                  {allTypeSkills && allTypeSkills.length
                    ? allTypeSkills.map((el, index) => {
                        return (
                          <option
                            className="color-black"
                            key={"option" + index}
                            value={el.id}
                          >
                            {el.name}
                          </option>
                        );
                      })
                    : null}
                </>
              </Form.Select>
              {ErrorFeild(contactForm, "type")}
            </Form.Group>
            <Form.Group className="mt-2 mb-2">
              <Form.Label className="text-Label-modal required">
                Nom de la formation
              </Form.Label>
              <Form.Control
                className={ErrorClass(contactForm, "nom")}
                as="input"
                name="nom"
                id="nom"
                placeholder="Saisir le nom..."
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.nom}
              />
              {ErrorFeild(contactForm, "nom")}
            </Form.Group>
            <Form.Group className="mt-2 mb-2">
              <Form.Label className="text-Label-modal">
                Lieu de la formation
              </Form.Label>
              <Form.Control
                className={ErrorClass(contactForm, "place")}
                as="input"
                name="place"
                id="place"
                placeholder="Saisir le lieu..."
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.place}
              />
              {ErrorFeild(contactForm, "object")}
            </Form.Group>

            <Form.Group className="mb-2 formGroup">
              <Form.Label className="text-black required">
                Durée de formation
              </Form.Label>
              {/* <Select
                isMulti
                isSearchable
                placeholder=""
                className={ErrorClass(contactForm, "skills mb-5")}
                name="skills"
                id="skills"
                options={skillsOptions}
                closeMenuOnSelect={false}
                value={contactForm.values.skills}
                onChange={(event) => {
                  contactForm.setFieldValue("skills", event);
                }}
                onBlur={contactForm.handleBlur}
                isValidNewOption={isValidNewOption}
                components={{ Menu }}
              /> */}
              {ErrorFeild(contactForm, "skills")}
              <Form.Select
                name="nbrheure"
                id="nbrheure"
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.nbrheure}
              >
                <option className="text-bold" defaultValue value="">
                  {" "}
                  Sélectionnez une option{" "}
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2 formGroup">
              <Form.Label className="text-black required">
                Nombre de participants
              </Form.Label>

              {ErrorFeild(contactForm, "skills")}
              <Form.Select
                name="nbr_participants"
                id="nbr_participants"
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.nbr_participants}
              >
                <option className="text-bold" defaultValue value="">
                  {" "}
                  Sélectionnez une option{" "}
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="more15">Plus de 15</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mt-2 mb-2">
              <Form.Label className="text-Label-modal required">
                Dites nous en plus sur la formation que vous souhaitez ajouter
              </Form.Label>
              <Form.Control
                as="textarea"
                className={ErrorClass(contactForm, "desc")}
                name="desc"
                id="desc"
                rows={3}
                onChange={contactForm.handleChange}
                onBlur={contactForm.handleBlur}
                value={contactForm.values.desc}
              />
              {ErrorFeild(contactForm, "desc")}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <ConfirmForm />
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
