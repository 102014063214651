/* PROFIL Candidate */
export const RETRIEVE_COSMONAUTE = "RETRIEVE_COSMONAUTE";
export const UPDATE_COSMONAUTE = "UPDATE_COSMONAUTE";
export const UPDATE_COSMONAUTE_DESCRIPTION = "UPDATE_COSMONAUTE_DESCRIPTION";

/* EDUCATION Candidate */
export const ADD_NEW_EDUCATION = "ADD_NEW_EDUCATION";
export const SAVE_EDUCATION = "SAVE_EDUCATION";
export const DELETE_EDUCATION = "DELETE_EDUCATION";
export const DUMP_EDUCATION = "DUMP_EDUCATION";

/* EXPERIENCE Candidate */
export const ADD_NEW_EXPERIENCE = "ADD_NEW_EXPERIENCE";
export const EDIT_EXPERIENCE = "EDIT_EXPERIENCE";
export const DELETE_EXPERIENCE = "DELETE_EXPERIENCE";
export const SAVE_EXPERIENCE = "SAVE_EXPERIENCE";
export const DUMP_EXPERIENCE = "DUMP_EXPERIENCE";

/* SKILLS Candidate */
export const GET_ALL_SKILLS = "GET_ALL_SKILLS";
export const SAVE_SKILLS = "SAVE_SKILLS";
export const DUMP_SKILLS = "DUMP_SKILLS";
export const GET_ALL_TYPE_SKILLS = "GET_ALL_TYPE_SKILLS";


/* LANGUES Candidate */
export const SAVE_LANGUAGES = "SAVE_LANGUAGES";
export const DUMP_LANGUAGES = "DUMP_LANGUAGES";
export const GET_LANGUES = "GET_LANGUES";
/* DASHBOARD Candidate */
export const GET_THREE_INTERVIEWS = "GET_THREE_INTERVIEWS";
export const GET_MISSIONS_ASSIGNEDS = "GET_MISSIONS_ASSIGNEDS";
export const GET_MISSIONS_ACCEPTED = "GET_MISSIONS_ACCEPTED";
export const GET_MISSIONS_PAST = "GET_MISSIONS_PAST";
export const GET_MISSIONS_DECLINED = "GET_MISSIONS_DECLINED";
export const GET_CURRENT_MISSIONS = "GET_CURRENT_MISSIONS";
export const GET_DECLINED_INTERVIEWS = "GET_DECLINED_INTERVIEWS";
export const GET_DASHBOARD_CALENDER = "GET_DASHBOARD_CALENDER";
export const GET_DASHBOARD_DETAIL = "GET_DASHBOARD_DETAIL";
export const GET_ALL_MISSION = "GET_ALL_MISSION";
export const GET_ALL_WANTED_MISSION = "GET_ALL_WANTED_MISSION";

/* INTERVIIEW Candidate */
export const GET_ALL_INTERVIEWS = "GET_ALL_INTERVIEWS";
export const DUMP_INTERVIEW = "DUMP_INTERVIEW";
export const DECLINE_INTERVIEW_COSMONAUTE = "DECLINE_INTERVIEW_COSMONAUTE";
export const ACCEPT_INTERVIEW_COSMONAUTE = "ACCEPT_INTERVIEW_COSMONAUTE";
export const CHANGE_DATE_COSMONAUTE = "CHANGE_DATE_COSMONAUTE";

/* CV Candidate */
export const COSMONAUTE_RESUME = "COSMONAUTE_RESUME";
export const GET_RESUME = "GET_RESUME";

/* LOGO Candidate */
export const UPLOAD_LOGO_CANDIDATE = "UPLOAD_LOGO_CANDIDATE";
export const GET_LOGO_CANDIDATE = "GET_LOGO_CANDIDATE";
export const DUMP_LOGO_CANDIDATE = "DUMP_LOGO_CANDIDATE";

/* pourcentage condidat*/
export const POURCENTAGE_CONDIDAT = "POURCENTAGE_CONDIDAT";

/*Decline Mission */
export const DECILNE_MESSION_COSMONAUTE = "DECILNE_MESSION_COSMONAUTE";
export const DUMP_MISSION = "DUMP_MISSION";
export const ONE_MISSION_USER = "ONE_MISSION_USER";

/* add document cosmonaute */
export const ADD_COSMONAUTE_DOCUMENT = "ADD_COSMONAUTE_DOCUMENT";
export const DELETE_COSMONAUTE_DOCUMENT = "DELETE_COSMONAUTE_DOCUMENT";
export const GET_COSMONAUTE_DOCUMENT = "GET_COSMONAUTE_DOCUMENT";
export const EDIT_COSMONAUTE_DOCUMENT = "EDIT_COSMONAUTE_DOCUMENT";
export const DUMP_COSMONAUTE_DOCUMENT = "DUMP_COSMONAUTE_DOCUMENT";

/* add contract */
export const ADD_CONTRACT = "ADD_CONTRACT";
export const GET_CONTRACT = "GET_CONTRACT";

/* ADD CRA */
export const ADD_CRA = "ADD_CRA";
export const DUMP_GET_CRA = "DUMP_GET_CRA";
/* GET MISSION RECRUTED candidat*/
export const RECRUTED_MISSION_CANDIDAT = "RECRUTED_MISSION_CANDIDAT";

/* GET ALL RECRUTED CANDIDAT RECRUTED FOR ADMIN*/
export const GET_RECRUTED_CANDIDAT = "GET_RECRUTED_CANDIDAT";

/* import contrat Entreprise */
export const RESEND_CONTRAT_RECRUITER = "RESEND_CONTRAT_RECRUITER";

/* detail mission Entreprise */
export const DETAIL_MISSION_RECRUTER = "DETAIL_MISSION_RECRUTER";

/* get all recruiter for admin have mission recruited*/
export const MISSION_RECRUITER_ADMIN = "MISSION_RECRUITER_ADMIN";

/* envoie mail contrat cra to candidate */
export const ENVOIE_EMAIL_CONTRAT_CANDIDAT = "ENVOIE_EMAIL_CONTRAT_CANDIDAT";

/* send email contrat invoices to recruiter */
export const SEND_EMAIL_CONTRAT_RECRUITER = "SEND_EMAIL_CONTRAT_RECRUITER";

/* get data recruiter from admin */
export const DETAIL_MISSION_RECRUTER_ADMIN = "DETAIL_MISSION_RECRUTER_ADMIN";

/* mission favorite post */
export const POST_MISSION_FAVORITE = "POST_MISSION_FAVORITE";

/* mission favorite get */
export const GET_MISSION_FAVORITE = "GET_MISSION_FAVORITE";

/* send invoices to recruiter */
export const SEND_VOICES_ADMIN = "SEND_VOICES_ADMIN";

/* dump mission favorite */
export const DUMP_FAVORITE = "DUMP_FAVORITE";