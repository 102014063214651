import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Image, Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import Switch from "react-switch";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Context as AuthContext } from '../../../context/reducers/AuthReducer';
import { Context as RecruiterContext } from '../../../context/reducers/RecruiterReducer';
import ModalUploadLogo from '../../../components/ModalUploadLogo/ModalUploadLogo';
import { getUserEmail } from '../../../services/Controller/CurrentUserServices';
import { ErrorFeild, ErrorClass } from '../../../services/Controller/ValidationForm';
import campany from '../../../assets/img/campany.png';
import AlertModal from "../../../components/alert/alert.js";
import { FaEnvelope, FaLink, FaPhoneAlt } from 'react-icons/fa';
import { phonePattern, emailPattern, urlPattern } from "../../../services/Controller/PattenService";
import { onFocusPhoneNumber, onBlurPhoneNumber } from "../../../services/Controller/PhoneService";
import './ProfilRecruiter.scss';

/* Validation Form Group Profile */
const validationRecruiterProfil = Yup.object({
  campanyName: Yup.string().required("Nom de l'entreprise obligatoire"),
  phoneNumber: Yup.string()
    .min(11, "Numéro de téléphone doit contenir au moins 11 caractères")
    .max(15, "Numéro de téléphone doit contenir au plus 15 chiffres")
    .matches(phonePattern, "Téléphone invalide"),
  firstname: Yup.string().required('Nom obligatoires'),
  lastname: Yup.string().required('Prénom obligatoires'),
  email: Yup.string()
    .email('Email invalide')
    .matches(emailPattern, "Email invalide")
    .required('Email obligatoire'),
  domain: Yup.string().required('Domaine d\'activité obligatoire'),
  link: Yup.string().matches(urlPattern, "Site web invalide"),
});

/* Validation Form Group Detail Profile */
const validationRecruiterProfilDetail = Yup.object({
  emailCampany: Yup.string()
    .email("Email invalide")
    .required("Email obligatoire")
    .required('Email obligatoire'),
  videoCampany: Yup.string().url('url vidéo invalide')
});

/* Export Profil component */
export default function ProfilRecruiter() {
  /* use Translation */
  const { t } = useTranslation();

  /* History */
  const history = useHistory();

  /* use Location */
  const location = useLocation();

  /* id Profil */
  const [idProfil, setIdProfile] = useState(null);

  /* LogOut Context Api */
  const { signout } = useContext(AuthContext);

  /* Recruiter Context Api */
  const {
    getProfilRecruiter, getDomainRecruiter, getListRegions,
    editProfilRecruiter, editProfilDetailRecruiter, dumpProfilRecruiter,
    logoUpload, getLogoRecruiter, dumpLogoRecruiter,
    state
  } = useContext(RecruiterContext);

  /* States */
  const [recruiterProfil, setRecruiterProfil] = useState(null);
  const [recruiterDomains, setRecruiterDomains] = useState(null);
  const [recruiterLogo, setRecruiterLogo] = useState(null);
  const [allRegions, setAllRegions] = useState([]);
  /* show alert */
  const [show, setShow] = useState(false);
  /* Modal Image */
  const [modalShow, setModalShow] = useState(false);

  /*eslint-disable */
  useEffect(() => {
    if (history.location.state === "completeProfile") setShow(true);
    getDomainRecruiter();
    getListRegions().then((res) => { setAllRegions(res.data) });
    getLogoRecruiter();
    const id = location.pathname.split("/")[3];
    if (id) {
      setIdProfile(id);
      getProfilRecruiter(id);
    }
    else {
      setIdProfile(null);
      getProfilRecruiter()
    }
  }, []);
  /*eslint-enable */

  useEffect(() => {
    if (state.recruiterProfil) setRecruiterProfil(state.recruiterProfil);
    if (state.recruiterDomain) setRecruiterDomains(state.recruiterDomain);
    if (state.recruiterLogo) setRecruiterLogo(state.recruiterLogo);
  }, [state]);

  /* refresh Profile Recruiter */
  useEffect(() => {
    if (state.profile) {
      getProfilRecruiter()
      dumpProfilRecruiter();
    }
  }, [dumpProfilRecruiter, getProfilRecruiter, state.profile]);

  /* refresh Logo Recruiter */
  useEffect(() => {
    if (state.logo) {
      getLogoRecruiter()
      dumpLogoRecruiter();
    }
  }, [dumpLogoRecruiter, getLogoRecruiter, state.logo]);

  /* open web site */
  function NewTab(link) {
    if (link) {
      if (link.indexOf("http") === -1) window.open("https://" + link, "_blank");
      else window.open(link, "_blank");

    }
  }

  /* Form Group Profile Creation */
  const profilForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      campanyName: recruiterProfil && recruiterProfil.company_name ? recruiterProfil.company_name : "",
      phoneNumber: recruiterProfil && recruiterProfil.phone_number ? recruiterProfil.phone_number : "",
      firstname: recruiterProfil && recruiterProfil.firstname ? recruiterProfil.firstname : "",
      lastname: recruiterProfil && recruiterProfil.lastname ? recruiterProfil.lastname : "",
      managerPost: recruiterProfil && recruiterProfil.poste ? recruiterProfil.poste : "",
      landingPlace: recruiterProfil && recruiterProfil.country ? recruiterProfil.country : "",
      email: recruiterProfil && recruiterProfil.email ? recruiterProfil.email : "",
      link: recruiterProfil && recruiterProfil.link ? recruiterProfil.link : "",
      domain: recruiterProfil && recruiterProfil.domain_recruiter && recruiterProfil.domain_recruiter.length ? recruiterProfil.domain_recruiter[0].id : "",
    },
    validationSchema: validationRecruiterProfil,
    onSubmit: async values => {
      var dataToSend = {
        firstname: values.firstname,
        lastname: values.lastname,
        companyName: values.campanyName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        poste: values.managerPost,
        country: values.landingPlace,
        link: values.link,
        idDomaineRecruiter: values.domain,
      }
      editProfilRecruiter(dataToSend);
      const emailCurrentUser = getUserEmail();
      if (emailCurrentUser !== dataToSend.email) {
        setTimeout(() => {
          signout(history);
        }, 3000);
      }
      else changeMode();
    }
  })

  /* Form Group Detail Profile Creation */
  const profilDetailForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      aboutCampany: recruiterProfil && recruiterProfil.description ? recruiterProfil.description : "",
      adressCampany: recruiterProfil && recruiterProfil.address ? recruiterProfil.address : "",
      emailCampany: recruiterProfil && recruiterProfil.email ? recruiterProfil.email : "",
      startAtCampany: recruiterProfil && recruiterProfil.start_at ? recruiterProfil.start_at : "",
      finishAtCampany: recruiterProfil && recruiterProfil.finish_at ? recruiterProfil.finish_at : "",
      videoCampany: recruiterProfil && recruiterProfil.url ? recruiterProfil.url : "",
      lookingForEmployee: recruiterProfil && recruiterProfil.recruit_employee ? true : false,
      lookingForEmployed: recruiterProfil && recruiterProfil.self_employed ? true : false,
      alreadyWorkedWithIndependent: recruiterProfil && recruiterProfil.independent ? true : false,
      alreadyWorkedWithIndependentLiked: recruiterProfil && recruiterProfil.like_independent ? true : false,
      alreadyWorkedWithRemoteConsultant: recruiterProfil && recruiterProfil.remote_consultant ? true : false,
      wishExpandEngineeringTeamOrDevelopExistingProject: recruiterProfil && recruiterProfil.expand_team ? true : false,
      wishStartNewProject: recruiterProfil && recruiterProfil.new_project ? true : false,
      wishMAkeFirstHireEngineer: recruiterProfil && recruiterProfil.hire_ing ? true : false,
    },
    validationSchema: validationRecruiterProfilDetail,
    onSubmit: values => {
      var dataToSend = {
        description: values.aboutCampany,
        address: values.adressCampany,
        email: values.emailCampany,
        startAt: values.startAtCampany,
        finishAt: values.finishAtCampany,
        recruitEmployee: values.lookingForEmployee,
        selfEmployed: values.lookingForEmployed,
        independent: values.alreadyWorkedWithIndependent,
        likeIndependent: values.alreadyWorkedWithIndependentLiked,
        remoteConsultant: values.alreadyWorkedWithRemoteConsultant,
        expandTeam: values.wishExpandEngineeringTeamOrDevelopExistingProject,
        newProject: values.wishStartNewProject,
        hireIng: values.wishMAkeFirstHireEngineer,
        url: values.videoCampany,
      }
      editProfilDetailRecruiter(dataToSend);
      changeMode();
    }
  })

  /* Mode of Page (Edit | Show) */
  const [editProfileMode, seteditProfileMode] = useState("show");
  function changeMode() {
    seteditProfileMode(editProfileMode === "show" ? "edit" : "show");
  }

  /* onChange Switch checkbox */
  function changeCheckbox(event, control) {
    profilDetailForm.setFieldValue(control, event);
  }

  /* Return back to Admin Recruiters list */
  function returnBackAdmin() {
    history.push(t('role.administrator') + t('sidebar.recruiters.path'));
  }

  /* Profil en Mode Show */
  function showProfilHeader() {
    return (
      <>
        {
          idProfil
            ?
            <div className="arrow-back-mission pointer mt-2 mb-3" onClick={returnBackAdmin}>
              <i className="icon-back-arrow"></i>
            </div>
            :
            <Row className="justify-content-end mt-5">
              <Button className="save-button blue2" type="button" onClick={changeMode}> Modifier </Button>
            </Row>
        }
        <Row className="align-items-center justify-content-center mt-3" xl={12} lg={12} md={12} sm={12}>
          <Col xl={2} lg={3} md={5} xs={5} className="img-section ">
            <Image className="w-100 height-image"
              src={recruiterLogo ? ('http://' + recruiterLogo.path) : campany} roundedCircle />
          </Col>
          <Col xl={9} lg={8} md={7} xs={12} className="mt-md-3 mt-sm-3" >
            <Row>
              <Col lg={6} md={12} xs={12} className="">
                {recruiterProfil && recruiterProfil.company_name ?
                  <label className='name'>
                    {recruiterProfil.company_name}
                  </label>
                  : null}
                {recruiterProfil && recruiterProfil.country ?
                  <div className='text-campany-label'>
                    {recruiterProfil.country}
                  </div>
                  : null}
                {recruiterProfil ?
                  <div>
                    <p className="place">
                      Nom d'utilisateur :{" "}
                      {recruiterProfil.firstname + " " + recruiterProfil.lastname}
                    </p>
                  </div>
                  : null}
                {recruiterProfil && recruiterProfil.poste ? recruiterProfil.poste : null}
                <p className="place">
                  Domaine d'activité :{" "}
                  {recruiterProfil?.domain_recruiter[0]?.name}
                  {recruiterProfil && recruiterProfil.domain_recruiter ?
                    <div>
                      <p className="place">
                        Poste :{" "}
                        {recruiterProfil.poste}
                      </p>
                    </div>
                    : null}
                </p>
              </Col>
              <Col lg={6} md={12} xs={12} className="d-flex flex-column justify-content-center">
                {recruiterProfil && recruiterProfil.phone_number ?
                  <div className="text-light mb-3">
                    <FaPhoneAlt className="mr-20" />
                    {recruiterProfil.phone_number}
                  </div>
                  : null}
                {recruiterProfil && recruiterProfil.email ?
                  <div className="text-light mb-3">
                    <FaEnvelope className="mr-20" />
                    {recruiterProfil.email}
                  </div>
                  : null}
                {recruiterProfil && recruiterProfil.link ?
                  <div className="text-light text-underline pointer mb-3" onClick={() => { NewTab(recruiterProfil?.link) }}>
                    <FaLink className="mr-20" />
                    {recruiterProfil.link}
                  </div>
                  : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  /* Profil en Mode Edit */
  function editProfilHeader() {
    return (
      <Form onSubmit={profilForm.handleSubmit}>
        <Row className='mt-2'>
          <Col>
            <div className="arrow-back pointer" onClick={changeMode}>
              <i className="icon-back-arrow"></i> Retour
            </div>
          </Col>
          <Col>
            <Button className="save-button blue2 float-right" type="submit">Sauvegarder</Button>
          </Col>
        </Row>
        <Row className="align-items-center mt-30">
          <Col lg={2} md={4} xs={12}>
            <Image className="height-image" src={recruiterLogo ? ('http://' + recruiterLogo.path) : campany} roundedCircle />
            <div className="text-camera text-align-center mb-5">
              <i className="icon-camera pointer" onClick={() => { setModalShow(true) }}></i>
              {" "}Changez votre photo de profil
            </div>
          </Col>
          <Col lg={10} md={8} xs={12}>
            <Row >
              <Col className="pr-form" lg={8} md={12} xs={12}>
                {/* Entreprise Name */}
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Nom de l'entreprise</Form.Label>
                      <Form.Control className={'input-header ' + ErrorClass(profilForm, 'campanyName')} type="text"
                        name="campanyName"
                        id="campanyName"
                        placeholder="Entrez le nom de votre entreprise"
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.campanyName} />
                      {ErrorFeild(profilForm, 'campanyName')}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Domaine de l'entreprise</Form.Label>
                      <Form.Select className={'input-header ' + ErrorClass(profilForm, 'domain')}
                        name="domain"
                        id="domain"
                        placeholder="Entrez votre domaine d'entreprise"
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.domain}>
                        <option className="color-black" value="">Sélectionnez un domaine</option>
                        {recruiterDomains && recruiterDomains.map((domain, index) => {
                          return (
                            <option className="color-black" key={"Domain" + index} value={domain.id}>
                              {domain.name}
                            </option>);
                        })}
                      </Form.Select>
                    </Form.Group>
                    {ErrorFeild(profilForm, 'domain')}
                  </Col>
                </Row>
                {/* Recruiter FullName & Manager Post */}
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Nom du Entreprise </Form.Label>
                      <Form.Control className={'input-header ' + ErrorClass(profilForm, 'lastname')} type="text"
                        name="lastname"
                        id="lastname"
                        placeholder="Entrez notre nom"
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.lastname} />
                      {ErrorFeild(profilForm, 'lastname')}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Prénom du Entreprise </Form.Label>
                      <Form.Control className={'input-header ' + ErrorClass(profilForm, 'firstname')} type="text"
                        name="firstname" id="firstname"
                        placeholder="Entrez votre prénom"
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.firstname} />
                      {ErrorFeild(profilForm, 'firstname')}
                    </Form.Group>
                  </Col>
                </Row>
                {/* Landing Place */}
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Poste actuel</Form.Label>
                      <Form.Select name="managerPost" id="managerPost" className={'input-header ' + ErrorClass(profilForm, 'managerPost')}
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.managerPost}>
                        <option className="color-black text-bold" value=""> Sélectionnez un poste </option>
                        <option className="color-black" value="Directeur">Directeur</option>
                        <option className="color-black" value="Manager">Manager</option>
                        <option className="color-black" value="Chef du projet">Chef du projet</option>
                        <option className="color-black" value="Responsable RH">Responsable RH</option>
                        <option className="color-black" value="Directeur RH">Directeur RH</option>
                        <option className="color-black" value="Responsable recrutement">Responsable recrutement</option>
                        <option className="color-black" value="Assistant de direction">Assistant de direction</option>
                        <option className="color-black" value="Autre">Autre</option>
                      </Form.Select>
                      {ErrorFeild(profilForm, 'managerPost')}
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <Form.Group>
                      <Form.Label>Région</Form.Label>
                      <Form.Select name="landingPlace" id="landingPlace"
                        className={'input-header ' + ErrorClass(profilForm, 'landingPlace')}
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.landingPlace}>
                        <option className="color-black" value={""}> Sélectionnez une région </option>
                        {
                          allRegions && allRegions.length
                            ? allRegions.map((el, index) => {
                              return (
                                <option className="color-black" key={"option" + index} value={el.nom}>
                                  {el.nom}
                                </option>
                              )
                            })
                            : null
                        }
                      </Form.Select>
                      {ErrorFeild(profilForm, 'landingPlace')}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col className="pl-form" lg={4} md={12} xs={12}>
                {/* Phone number Recruiter */}
                <Row>
                  <Col>
                    <Form.Group className="position-relative">
                      <Form.Label>Numéro de téléphone</Form.Label>
                      <i className="icon-call position-absolute icon-input"></i>
                      <Form.Control className={'input-header ' + ErrorClass(profilForm, 'phoneNumber')} type="text"
                        name="phoneNumber" id="phoneNumber"
                        placeholder="Entrez votre numéro de téléphone"
                        onChange={profilForm.handleChange}
                        onBlur={() => { onBlurPhoneNumber(profilForm, "phoneNumber") }}
                        onFocus={() => { onFocusPhoneNumber(profilForm, "phoneNumber") }}
                        value={profilForm.values.phoneNumber} />
                    </Form.Group>
                    {ErrorFeild(profilForm, 'phoneNumber')}
                  </Col>
                </Row>
                {/* Email Recruiter */}
                <Row>
                  <Col>
                    <Form.Group className="position-relative">
                      <Form.Label>Email</Form.Label>
                      <i className="icon-email position-absolute icon-input"></i>
                      <Form.Control className={'input-header ' + ErrorClass(profilForm, 'email')} type="email"
                        name="email" id="email"
                        placeholder="Enter your Email"
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.email} />
                    </Form.Group>
                    {ErrorFeild(profilForm, 'email')}
                  </Col>
                </Row>
                {/* URL Web Site */}
                <Row>
                  <Col>
                    <Form.Group className="position-relative">
                      <Form.Label> Site web entreprise</Form.Label>
                      <i className="icon-url position-absolute icon-input"></i>
                      <Form.Control className={'input-header ' + ErrorClass(profilForm, 'link')} type="text"
                        name="link" id="link"
                        placeholder="Enter your URL"
                        onChange={profilForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilForm.values.link} />
                    </Form.Group>
                    {ErrorFeild(profilForm, 'link')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    )
  }

  /* Detail Profile en Mode Show */
  function showDetailProfil() {
    return (
      <Row>
        <Col className="pr-form" lg={6} md={6} xs={12}>
          {recruiterProfil && recruiterProfil.description ?
            <div>
              <label className="title-show-profile">À propos de l'entreprise</label>
              <p className="description">
                {recruiterProfil.description}
              </p>
            </div>
            : null}
          {/* <div>
            <label className="title-show-profile">Les horaires de travail:</label>
            <p className="description-labor">
              <div className="period-box">
                <div className="period">
                  <span>Commence à: </span>
                  <label className="labor"> {recruiterProfil && recruiterProfil.start_at ? recruiterProfil.start_at : "- - -"} </label>
                </div>
                <div className="period">
                  <span>Finit à:</span>
                  <label className="labor"> {recruiterProfil && recruiterProfil.finish_at ? recruiterProfil.finish_at : "- - -"} </label>
                </div>
              </div>
            </p>
          </div> */}
          {/* <Col className=" d-flex" lg={12} md={12} xs={12}>
            <label className="title-look-profile">Vous cherchez</label>
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Vous cherchez à recruter un ou plusieurs employés :
                <span className={recruiterProfil.recruit_employee ? "text-navy ml-10  text-bold" : "text-danger ml-10  text-bold"}>
                  {recruiterProfil.recruit_employee ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Vous recherchez des indépendants ou plusieurs indépendants :
                <span className={recruiterProfil.self_employed ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.self_employed ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
          {/* <Col className=" d-flex" lg={12} md={12} xs={12}>
            <label className="title-look-profile">Vous avez déjà travaillé avec </label>
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Indépendants :
                <span className={recruiterProfil.independent ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.independent ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Indépendants que vous avez aimés :
                <span className={recruiterProfil.like_independent ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.like_independent ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Consultants à distance :
                <span className={recruiterProfil.remote_consultant ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.remote_consultant ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
          {/* <Col className="d-flex" lg={12} md={12} xs={12}>
            <label className="title-look-profile">Vous souhaitez  </label>
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Élargir une équipe d'ingénieurs ou développer un projet déjà existant :
                <span className={recruiterProfil.expand_team ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.expand_team ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Lancer un nouveau projet :
                <span className={recruiterProfil.new_project ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.new_project ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}

          {/* <Col className="d-flex mt-1" lg={12} md={12} xs={12}>
            {recruiterProfil ?
              <label className="lookFor-text">Faire votre première embauche d'un ingénieur :
                <span className={recruiterProfil.hire_ing ? "text-navy ml-10  text-bold" : "text-danger ml-10 text-bold"}>
                  {recruiterProfil.hire_ing ? "Oui" : "Non"}
                </span>
              </label>
              : null
            }
          </Col> */}
        </Col>
        <Col className="pl-form" lg={6} md={6} xs={12}>
          <label className="title-show-video">Vidéo à propos de l'entreprise</label>
          {
            recruiterProfil && recruiterProfil.url
              ? <ReactPlayer className="w-100 C_video" url={recruiterProfil.url} />
              : <div className="nothingToShow"> <span> Pas de résultats à afficher </span> </div>
          }
        </Col>
      </Row >
    )
  }

  /* Detail Profile en Mode Show */
  function editDetailProfil() {
    return (
      <Form onSubmit={profilDetailForm.handleSubmit}>
        <Row>
          <Col className="pr-form" lg={6} md={12} xs={12}>
            {/* About the Campany */}
            <Row className="mb-3">
              <Col>
                <label className="title-profile">À propos de l'entreprise</label>
                <Form.Group>
                  <Form.Control className={ErrorClass(profilDetailForm, 'aboutCampany')} as="textarea" rows={6}
                    name="aboutCampany"
                    id="aboutCampany"
                    placeholder="Write here about your campany, its values, etc ..."
                    onChange={profilDetailForm.handleChange}
                    onBlur={profilForm.handleBlur}
                    value={profilDetailForm.values.aboutCampany} />
                  {ErrorFeild(profilDetailForm, 'aboutCampany')}
                  <div className="IT_info">
                    <p className="info-campany"> Informations uniquement visibles pour vous et l'équipe Inspire. </p>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {/* Address of the Campany */}
            <Row className="mb-3">
              <Col>
                <label className="title-profile">Adresse entreprise :</label>
                <Form.Group>
                  <Form.Control className={ErrorClass(profilDetailForm, 'adressCampany')} type="text"
                    name="adressCampany"
                    id="adressCampany"
                    onChange={profilDetailForm.handleChange}
                    onBlur={profilForm.handleBlur}
                    value={profilDetailForm.values.adressCampany} />
                  {ErrorFeild(profilDetailForm, 'adressCampany')}
                </Form.Group>
              </Col>
            </Row>
            {/* Email of the Campany */}
            <Row className="mb-3">
              <Col>
                <label className="title-profile">E-mail entreprise :</label>
                <Form.Group>
                  <Form.Control className={ErrorClass(profilDetailForm, 'emailCampany')} type="text"
                    name="emailCampany"
                    id="emailCampany"
                    onChange={profilDetailForm.handleChange}
                    onBlur={profilForm.handleBlur}
                    value={profilDetailForm.values.emailCampany} />
                  {ErrorFeild(profilDetailForm, 'emailCampany')}
                </Form.Group>
              </Col>
            </Row>
            {/* Start at || Finish at */}
            {/* <Row className="mb-3">
              <Col>
                <label className="title-profile">Quand nous commençons et arrêtons généralement le travail :</label>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label className="text-labor"> Commence à: </Form.Label>
                      <Form.Control className={ErrorClass(profilDetailForm, 'startAtCampany')} type="time"
                        name="startAtCampany"
                        id="startAtCampany"
                        onChange={profilDetailForm.handleChange}
                        onBlur={profilForm.handleBlur}
                        value={profilDetailForm.values.startAtCampany.slice(0, 10)} />
                      {ErrorFeild(profilDetailForm, 'startAtCampany')}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label className="text-labor"> Finit à: </Form.Label>
                      <Form.Control className={ErrorClass(profilDetailForm, 'finishAtCampany')} type="time"
                        name="finishAtCampany"
                        id="finishAtCampany"
                        onChange={profilDetailForm.handleChange}
                        value={profilDetailForm.values.finishAtCampany.slice(0, 10)} onBlur={profilDetailForm.handleBlur} />
                      {ErrorFeild(profilDetailForm, 'finishAtCampany')}
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            {/* Looking For */}
            {/* <label className="title-look-profile">Vous cherchez</label> */}
            {/* looking For Employee */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={10}>
                  <label className="lookFor-text">Vous cherchez à recruter un ou plusieurs employés :</label>
                </Col>
                <Col lg={2} md={2} xs={2}>
                  <Switch id="lookingForEmployee" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="lookingForEmployee" checked={profilDetailForm.values.lookingForEmployee}
                    onChange={(event) => changeCheckbox(event, 'lookingForEmployee')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* looking For employed */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Vous recherchez des indépendants ou plusieurs indépendants :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="lookingForEmployed" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="lookingForEmployed" checked={profilDetailForm.values.lookingForEmployed}
                    onChange={(event) => changeCheckbox(event, 'lookingForEmployed')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* You have already worked with */}
            {/* <label className="title-look-profile">Vous avez déjà travaillé avec :</label> */}
            {/* Independents */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Indépendants :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="alreadyWorkedWithIndependent" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="alreadyWorkedWithIndependent" checked={profilDetailForm.values.alreadyWorkedWithIndependent}
                    onChange={(event) => changeCheckbox(event, 'alreadyWorkedWithIndependent')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* liked Independents */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Indépendants que vous avez aimés :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="alreadyWorkedWithIndependentLiked" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="alreadyWorkedWithIndependentLiked" checked={profilDetailForm.values.alreadyWorkedWithIndependentLiked}
                    onChange={(event) => changeCheckbox(event, 'alreadyWorkedWithIndependentLiked')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* consultants */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Consultants à distance :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="alreadyWorkedWithRemoteConsultant" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="alreadyWorkedWithRemoteConsultant" checked={profilDetailForm.values.alreadyWorkedWithRemoteConsultant}
                    onChange={(event) => changeCheckbox(event, 'alreadyWorkedWithRemoteConsultant')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* You wish */}
            {/* <label className="title-look-profile">Vous souhaitez </label> */}
            {/* expanding project */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Élargir une équipe d'ingénieurs ou développer un projet déjà existant :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="wishExpandEngineeringTeamOrDevelopExistingProject" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="wishExpandEngineeringTeamOrDevelopExistingProject" checked={profilDetailForm.values.wishExpandEngineeringTeamOrDevelopExistingProject}
                    onChange={(event) => changeCheckbox(event, 'wishExpandEngineeringTeamOrDevelopExistingProject')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* new Project */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Lancer un nouveau projet :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="wishStartNewProject" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="wishStartNewProject" checked={profilDetailForm.values.wishStartNewProject}
                    onChange={(event) => changeCheckbox(event, 'wishStartNewProject')} />
                </Col>
              </Row>
            </Form.Group> */}
            {/* hiring */}
            {/* <Form.Group>
              <Row className="mb-3">
                <Col lg={10} md={10} xs={12}>
                  <label className="lookFor-text">Faites votre première embauche d'un ingénieur :</label>
                </Col>
                <Col lg={2} md={2} xs={12}>
                  <Switch id="wishMAkeFirstHireEngineer" className="float-right" uncheckedIcon={false} checkedIcon={false} height={23} width={50}
                    name="wishMAkeFirstHireEngineer" checked={profilDetailForm.values.wishMAkeFirstHireEngineer}
                    onChange={(event) => changeCheckbox(event, 'wishMAkeFirstHireEngineer')} />
                </Col>
              </Row>
            </Form.Group> */}
          </Col>
          <Col className="pl-form" lg={6} md={12} xs={12}>
            <label className="title-video">Vidéo à propos de l'entreprise</label>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Text>URL</InputGroup.Text>
                <Form.Control type="text" name="videoCampany" id="videoCampany"
                  onChange={profilDetailForm.handleChange} className={ErrorClass(profilDetailForm, 'videoCampany')}
                  value={profilDetailForm.values.videoCampany} onBlur={profilDetailForm.handleBlur}
                  placeholder="Enter your Youtube URL"
                />
              </InputGroup>
              {ErrorFeild(profilDetailForm, 'videoCampany')}
            </Form.Group>
            <div className="IT_info mt-2">
              <p className="video-campany">
              Chère entreprise, vous pouvez, si vous le souhaitez, faire une vidéo Youtube qui en dit plus sur l'entreprise, l'environnement et ses valeurs
              </p>
            </div>
            <ReactPlayer className="w-100 C_video" url={profilDetailForm.values.videoCampany} />
          </Col>
        </Row>
        <Button className="save-button blue2 mt-80 mb-5 float-left" type="submit">Sauvegarder</Button>
      </Form>
    )
  }

  /* Main Function */
  return (
    <>
      < ModalUploadLogo modalShow={modalShow} setModalShow={setModalShow} logoUpload={logoUpload} />
      <div id="header-Content" className="header-Content">
        {editProfileMode === "edit" ? editProfilHeader() : showProfilHeader()}
      </div>
      <div id="main" className="main">
        {editProfileMode === "edit" ? editDetailProfil() : showDetailProfil()}
      </div>
      {/* show alert */}
      <AlertModal show={show} setShow={setShow}
        content="Bienvenue dans l’espace Entreprise  d’Inspire Talents ! Veuillez compléter votre profil afin de pouvoir dénicher des pépites de l’IT !"
      />
    </>
  );
};
