import { API_REGIONS } from "../../Api";
import axios from "axios";

/* Get list Skills */
const getListRegions = (dispatch) => () => {
    // Call API list Skills
    return axios.get(API_REGIONS);
}


export { getListRegions };