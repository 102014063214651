import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import "./Apropos.scss";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import { Link } from "react-router-dom";
import imgOneAbout from "../../assets/img/5.png";
import img7 from "../../assets/img/6.png";
import img8 from "../../assets/img/temple.png";
import img9 from "../../assets/img/4.png";

export default function Apropos() {
  const { t } = useTranslation();

  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      <Header></Header>
      <section className="pg-apropos-sec1">
        
        {/* <div className="overlay-propos1"></div> */}
        <div className="padding-propos1-title">
          {/* <ScrollAnimation animateIn="flipInY" animateOut="flipOutY"> */}
            <div className="text-transparent text-uppercase text-center roboto-black fontSize-140 text-spacing-4 padding-top-title">
              {t("propos.section1.title")}
            </div>
          {/* </ScrollAnimation> */}
          {/* <ScrollAnimation animateIn="flipInY" animateOut="flipOutY"> */}
            <div className=" text-uppercase text-center roboto-black fontSize-140 yellow-color ">
              {t("propos.section1.text")}
            </div>
          {/* </ScrollAnimation> */}
          <p className="it-Description fontSize-25 roboto-light text-center">
            {t("propos.section1.description")}
          </p>
          <p className="it-Description fontSize-25 roboto-light text-center">
            {t("propos.section1.description1")}
          </p>
        </div>
        {/* <div className="overlay-galaxie"></div> */}
        {/* <ScrollAnimation animateIn="tada"> */}
          <div className="scroll-downs mt-5 ">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        {/* </ScrollAnimation> */}
      </section>
      {/* <section className="pg-apropos-sec2">
        <div className="overlay-galaxies"></div>
        <div className="overlay-propos2 "></div>
        <Row className="align-items-center">
          <Col lg={12} className="padding-bottom-text">
            <div className="roboto-condensed-bold title-text-section2 text-uppercase fontSize-135 green-color mt-10-percent">
              {t("propos.section2.title")}
            </div>
            <div className="roboto-condensed-bold title-text-section2 text-uppercase fontSize-135 green-color mb-5">
              {t("propos.section2.title1")}
            </div>
          </Col>
          <Col className="title-description-propos2">
            <div className="roboto-medium text-white fontSize-40 text-uppercase ">
              {t("propos.section2.text")}
            </div>
            <div className="roboto-medium text-white fontSize-40 text-uppercase ">
              {t("propos.section2.text2")}
            </div>
            <div className="roboto-medium text-white fontSize-40 text-uppercase ">
              {t("propos.section2.text1")}
            </div>
          </Col>
        </Row>
        <div  className="overlay-propos animate-3"></div>
        <div className="overlay"></div>
      </section> */}
      <section className="pg-apropos-sec3">
        <img src={imgOneAbout} alt="cristals" className="imgOneAbout" />
        {/* <div className="overlay-sections5 animate-3"></div> */}
        <Row className="align-items-center padding-propos1-title ">
          <Col lg={12} className="pl-form">
            <div className="roboto-condensed-bold  text-uppercase fontSize-35 yellow-color text-propos3">
              {t("propos.section3.title")}
            </div>
            <div className="roboto-condensed-bold  text-uppercase fontSize-90 yellow-color text-propos3 padding-bottom-text">
              {t("propos.section3.title1")}
            </div>
          </Col>
          <Col g={12} className="pl-form mrl-20">
            <p className=" it-Description fontSize-25 roboto-light description-propos3">
              {t("propos.section3.text")}
            </p>
            <p className="it-Description fontSize-25 roboto-light description-propos3">
              {t("propos.section3.text1")}
            </p>
            <p className="it-Description fontSize-25 roboto-light description-propos3">
              {t("propos.section3.text2")}
            </p>
            <p className="it-Description fontSize-25 roboto-light description-propos3">
              {t("propos.section3.text3")}
            </p>
          </Col>
        </Row>
        <div className="overlay-planet"></div>
      </section>
      <section className="pg-apropos-sec4">
        {/* <div className=""></div> */}
        <Row className="align-items-center padding-propos4-text">
          <Col lg={12}>
            {/* <div className="overlay-cercle"></div> */}
            {/* <div className="diamant"></div> */}
            {/* <div className="diamant1"></div> */}
          </Col>
          <Col lg={12}>
            <div className="Roboto-condensed text-uppercase text-center fontSize-35 line-heigth-text yellow-color">
              {t("propos.section4.text")}
            </div>
          </Col>
          <Col lg={12}>
            <div className="Roboto-condensed text-uppercase  text-center fontSize-35 line-heigth-text yellow-color">
              {t("propos.section4.text1")}
            </div>
          </Col>
          <Col lg={12}>
            <div className="Roboto-condensed text-uppercase  text-center fontSize-35 line-heigth-text yellow-color">
              {t("propos.section4.text2")}
            </div>
          </Col>
          <Col>
            <p className="Roboto-condensed text-uppercase  text-center fontSize-35 line-heigth-text yellow-color">
              {t("propos.section4.text3")}
            </p>
          </Col>
        </Row>
        {/* <div className="overlay-props4"></div> */}
        <img src={img7} alt="img7" className="img7" />
        <img src={img8} alt="img8" className="img8" />
        <img src={img9} alt="img9" className="img9" />
      </section>
      <section id="section10">
          <Row className="line-height-row">
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
              </ScrollAnimation> */}
              <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
            </Col>
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              > */}
                <p className="roboto-condensed-bold ml-big text-uppercase fontSize-100 yellow-color text-spacing-3 infini mt-80 pt-40">
                  {t("home.infini")}
                </p>
              {/* </ScrollAnimation> */}
            </Col>
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              > */}
                <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                  {t("home.contact")}
                </p>
              {/* </ScrollAnimation> */}
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.adress")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.phone")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.mail")}
                </p>
                <p className="roboto-light text-center fontSize-25 notice text-underline">
                  <Link to="/notice">{t("home.mention")}</Link>
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <Footer />
    </div>
  );
}
function Footer() {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
          <h4 className="footer-title">Acropolia Talents</h4>
            <p>Acropolia place l’humain au <br />coeur de ses préoccupations</p>
            <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a href="https://www.instagram.com/acropolia_academy/" target="_blank" rel="noopener noreferrer" className="social-icon instagram">IG</a>
              <a href="https://www.facebook.com/profile.php?id=61551011631802" target="_blank" rel="noopener noreferrer" className="social-icon facebook">FB</a>
              {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon linkedin">LN</a> */}
              <a href="https://www.youtube.com/@acropolia_academy" target="_blank" rel="noopener noreferrer" className="social-icon youtube">YT</a>
              {/* <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="social-icon x">X</a> */}
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
              {/* <li><Link to="/about-dino">L'histoire de Dino</Link></li> */}
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
              {/* <li><Link to="/terms">Conditions générales de services</Link></li> */}
              {/* <li><Link to="/privacy">Politique de confidentialité</Link></li> */}
              {/* <li><Link to="/cookies">Charte en matières de cookies</Link></li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}