import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { FaMapMarkerAlt, FaRegCalendarMinus } from "react-icons/fa";
import {
  Button,
  Row,
  Col,
  ProgressBar,
  Image,
  Card,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context as DashbordCosmonaute } from "../../../context/reducers/CosmonauteReducer";
import Calendar from "../../../components/Calendar/Calendar";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import { getUserId } from "../../../services/Controller/CurrentUserServices";
import {
  formatDate,
  formatDy,
  formatTime,
  getDuration,
} from "../../../services/Controller/DateFormatter";
import { fr } from "date-fns/esm/locale";
import astro from "../../../assets/img/Athéna1.png";
import "./DashboardCosmomaute.scss";
import { useTranslation } from "react-i18next";
import { ContactAdmin } from "../../../components/ModalContact/ContactAdmin";
import ToastService from "../../../components/Toast/ToastService";
import ModalInterview from "../../../components/Interview/ModalInterview";
import { ModalMissionCausmonaute } from "../../../components/modalCosmonaute/ModalMissionCosmonaute";
import { MissionFavorite } from "../../../components/MissionFavorite/MissionFavorite";
import { timeOutCalendar } from "../../../services/Controller/CalendarService"

export default function DashboardCosmomaute() {
  const {
    UpcomingInterviews,
    AssignedMissions,
    CurrentMissions,
    getDashboardDetail,
    getCalenderDetail,
    hideToast,
    pourcentageCondidat,
    contactAdmin,
    declineInterview,
    declineMission,
    dumpInterview,
    dumpMission,
    changeDateCosmonaute,
    postMissionFavorite,
    getMissionFavorite,
    dumpMissionFavorite,
    state,
  } = useContext(DashbordCosmonaute);
  /* States */
  const [upcominginterview, setupcominginterviews] = useState(null);
  const [AssignedMission, setAssignedMission] = useState(null);
  const [CurentMission, setCurentMission] = useState(null);
  const [detail, setDetail] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalContact, setModalContact] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [typeModal, setTypeModal] = useState(false);
  const [modalMissionShow, setModalMissionShow] = useState(false);
  const [oneMission, setOneMission] = useState(null);
  const [modalDateInterviewShow, setModalDateInterviewShow] = useState(false);
  const [showed, setShowed] = useState(false);
  const [title, setTitle] = useState(null);
  const [modalFavorite, setModalFavorite] = useState(false);
  const [missionFavoriteData, setMissionFavoriteData] = useState(null);
  const target = useRef(null);
  /* Translation */
  const { t } = useTranslation();

  /* History */
  const history = useHistory();
  /* Redirect to Resume */
  const routeChange = () => {
    let path = `/user/resume/` + getUserId();
    history.push(path);
  };

  /*eslint-disable */
  //get data
  useEffect(() => {
    UpcomingInterviews();
    AssignedMissions();
    CurrentMissions();
    getDashboardDetail();
    getCalenderDetail();
    pourcentageCondidat();
    getMissionFavorite();
  }, []);

  //change state
  useEffect(() => {
    setupcominginterviews(state.interviews);
    setAssignedMission(state.AssignedMission);
    setCurentMission(state.CurrentMission);
    setDetail(state.detailDashboard);
    setMissionFavoriteData(state.missionFavoriteData);
  }, [dumpInterview, state.interviewCosmonaute]);
  /*eslint-enable */

  useEffect(() => {
    if (state.interviewCosmonaute) {
      UpcomingInterviews();
      dumpInterview();
    }
  }, [dumpInterview, UpcomingInterviews, state.interviewCosmonaute]);
  useEffect(() => {
    if (state.missionCosmonaute) {
      AssignedMissions();
      dumpMission();
    }
  }, [dumpMission, AssignedMissions, state.missionCosmonaute]);
  useEffect(() => {
    if (state.missionFavorite) {
      getMissionFavorite();
      dumpMissionFavorite();
    }
  }, [dumpMissionFavorite, getMissionFavorite, state.missionFavorite]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Edit Profil */
  function redirectTo(to) {
    if (to === "past" || to === "preselected")
      history.push({
        pathname: t("role.user") + t("sidebar.missions.path"),
        state: to,
      });
    else
      history.push({
        pathname: t("role.user") + t("sidebar.interviews.path"),
        state: to,
      });
  }

  function openModalMission(mission, title, type) {
    setTitleModal(title);
    setSelectedItem(mission);
    setTypeModal(type);
    setModalMissionShow(true);
  }

  function openModalInterview(inerview, title, type) {
    setTitleModal(title);
    setSelectedItem(inerview);
    setOneMission({
      title: inerview.title,
      entreprise: {
        username: inerview.company_name,
      },
      date_start: inerview.dateStart,
      date_end: inerview.dateEnd,
      place: inerview.place,
    });
    setTypeModal(type);
    setModalDateInterviewShow(true);
  }
  /* Détail Mission */
  function redirectToMission(id) {
    history.push(t("role.user") + t("sidebar.missions.path") + "/" + id);
  }
  /* Header Dashboard Cosmonaute */
  function DahboardCosmonauteHeader() {
    return (
      <Row>
        <Col className="pr-dashboard" lg={6} md={6} xs={12}>
          <Card className="card-cosmonaute p-2">
            <Card.Body>
              <Row className="align-items-center">
                <Col lg={8} md={6} xs={12}>
                  <label className="card-title">
                    Bienvenue {detail?.name} !
                  </label>
                  <p className="mt-2 cosmonaute-dashbord-text">
                    Complétez votre profil et mettez-le à jour
                  </p>
                  <ProgressBar
                    now={state.pourcentage ? state.pourcentage.percentage : 0}
                  />
                  <Button
                    className="buttondashbord roboto-bold mt-4"
                    onClick={() => {
                      routeChange();
                    }}
                  >
                    Modifier CV
                  </Button>
                </Col>
                <Col lg={4} md={6} xs={12} className="display-mobile-logo">
                  <Image className="w-100 w-height-image" src={astro} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} md={6} xs={12} className="margin-top-block">
          <Card className="card-cosmonaute p-1">
            <Card.Header>
              <div className="d-inline-text mr-20 current-mision-header  roboto-light fontSize-25">
                Mes Formations actuelles
              </div>
              {/* <div className="vert_title d-inline-text">
                (vous avez{" "}
                {CurentMission ? CurentMission?.restMonths + " " : " 0 "}
                mois restants)
              </div> */}
            </Card.Header>

            <Card.Body>
              <div className="d-flex">
                <span className="cardTitle mr-20">
                  {CurentMission && CurentMission.mission
                    ? CurentMission.mission.title
                    : "......"}
                </span>
                {CurentMission?.mission.dateStart
                      ?
                      <span className="month-white">
                  <small> {getDuration(
                        CurentMission?.mission.dateStart,
                        CurentMission?.mission.dateEnd
                      )}
                  </small>
                </span>
                      : null}
              </div>
              <p className="company color-company mt-2">
                {CurentMission && CurentMission.mission
                  ? CurentMission.mission.companyName
                  : "....."}
              </p>
              <div className="text-detail d-flex">
                <FaRegCalendarMinus />
                <span className="ml-10 mr-20">
                  <p>
                    Date debut :{" "}
                    {CurentMission && CurentMission.mission
                      ? formatDate(CurentMission.mission.dateStart, fr)
                      : "...."}{" "}
                    - Date fin :{" "}
                    {CurentMission && CurentMission.mission
                      ? formatDate(CurentMission.mission.dateEnd, fr)
                      : "...."}
                  </p>
                </span>
              </div>
              <div>
                <div className="text-detail  d-flex">
                  <FaMapMarkerAlt />
                  <span className="ml-10 mr-20">
                    <p>
                      Place :{" "}
                      {CurentMission && CurentMission.mission
                        ? CurentMission.mission.place
                        : "...."}
                    </p>
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Row id="number-cards" className="mt-4">
          <Col className="pr-dashboard mt-4" lg={3} md={6} xs={6}>
            <Card
              className="card-redirection"
              onClick={() => redirectTo("past")}
            >
              <Card.Body>
                <div className="text-Number">
                  {detail?.completedMissions ? detail.completedMissions : 0}
                </div>
                <p className="text-mission-Number">Formations terminées</p>
              </Card.Body>
            </Card>
          </Col>
          <Col className="pr-dashboard mt-4" lg={3} md={6} xs={6}>
            <Card
              className="card-redirection"
              onClick={() => redirectTo("preselected")}
            >
              <Card.Body>
                <div className="text-Number">
                  {detail?.pendingMissions ? detail.pendingMissions : 0}
                </div>
                <p className="text-mission-Number">Formations en attente</p>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col className="margin-top-block mt-4" lg={3} md={6} xs={6}>
            <Card
              className="card-redirection"
              onClick={() => redirectTo("passed")}
            >
              <Card.Body>
                <div className="text-Number">
                  {detail?.passedInterview ? detail?.passedInterview : 0}
                </div>
                <p className="text-mission-Number">Entretiens passés</p>
              </Card.Body>
            </Card>
          </Col> */}
          {/* <Col
            className="margin-top-block mt-4"
            lg={3}
            md={6}
            xs={6}
            onClick={() => redirectTo("upcomming")}
          >
            <Card className="card-redirection">
              <Card.Body>
                <div className="text-Number">
                  {detail?.interview ? detail.interview : 0}
                </div>
                <p className="text-mission-Number">Entretiens acceptés </p>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Row>
    );
  }

  /* Prepare Style & Data Calendar */
  const selectedDates = {
    interviews: state.calender?.interview
      ? state.calender.interview.map((el) => new Date(el.selectedDate))
      : [],
    missions: state.calender?.missions
      ? state.calender?.missions.map((el) => new Date(el.dateStart))
      : [],
  };
  const passedInterviews = state.calender?.interview
    ? state.calender.interview.map((interview, index) => ({
        ...{
          id: index,
          title: interview.title,
          selectedDate: interview.selectedDate
            ? formatDate(interview.selectedDate)
            : null,
        },
      }))
    : null;
  const passedMissions = state.calender?.missions
    ? state.calender?.missions.map((mission, index) => ({
        ...{
          id: index,
          title: mission.title,
          selectedDate: mission.dateStart
            ? formatDate(mission.dateStart)
            : null,
        },
      }))
    : null;
  const selectedDatesStyles = {
    interviews: {
      color: "white",
      backgroundColor: "#D52BFF",
      width: "8%",
      marginLeft: "2px",
      borderRadius: "5px",
      cursor: "pointer",
      padding:"auto"
    },
    missions: {
      color: "white",
      backgroundColor: "#4653DD",
      width: "8%",
      marginLeft: "2px",
      borderRadius: "5px",
      cursor: "pointer",
      padding:"auto"
    },
  };
  const OpenModal = (e) => {
    const lengthPassed = passedInterviews ? passedInterviews.length : 0;
    const lengthUpcoming = passedMissions ? passedMissions.length : 0;
    if (selectedDates.interviews) {
      for (var i = 0; i < lengthPassed; i++) {
        if (
          formatDy(e) === formatDy(selectedDates.interviews[i]) &&
          formatDy(selectedDates.interviews[i]) ===
            formatDy(passedInterviews[i].selectedDate)
        ) {
          setTitle(passedInterviews[i].title);
          setShowed(!showed);
        }
      }
    }
    if (selectedDates.missions) {
      for (var j = 0; j < lengthUpcoming; j++) {
        if (
          formatDy(e) === formatDy(selectedDates.missions[j]) &&
          formatDy(selectedDates.missions[j]) ===
            formatDy(passedMissions[j].selectedDate)
        ) {
          setTitle(passedMissions[j].title);
          setShowed(!showed);
        }
      }
    }
  };
  /* open modal contact admin*/
  function openModalContact(mission) {
    setSelectedItem(mission);
    setModalContact(true);
  }
  /* Calendar picker */
  function CalendarByDay() {
    return (
      <>
        <Row
          className="mt-4 align-items-center justify-space-between text-md"
          lg={12}
          md={12}
          sm={12}
        >
          <Col lg={3} md={12} xs={12}>
            <label className="text_agenda">Agenda </label>
          </Col>
        </Row>
        <Row className="mb-2  align-items-center ">
          <Col className="text-calendar pink mt-7 mr-20" lg={4} md={6} xs={4}>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Formations"
                defaultChecked
                disabled
              />
            </Form.Group>
          </Col>
          <Col className="text-calendar purple mt-7" lg={4} md={6} xs={4}>
            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Missions"
                defaultChecked
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        {/*<DayPicker className="agenda-picker w-100"/>*/}
        <Overlay
          target={target.current}
          show={showed}
          title={title}
          placement="right"
          onEntered={timeOutCalendar(setShowed)}
        >
          <Tooltip className="tooltip-dashbord">{title}</Tooltip>
        </Overlay>
        <Col lg={12} md={12} xs={12}>
          <Calendar
            selectedDates={selectedDates}
            selectedDatesStyles={selectedDatesStyles}
            renderDay={OpenModal}
          />
        </Col>
      </>
    );
  }

  /* */
  function UpCommingInterviews() {
    return (
      <>
        <div className="d-flex mb-4">
          <div className="w-wbkit upcom new-color-interviews">
            Prochaines formations
          </div>

          <label className="text-length pink float-right">
            {upcominginterview ? upcominginterview.length : "0"}
          </label>
        </div>
        {upcominginterview && upcominginterview.length ? (
          upcominginterview.map((item) => {
            return (
              <Card className="card-content mb-45" key={item.interviewId}>
                <Card.Body>
                  <Row className="mb-">
                    <Col lg={10} md={10} xs={10}>
                      <label
                        className="text-header-cosmonaute mr-20 link"
                        onClick={() => {
                          redirectToMission(item.missionId);
                        }}
                      >
                        {item.title}
                      </label>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <Dropdown className="float-right" align="end">
                        <Dropdown.Toggle id="dropdown-basic">
                          <i className="icon-see-more"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="text-calendar"
                            as="button"
                            onClick={() =>
                              openModalInterview(
                                item,
                                "Êtes-vous sûr de vouloir refuser l'entretien ?",
                                "decline"
                              )
                            }
                          >
                            Rejeter entretien
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-calendar"
                            as="button"
                            onClick={() =>
                              openModalInterview(
                                item,
                                "Changer date entretien",
                                "changeDate"
                              )
                            }
                          >
                            Demander changement date
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-calendar"
                            as="button"
                            onClick={() => openModalContact(item)}
                          >
                            Contacter administrateur
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row>
                    <p className="company">{item.companyName}</p>
                  </Row>
                  <Row>
                    <div className="text-detail d-flex">
                      <FaRegCalendarMinus />
                      <span className="ml-10 mr-20">
                        <p>
                          {formatDate(item.selectedDate, fr)} à{" "}
                          {formatTime(item.selectedDate)}
                        </p>
                      </span>
                    </div>
                    <div className="text-detail  d-flex">
                      <FaMapMarkerAlt />
                      <span className="ml-10 mr-20">
                        <p> {item.place ? item.place : "- - -"} </p>
                      </span>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            );
          })
        ) : (
          <NothingToShow />
        )}
        <div className="text_test mt-4 mb-4">
          <Link to="/user/missions" className="link-decoration">
            <span className="text_test">Toutes les formations </span>{" "}
            <i className="icon-arrow-left icon-left-size"></i>
          </Link>
        </div>
        {/* <Card className="specific_mission">
          <Card.Body>
            <span className="mission-text">
              Vous avez besoin d'une mission spécifique ?
            </span>
            <Row>
              <Col lg={8} md={8} xs={12}>
                <span className="petit-text">
                  Contactez l'administrateur et précisez en détail ce que vous
                  recherchez.
                </span>
              </Col>
              <Col className="display-mobile-logo" lg={4} md={4} xs={12}>
                <i className="icon-ask-admin icon-mission-size float-right"></i>
              </Col>
            </Row>
            <div onClick={() => {
                  setModalFavorite(true);
                }} className="text-fill-form roboto-italic d-flex mt-3">
              <span id="formulaire">Remplissez le formulaire ici</span>
              <i
              id="white-arrow"
                className="icon-arrow-left mt-7 link-decoration"
              ></i>
            </div>
          </Card.Body>
        </Card> */}
      </>
    );
  }

  /* */
  function GetAssignedMissions() {
    return (
      <>
        <div className="d-flex mb-4">
          <div className="w-wbkit upcom">Formations assignées</div>
          <label className="text-length blue float-right">
            {AssignedMission ? AssignedMission.number : "0"}
          </label>
        </div>
        {AssignedMission && AssignedMission.number ? (
          AssignedMission.missions.map((item) => {
            return (
              <Card className="card-content mb-45" key={item.id}>
                <Card.Body>
                  <Row className="mb-">
                    <Col lg={10} md={10} xs={10}>
                      <label
                        className="text-header-cosmonaute mr-20 link "
                        onClick={() => {
                          redirectToMission(item.id);
                        }}
                      >
                        {item.title}
                      </label>
                      <span className="text-tag-dashboard blue">
                        {item?.dateStart
                          ? getDuration(item.dateStart, item.dateEnd)
                          : "0"}
                      </span>
                    </Col>

                    <Col lg={2} md={2} xs={2}>
                      <Dropdown className="float-right" align="end">
                        <Dropdown.Toggle id="dropdown-basic">
                          <i className="icon-see-more"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            className="text-calendar"
                            s="button"
                            onClick={() =>
                              openModalMission(
                                item,
                                "Rejeter mission",
                                "decline"
                              )
                            }
                          >
                            Rejeter mission
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-calendar"
                            as="button"
                            onClick={() => openModalContact(item)}
                          >
                            Contacter administrator
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row>
                    <p className="company">
                      {item.companyName ? item.companyName : "..."}
                    </p>
                  </Row>
                  <Row>
                    <div className="text-detail d-flex">
                      <FaRegCalendarMinus />
                      <span className="ml-10 mr-20">
                        <p>
                          Start date : {formatDate(item.dateStart, fr)} - End
                          date : {formatDate(item.dateEnd, fr)}
                        </p>
                      </span>
                    </div>
                    <div className="text-detail  d-flex">
                      <FaMapMarkerAlt />
                      <span className="ml-10 mr-20">
                        <p>
                          {item.address ? item.address : "..."} -{" "}
                          {item.country ? item.country : "..."}
                        </p>
                      </span>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            );
          })
        ) : (
          <NothingToShow />
        )}
        <Row className="mt-4 mb-4">
          <div className="text_test">
            <Link to="/user/missions" className="link-decoration">
              <span className="text_test mr-10">Toutes les Formations</span>
              <i className="icon-arrow-left icon-left-size"></i>
            </Link>
          </div>
        </Row>
      </>
    );
  }

  /* Content Dashboard Cosmonaute */
  function DahboardCosmonauteContent() {
    return (
      <Row>
        <Col className="pr-dashboard" lg={4} md={12} xs={12}>
          {/* <Card className="specific_mission mb-3">
            <Card.Body>
              <span className="mission-text">
                Avez-vous besoin d'un Quizz spécifique ?
              </span>
              <div className="d-flex">
                <div className="w-wbkit petit-text mt-2">
                  <p>N'hésitez pas à répondre à ce Quizz </p>
                </div>
                <i className="icon-arrow-Record"></i>
              </div>
              <div>
                <div id="quizz" onClick={() =>
                      window.open("http://recruit.prod-projet.com/", "_blank")
                    } className="text-fill-form">
                  Cliquez ici pour répondre à ce Quizz
                  <i
                    className="icon-arrow-left"
                    style={{ cursor: "pointer" }}
                    
                  ></i>
                </div>
              </div>
            </Card.Body>
          </Card> */}
          {CalendarByDay()}
        </Col>
        <Col className="pr-dashboard pl-dashboard" lg={3} md={12} xs={12}>
          {UpCommingInterviews()}
        </Col>
        <Col className="pl-dashboard" lg={5} md={12} xs={12}>
          {GetAssignedMissions()}
        </Col>
      </Row>
    );
  }

  return (
    <>
      <ToastService data={state.toastContent} />
      <MissionFavorite
        modalFavorite={modalFavorite}
        setModalFavorite={setModalFavorite}
        postMissionFavorite={postMissionFavorite}
        detail={detail}
        missionFavoriteData={missionFavoriteData}
      />
      <ModalMissionCausmonaute
        modalMission={modalMissionShow}
        type={typeModal}
        mission={selectedItem}
        setModalMission={setModalMissionShow}
        title={titleModal}
        declineMission={declineMission}
      />
      <ModalInterview
        modalDateInterviewShow={modalDateInterviewShow}
        setModalDateInterviewShow={setModalDateInterviewShow}
        title={titleModal}
        type={typeModal}
        mission={oneMission}
        declineInterviewCosmonaute={declineInterview}
        selectedItem={selectedItem}
        changeDateCosmonaute={changeDateCosmonaute}
      />
      <ContactAdmin
        setModalContact={setModalContact}
        modalContact={modalContact}
        contactAdmin={contactAdmin}
        mission={selectedItem}
      />
      <div id="header-Content" className="header-Content">
        {DahboardCosmonauteHeader()}
      </div>
      <div id="main" className="main">
        {DahboardCosmonauteContent()}
      </div>
    </>
  );
}
