import React, { useReducer } from "react";
import {
  addDocument,
  getDocument,
  dumpDocument,
  getAllMissions,
  ProfilCosmonaute,
  ediDocument,
  addContract,
  addCra,
  getContract,
  getDocumentRecruter,
  getAllCandidatRecruted,
  getOneMission,
  importContratRecruiter,
  getMissionDetail,
  getAllRecrutedRecruiter,
  envoieMailCraContrat,
  envoieMailInvoiceContrat,
  getMissionDetailAdmin,
  sendInvoices,
  dumpCra,
  deleteDocument,
} from "../actions/DocumenCosmonaute";
import { hideToast } from "../actions/Toast";
import { ONE_MISSION_ADMIN } from "../constants/Administrator";
import {
  ADD_COSMONAUTE_DOCUMENT,
  EDIT_COSMONAUTE_DOCUMENT,
  DUMP_COSMONAUTE_DOCUMENT,
  GET_COSMONAUTE_DOCUMENT,
  RETRIEVE_COSMONAUTE,
  ADD_CONTRACT,
  ADD_CRA,
  GET_CONTRACT,
  RECRUTED_MISSION_CANDIDAT,
  GET_RECRUTED_CANDIDAT,
  RESEND_CONTRAT_RECRUITER,
  DETAIL_MISSION_RECRUTER,
  MISSION_RECRUITER_ADMIN,
  ENVOIE_EMAIL_CONTRAT_CANDIDAT,
  SEND_EMAIL_CONTRAT_RECRUITER,
  DETAIL_MISSION_RECRUTER_ADMIN,
  SEND_VOICES_ADMIN,
  DUMP_GET_CRA,
  DELETE_COSMONAUTE_DOCUMENT,
} from "../constants/Cosmonaute";
import { GET_RECRUTER_DOCUMENT } from "../constants/Recruiter";
import { HIDE_TOAST, SHOW_TOAST } from "../constants/Toast";

/* Document Reducer */
const DocumentReducer = (state, action) => {
  switch (action.type) {
    case RETRIEVE_COSMONAUTE:
      return {
        ...state,
        cosmonaute: action.payload,
        toastContent: action.toastContent,
      };
    case GET_COSMONAUTE_DOCUMENT:
      return {
        ...state,
        getDocuments: action.payload,
        toastContent: action.toastContent,
      };
    case ADD_COSMONAUTE_DOCUMENT:
      return {
        ...state, document: action.payload, toastContent: action.toastContent,
      };
      case DELETE_COSMONAUTE_DOCUMENT:
      return {
        ...state, document: action.payload, toastContent: action.toastContent,
      };
    case EDIT_COSMONAUTE_DOCUMENT:
      return {
        ...state, document: action.payload, toastContent: action.toastContent,
      };
    case DUMP_COSMONAUTE_DOCUMENT: 
      return {
        ...state, document: null, toastContent: null,
      };
    case RECRUTED_MISSION_CANDIDAT:
      return {
        ...state,
        allMission: action.payload,
        toastContent: action.toastContent,
      };
    case ADD_CONTRACT:
      return {
        ...state,
        cra: action.payload,
        toastContent: action.toastContent,
      };
    case ADD_CRA:
      return {
        ...state,
        cra: action.payload,
        toastContent: action.toastContent,
      };
    
    case GET_CONTRACT:
      return {
        ...state,
        docContract: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_GET_CRA:
        return {
          ...state,
          cra: null,
          toastContent: null,
        };
    case GET_RECRUTED_CANDIDAT:
      return {
        ...state,
        candidatRecruted: action.payload,
        toastContent: action.toastContent,
      };
    case MISSION_RECRUITER_ADMIN:
      return {
        ...state,
        recruiterRecruted: action.payload,
        toastContent: action.toastContent,
      };
    case ONE_MISSION_ADMIN:
      return {
        ...state,
        oneMission: action.payload,
        toastContent: action.toastContent,
      };
    case GET_RECRUTER_DOCUMENT:
      return {
        ...state,
        missionEntreprise : action.payload,
        toastContent: action.toastContent,
      };
    case DETAIL_MISSION_RECRUTER:
      return {
        ...state,
        missionDetail: action.payload,
        toastContent: action.toastContent,
      };
    case RESEND_CONTRAT_RECRUITER:
      return {
        ...state,
        cra: action.payload,
        toastContent: action.toastContent,
      };
    case ENVOIE_EMAIL_CONTRAT_CANDIDAT:
      return {
        ...state,
        envoieEmailCandidat: action.payload,
        toastContent: action.toastContent,
      };
    case SEND_EMAIL_CONTRAT_RECRUITER:
      return {
        ...state,
        envoieEmailEntreprise : action.payload,
        toastContent: action.toastContent,
      };
    case DETAIL_MISSION_RECRUTER_ADMIN:
      return {
        ...state,
        detailMissionAdmin: action.payload,
        toastContent: action.toastContent,
      };
    case SEND_VOICES_ADMIN:
      return {
        ...state,
        cra: action.payload,
        toastContent: action.toastContent,
      };
    /* Show | Hide Toast */
    case SHOW_TOAST:
      return { ...state, toastContent: action.toastContent };
    case HIDE_TOAST:
      return { ...state, toastContent: null };
    default:
      return state;
  }
};
/* Document State */
const DocumentState = (reducer, actions, defaultValue) => {
  const Context = React.createContext();
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);
    const boundActions = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
};
/* Recruiter Provider Context */
export const { Provider, Context } = DocumentState(
  DocumentReducer,
  {
    addDocument,
    deleteDocument,
    dumpDocument,
    getDocument,
    getAllMissions,
    ProfilCosmonaute,
    ediDocument,
    addContract,
    addCra,
    dumpCra,
    getContract,
    getDocumentRecruter,
    getAllCandidatRecruted,
    importContratRecruiter,
    getOneMission,
    getMissionDetail,
    getAllRecrutedRecruiter,
    envoieMailCraContrat,
    envoieMailInvoiceContrat,
    getMissionDetailAdmin,
    sendInvoices,
    hideToast,
  },
  {
    document: null,
    deleteDocument:null,
    getDocuments: null,
    allMission: null,
    cosmonaute: null,
    contract: null,
    cra: null,
    docContract: null,
    missionEntreprise : null,
    candidatRecruted: null,
    importContrat: null,
    missionDetail: null,
    recruiterRecruted: null,
    envoieEmailCandidat: null,
    envoieEmailEntreprise : null,
    detailMissionAdmin: null,
    sendVoice: null,
  }
);
