import Axios from "../../services/Axios/axiosServices";
import { ADD_NEW_EDUCATION, SAVE_EDUCATION, DELETE_EDUCATION, DUMP_EDUCATION } from "../constants/Cosmonaute";
import { SHOW_TOAST } from "../constants/Toast";
import { API_HOST, API_ADD_EDUCATION_PATH, API_SAVE_EDUCATION, API_REMOVE_EDUCATION_PATH } from "../../Api";
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* Add education for cosmonaute */
export const addEducation = (dispatch) => (data) => {
    const formdata = new FormData();
    formdata.append("school", data.school);
    formdata.append("degree", data.degree);
    formdata.append("fromDateEdu", data.startAtschool.toString());
    formdata.append("toDateEdu", data.finishAtschool.toString());
    formdata.append("descriptionEdu", data.Descripteducation);
    const url = API_HOST + API_ADD_EDUCATION_PATH + getUserId();
    Axios.post(url, formdata).then(
        (res) => {
            dispatch({
                type: ADD_NEW_EDUCATION,
                payload: res.data.educations,
                toastContent: {
                    status: "success",
                    body: "Education est ajoutée avec succès",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec d'ajout éducation"
                },
            });
        }
    );
};

/* Delete education for cosmonaute */
export const deleteEducation = (dispatch) => (id) => {
    const url = API_HOST + API_REMOVE_EDUCATION_PATH + id;
    Axios.delete(url).then(
        (res) => {
            dispatch({
                type: DELETE_EDUCATION,
                payload: res.data.educations,
                toastContent: {
                    status: "success",
                    body: "Education est supprimée avec succès",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec de suppression education"
                },
            });
        }
    );
};

/* save Education for Candidate */
export const saveEducation = (dispatch) => (data, id) => {
    const url = API_HOST + API_SAVE_EDUCATION + id;
    Axios.post(url, data).then(
        (res) => {
            dispatch({
                type: SAVE_EDUCATION,
                payload: res.data,
                toastContent: {
                    status: "success",
                    body: "Succès d'enregistrement des éducations",
                },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                payload: null,
                toastContent: {
                    status: "error",
                    body: "Echec d'enregistrement des éducations"
                },
            });
        }
    );
};

/* dump Education for Candidate */
export const dumpEducation = (dispatch) => () => {
    dispatch({
        type: DUMP_EDUCATION,
        payload: null,
        toastContent: null,
    });
};