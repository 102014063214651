import "./MissionsFilter.scss";
import { Row, Col, Form } from "react-bootstrap";
import { FaSearch, FaMapMarkerAlt } from "react-icons/fa";
import { formatDate } from "../../services/Controller/DateFormatter";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Context as MissionContext } from "../../context/reducers/RecruiterReducer";
import { Autocomplete, TextField } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function MissionsFilter({
  resetForm,
  setResetForm,
  defaultTabValue,
  allMissions,
  setDisplayedData,
  namePage,
  postData,
  setFilters,
}) {
  const {
    getAllSkills,
    getListRegions,
    getAllTypeSkills,
    getAllMyTypeSkills,
    getAllTypeSkillsMission,
    getAllDomainsRecruiter,
    getAllMyDomainsRecruiter,
    getAllDomainsRecruiterMission,
    state,
    getAllInspirnautes,
    getAllModes,
    getAllMyModes
  } = useContext(MissionContext);

  
  /* use History */
  const history = useHistory();

  const openFilter = [
    { id: 0, name: "Non disponible" },
    { id: 1, name: "Disponible" },
  ];

  /* Form Filter */
  const formFilter = useFormik({
    initialValues: {
      id_formateur: "",
      nom_formation: "",
      id_type: "",
      id_expertise: "",
      mode: "",
      open: "",
    },
    // onSubmit: values => {
    //     if (namePage === "follow-candidats" || namePage === "follow-intrviews") {
    //         const filterData = allMissions ? allMissions : [];
    //         const list = filterData.filter((el) => filterCondition(values, el));
    //         setDisplayedData(list);
    //     } else {
    //         const filterData = allMissions[defaultTabValue] ? allMissions[defaultTabValue] : [];
    //         const list = filterData.filter((el) => filterCondition(values, el));
    //         setDisplayedData(list);
    //     }

    // }
    onSubmit: (values) => {
      const data = {
        id_formateur: values.id_formateur,
        nom_formation: values.nom_formation,
        id_type: values.id_type,
        id_expertise: values.id_expertise,
        mode: values.mode,
        // open: values.open,
      };
      formFilter.resetForm();
      postData(data);
      window.scrollTo(0, 0);
    },
  });

  const [allTypeSkills, setAllTypeSkills] = useState([]);
  const [allModesList, setAllModesList] = useState([]);
  const [allTypeSkillsMission, setAllTypeSkillsMission] = useState([]);
  const [allDomainRecruiter, setAllDomainRecruiter] = useState([]);
  const [allFormateurs, setAllFormateurs] = useState([]);
  const [allMyDomainRecruiter, setAllMyDomainRecruiter] = useState([]);
  const [allDomainRecruiterMission, setAllDomainRecruiterMission] = useState(
    []
  );

  useEffect(() => {
    if (formFilter?.values) {
      setFilters(formFilter?.values);
    }
  }, [formFilter?.values, setFilters]);

  useEffect(() => {
    console.log("stateeeeeeeeeeeeeeeeeeeeeeeeee",state)
  }, [state]);
  useEffect(() => {
    getAllModes();
    getAllMyModes();
    getAllTypeSkills();
    getAllTypeSkillsMission();
    getAllMyTypeSkills();
    getAllDomainsRecruiter();
    getAllMyDomainsRecruiter();
    getAllDomainsRecruiterMission();
    getAllInspirnautes();
    // setFilters(formFilter?.values);
  }, []);
  
  useEffect(() => {
    if (
      state?.allTypeSkillsMission &&
      history.location.pathname === "/admin/formationsproposes"
    ) {
      setAllTypeSkills(state?.allTypeSkillsMission);
    }
    
    if (
      state?.allMyTypeSkills &&
      (history.location.pathname === "/entreprise/missions" || history.location.pathname === "/user/missions" )
    ) {
      setAllTypeSkills(state?.allMyTypeSkills);
    }
    if (
      state?.allModes
    ) {
      setAllModesList(state?.allModes?.modes);
      
    }
    if (
      state?.allMyModes &&
      (history.location.pathname === "/entreprise/missions" || history.location.pathname === "/user/missions" )
    ) {
      setAllModesList(state?.allMyModes?.user_modes);
      console.log("dddddddddddddddddddddddddddddddddd",state?.allMyModes)
    }
    if (
      state?.allTypeSkillsMission &&
      (history.location.pathname === "/user/formationsproposes" || history.location.pathname === "/entreprise/formationsproposes" )
    ) {
      setAllTypeSkills(state?.allTypeSkillsMission);
    }
    if (state?.allDomainsRecruiters) {
      setAllDomainRecruiter(state?.allDomainsRecruiters);
    }
    if (
      state?.allMyDomainsRecruiters &&
      history.location.pathname === "/user/missions"
    ) {
      setAllMyDomainRecruiter(state?.allMyDomainsRecruiters);
    }
    if (
      state?.allDomainRecruiterMission &&
      history.location.pathname === "/admin/formationsproposes"
    ) {
      setAllDomainRecruiterMission(state?.allDomainRecruiterMission);
    }
    setAllDomainRecruiterMission(state?.allDomainRecruiterMission);

    if (state?.allInspirnautes) {
      setAllFormateurs(state?.allInspirnautes);
    }
  }, [
    allTypeSkills,
    state,
    setAllFormateurs,
    setAllTypeSkills,
    formFilter?.values,
  ]);

  /* reset Form */
  // useEffect(() => {
  //     if (resetForm) {
  //         formFilter.resetForm();
  //         setResetForm(false);
  //     }
  // }, [resetForm, setResetForm, formFilter]);

  /* All Conditions Filter */
  // function filterCondition(FormValues, mission) {
  //     if (namePage === "follow-candidats") {
  //         return (
  //             (FormValues.title === "" || (FormValues.title !== "" && mission.missionTitle?.toLowerCase()?.indexOf(FormValues.title) !== -1))
  //             && (FormValues.name === "" || (FormValues.name !== "" && mission.name?.toLowerCase()?.indexOf(FormValues.name) !== -1))
  //             && (FormValues.startDate === "" || (FormValues.startDate !== "" && formatDate(FormValues.startDate) <= formatDate(mission.dateStart)))
  //             // && (FormValues.endDate === "" || (FormValues.endDate !== "" && formatDate(FormValues.endDate) >= formatDate(mission.dateEnd)))
  //         )
  //     } else if (namePage === "follow-intrviews") {
  //         return (
  //             (FormValues.title === "" || (FormValues.title !== "" && mission.missionTitle?.toLowerCase()?.indexOf(FormValues.title) !== -1))
  //             && (FormValues.name === "" || (FormValues.name !== "" && mission.name?.toLowerCase()?.indexOf(FormValues.name) !== -1))
  //             && (FormValues.startDate === "" || (FormValues.startDate !== "" && formatDate(FormValues.startDate) <= formatDate(mission.firstDate)))
  //             // && (FormValues.endDate === "" || (FormValues.endDate !== "" && formatDate(FormValues.endDate) >= formatDate(mission.secondDate)))
  //         )
  //     } else {
  //         return (
  //             (FormValues.title === "" || (FormValues.title !== "" && mission.title?.toLowerCase()?.indexOf(FormValues.title) !== -1))
  //             && (FormValues.place === "" || (FormValues.place !== "" && mission.place?.toLowerCase()?.indexOf(FormValues.place) !== -1))
  //             && (FormValues.startDate === "" || (FormValues.startDate !== "" && formatDate(FormValues.startDate) <= formatDate(mission.dateStart)))
  //             // && (FormValues.endDate === "" || (FormValues.endDate !== "" && formatDate(FormValues.endDate) >= formatDate(mission.dateEnd)))
  //         )
  //     }
  // }
  return (
    <>
      <Form onChange={formFilter.onSubmit}>
        <Row>
          {history.location.pathname === "/user/missions" ? null : (
            <Col lg={3} md={6} xs={12}>
              <Form.Group className="position-relative">
                <Autocomplete
                  options={allFormateurs}
                  getOptionLabel={(option) => `${option.username}`} // Assuming you have these properties
                  onChange={(event, newValue) => {
                    formFilter.setFieldValue(
                      "id_formateur",
                      newValue ? newValue.id : ""
                    ); // Set the value for Formik
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filtrer par formateur"
                      variant="outlined"
                      error={Boolean(
                        formFilter.errors.id_formateur &&
                          formFilter.touched.id_formateur
                      )}
                      helperText={formFilter.errors.id_formateur}
                    />
                  )}
                  value={
                    allFormateurs.find(
                      (option) => option.id === formFilter.values.id_formateur
                    ) || null
                  } // Set the value
                />
              </Form.Group>
            </Col>
          )}
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              {/* <FaSearch className="position-absolute searsh-Input" /> */}
              <Form.Control
                className="input-filter"
                placeholder="Nom de la formation"
                name="nom_formation"
                id="nom_formation"
                type="text"
                onChange={formFilter.handleChange}
                value={formFilter.values.nom_formation}
                onBlur={formFilter.handleBlur}
              />
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <Form.Select
                className="input-filter"
                placeholder="Recherchez par la catégorie de formation"
                name="id_type"
                id="id_type"
                type="text"
                onChange={formFilter.handleChange}
                value={formFilter.values.id_type}
                onBlur={formFilter.handleBlur}
              >
                <>
                  <option className="text-bold" defaultValue value="">
                    {" "}
                    Catégorie de formation{" "}
                  </option>

                  {allTypeSkills && allTypeSkills.length
                    ? allTypeSkills.map((el, index) => {
                        return (
                          <option
                            className="color-black"
                            key={"option" + index}
                            value={el.id}
                          >
                            {el.name}
                          </option>
                        );
                      })
                    : null}
                </>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <Form.Select
                className="input-filter"
                placeholder="Recherchez par le domaine d'expertise"
                name="id_expertise"
                id="id_expertise"
                type="text"
                onChange={formFilter.handleChange}
                value={formFilter.values.id_expertise}
                onBlur={formFilter.handleBlur}
              >
                <>
                  <option className="text-bold" defaultValue value="">
                    {" "}
                    Domaine d'expertise{" "}
                  </option>
                  {history.location.pathname === "/user/missions" 
                    ? allMyDomainRecruiter && allMyDomainRecruiter.length
                      ? allMyDomainRecruiter.map((el, index) => (
                          <option
                            className="color-black"
                            key={"option" + index}
                            value={el.id}
                          >
                            {el.name}
                          </option>
                        ))
                      : null
                    : (history.location.pathname === "/admin/formationsproposes" ||history.location.pathname === "/entreprise/formationsproposes")
                    ? allDomainRecruiterMission &&
                      allDomainRecruiterMission.length
                      ? allDomainRecruiterMission.map((el, index) => (
                          <option
                            className="color-black"
                            key={"option" + index}
                            value={el.id}
                          >
                            {el.name}
                          </option>
                        ))
                      : null
                    : allDomainRecruiter && allDomainRecruiter.length
                    ? allDomainRecruiter.map((el, index) => (
                        <option
                          className="color-black"
                          key={"option" + index}
                          value={el.id}
                        >
                          {el.name}
                        </option>
                      ))
                    : null}
                </>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <Form.Select
                className="input-filter"
                placeholder=""
                name="mode"
                id="mode"
                type="text"
                onChange={formFilter.handleChange}
                value={formFilter.values.mode}
                onBlur={formFilter.handleBlur}
              >
                <>
                  <option className="text-bold" defaultValue value="">
                    Mode
                  </option>
                  {allModesList?.map((el, index) => {
                    return (
                      <option
                        className="color-black"
                        key={"option" + index}
                        value={el.title}
                      >
                        {el.title}
                      </option>
                    );
                  })}
                </>
              </Form.Select>
            </Form.Group>
          </Col>
          {/* <Col lg={3} md={6} xs={12}>
            <Form.Group className="position-relative">
              <Form.Select
                className="input-filter"
                placeholder=""
                name="open"
                id="open"
                type="text"
                onChange={formFilter.handleChange}
                value={formFilter.values.open}
                onBlur={formFilter.handleBlur}
              >
                <>
                  <option className="text-bold" defaultValue value="">
                  Disponibilité
                  </option>
                  {openFilter.map((el, index) => {
                    return (
                      <option
                        className="color-black"
                        key={"option" + index}
                        value={el.id}
                      >
                        {el.name}
                      </option>
                    );
                  })}
                </>
              </Form.Select>
            </Form.Group>
          </Col> */}
          {/* <Form.Group className="position-relative">
            <FaMapMarkerAlt className="position-absolute searsh-Input" />
            <Form.Control
              className="input-filter"
              placeholder="Rechercher par lieu"
              type="text"
              name="place"
              id="place"
              onChange={formFilter.handleChange}
              value={formFilter.values.place}
              onBlur={formFilter.handleBlur}
            />
          </Form.Group> */}
        </Row>
      </Form>
    </>
  );
}
