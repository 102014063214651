import { useContext, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import ToastService from "../../../components/Toast/ToastService";
import { Context as DocumentContext } from "../../../context/reducers/DocumentReducer";
import { useHistory, useLocation } from "react-router-dom";
import "./DocumentRecruite.scss";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import Paginate from "../../../components/Paginate/Paginate";
import {
  getUserId,
  isAdmin,
  isRecruiter,
} from "../../../services/Controller/CurrentUserServices";
function DocumentRecruiter() {
  const { state, getDocumentRecruter, hideToast } = useContext(DocumentContext);
  const [listeMission, setListeMission] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  /* use History */
  const history = useHistory();
  const location = useLocation();
  const idRecruter = location.pathname.split("/")[3];
  const someEventHandler = (id) => {
    if (isRecruiter()) {
      history.push("/recruiter/mission/" + id);
    } else if (isAdmin()) {
      history.push("/admin/recruted/" + idRecruter + "/mission/" + id);
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (isRecruiter()) {
      getDocumentRecruter(getUserId());
    } else {
      const idMission = location.pathname.split("/")[3];
      getDocumentRecruter(idMission);
    }
  }, []);
  /* eslint-enable */
  useEffect(() => {
    setListeMission(state.missionEntreprise );
  }, [state]);
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  function ListeRecruiterItem() {
    return currentItems.map((item, index) => (
      <tr key={index}>
        <td className="date-document pt-4 pb-4">
          {item ? item.mission_title : null}
        </td>
        <td className="pt-4 date-document">
          <a download={true} target="_blank" rel="noreferrer noopener" media="true" href={item.last_invoice?.doc_url}>
            {item.last_invoice ? item.last_invoice?.doc_name : null}
          </a>
        </td>
        <td className="pt-4 date-document">{item ? item.nombre_docs : 0}</td>
        <td className="pt-4">
          {" "}
          <div className="pointer date-document">
            {/* Tooltip */}
            <ReactTooltip id="Consult" place="bottom">
              {" "}
              Consulter{" "}
            </ReactTooltip>
            <span
              className="btn-detail blue"
              onClick={() => {
                someEventHandler(item.id_mission);
              }}
            >
              <FaEye data-tip data-for="Consult" />
            </span>
          </div>
        </td>
      </tr>
    ));
  }
  return (
    <>
      <ToastService data={state.toastContent} />
      <div id="header-Content" className="header-Content"></div>
      <div id="main" className="main">
        <div className="title-page">Mes Documents par Mission</div>
        <Row
          lg={12}
          md={12}
          xs={12}
          className="d-flex justify-content-around mt-5"
        >
          <Col
            lg={12}
            md={12}
            xs={12}
            className="border-Col-document margin-left-col1"
          >
            <div>
              <Table className="table user-table">
                <thead>
                  <tr>
                    <th>Mission</th>
                    <th>Dernière Facture</th>
                    <th>Nombre de document</th>
                    <th>Détail Mission</th>
                  </tr>
                </thead>
                <tbody>
                  {listeMission && listeMission.length
                    ? ListeRecruiterItem()
                    : null}
                </tbody>
              </Table>
              {!listeMission || !listeMission.length ? (
                <NothingToShow type="array" />
              ) : (
                <Paginate
                  items={listeMission}
                  setCurrentItems={setCurrentItems}
                  itemsPerPage={5}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
export default DocumentRecruiter;
