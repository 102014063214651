

/* Get Users Stats  */
import { API_HOST, API_CONTACT_ADMIN } from "../../Api";
import axiosServices from "../../services/Axios/axiosServices";
import { CONTACT_ADMIN } from "../constants/ContactAdmin";
import { SHOW_TOAST } from "../constants/Toast";

export const contactAdmin = (dispatch) => (data, item) => {
    // URL get list Candidatures recruiters for Admin
    const URL = API_HOST + API_CONTACT_ADMIN;
    let Data = { 'data': data, 'mission': item }
    // Call API list Candidatures recruiters for Admin
    axiosServices.post(URL, Data).then(
        (res) => {
            dispatch({
                type: CONTACT_ADMIN,
                payload: res.data,
                toastContent: { status: 'success', body: "email envoyer avec success" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error',  body: "Echec d'envoyer l'email" }
            });
        }
    );
}