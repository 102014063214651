import React, { useReducer } from "react";
import { SHOW_TOAST, HIDE_TOAST } from "../constants/Toast";
import { hideToast } from "../actions/Toast";
import {
  inscriptionInspirnaute,
  getListSkills,
  inscriptionRecruter,
  getDomainRecruter,
  sendContact,
  checkEmail,
} from "../actions/Inscription";
import { getListRegions } from "../actions/Regions";
import {
  ADD_INSPIRNAUTE,
  GET_SKILLS,
  ADD_RECRUTER,
  GET_DOMAIN_RECRUTER,
  ENVOIE_CONTACT,
  CHECK_EMAIL,
} from "../constants/Inscription";

/* Inscription Reducer */
const InscriptionReducer = (state, action) => {
  switch (action.type) {
    case ADD_INSPIRNAUTE:
      return {
        ...state,
        inscription: action.payload,
        toastContent: action.toastContent,
      };
    /* add recruter */
    case ADD_RECRUTER:
      return {
        ...state,
        recruter: action.payload,
        toastContent: action.toastContent,
      };
    /*send contact*/
    case ENVOIE_CONTACT:
      return {
        ...state,
        contact: action.payload,
        toastContent: action.toastContent,
      };
    /* SKILLS */
    case GET_SKILLS:
      return {
        ...state,
        allSkills: action.payload,
        toastContent: action.toastContent,
      };
    case GET_DOMAIN_RECRUTER:
      return {
        ...state,
        doaminRecruter: action.payload,
        toastContent: action.toastContent,
      };
    case CHECK_EMAIL:
      return {
        ...state,
        emailUser: action.payload,
      };
    /* Show | Hide Toast */
    case SHOW_TOAST:
      return { ...state, toastContent: action.toastContent };
    case HIDE_TOAST:
      return { ...state, toastContent: null };
    default:
      return state;
  }
};

/* Inscription State */
const InscriptionState = (reducer, actions, defaultValue) => {
  const Context = React.createContext();
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);
    const boundActions = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }
    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
};

/* Inscription Provider Context */
export const { Provider, Context } = InscriptionState(
  InscriptionReducer,
  {
    inscriptionInspirnaute,
    getListSkills,
    inscriptionRecruter,
    hideToast,
    getDomainRecruter,
    sendContact,
    checkEmail,
    getListRegions
  },
  {
    toastContent: null,
    allSkills: null,
    inscription: null,
    recruter: null,
    doaminRecruter: null,
    contact: null,
    emailUser: null,
  }
);
