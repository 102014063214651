import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Dropdown,
  Form,
  Image,
  Row,
  Tabs,
  Tab,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import "./InerviewsCosmonaute.scss";
import "react-day-picker/lib/style.css";
import banner from "../../../assets/img/banner-interviews.png";
import { Context as InterviewsCosmonaute } from "../../../context/reducers/CosmonauteReducer";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import ModalInterview from "../../../components/Interview/ModalInterview";
import { FaLink, FaMapMarkerAlt, FaRegCalendarMinus } from "react-icons/fa";
import Paginate from "../../../components/Paginate/Paginate";
import { getStatus } from "../../../services/Controller/MissionServices";
import {
  formatDate,
  formatDy,
  formatTime,
} from "../../../services/Controller/DateFormatter";
import ToastService from "../../../components/Toast/ToastService";
import InterviewsFilter from "../../../components/InterviewsFilter/InterviewsFilter";
import Calendar from "../../../components/Calendar/Calendar";
import { useHistory } from "react-router-dom";
import { ContactAdmin } from "../../../components/ModalContact/ContactAdmin";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { timeOutCalendar } from "../../../services/Controller/CalendarService";

function InerviewsCosmonaute() {
  /* use History */
  const history = useHistory();
  /* Translation */
  const { t } = useTranslation();
  const {
    GET_ALL_INTERVIEW,
    declineInterview,
    dumpInterview,
    acceptInterviewCosmonaute,
    changeDateCosmonaute,
    hideToast,
    contactAdmin,
    state,
  } = useContext(InterviewsCosmonaute);
  const [allInterviews, setAllInterview] = useState({
    All: [],
    upcomming: [],
    declined: [],
  });
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [defaultTabValue, setDefaultTabValue] = useState("All");
  const [modalContact, setModalContact] = useState(false);
  /* */
  const [modalDateInterviewShow, setModalDateInterviewShow] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [oneMission, setOneMission] = useState(null);
  const [resetForm, setResetForm] = useState(false);
  /* */
  /*eslint-disable */
  useEffect(() => {
    if (history.location.state) setDefaultTabValue(history.location.state);
    GET_ALL_INTERVIEW();
  }, []);
  /*eslint-enable */
  /*  */
  useEffect(() => {
    if (state.allInterviewsCosmonaute) {
      let list = state.allInterviewsCosmonaute;
      const allData = {
        All: list,
        proposed: list.filter((el) => el.status === "pending"),
        upcomming: list.filter((el) => el.status === "accepted"),
        passed: list.filter((el) => el.status === "passed"),
        declined: list.filter((el) => el.status === "rejected"),
        changed: list.filter((el) => el.status === "change_date"),
      };
      setAllInterview(allData);
      setDisplayedData(allData[defaultTabValue]);
    }
  }, [state, defaultTabValue]);

  /* refresh after change | Reject Interview */
  useEffect(() => {
    if (state.interviewCosmonaute) {
      GET_ALL_INTERVIEW();
      dumpInterview();
    }
  }, [dumpInterview, GET_ALL_INTERVIEW, state.interviewCosmonaute]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);

  /* open modal contact admin*/
  function openModalContact(mission) {
    setSelectedItem(mission);
    setModalContact(true);
  }
  /* Open Modal Interview */
  function openModalInterview(inerview, title, type) {
    setTitleModal(title);
    setSelectedItem(inerview);
    setOneMission({
      title: inerview.title,
      entreprise: {
        username: inerview.company_name,
      },
      date_start: inerview.dateStart,
      date_end: inerview.dateEnd,
      place: inerview.place,
    });
    setTypeModal(type);
    setModalDateInterviewShow(true);
  }
  /* Détail Mission */
  function redirectToMission(id) {
    history.push(t("role.user") + t("sidebar.missions.path") + "/" + id);
  }
  const today = new Date();
  /* Interview Cosmonaute Header */
  function InterviewCosmonauteHeader() {
    return (
      <Row>
        <Col className="mt-5 " xl={6} lg={6} md={12} xs={12}>
          <div className="text-professionl mt-5 mb-3">Astuces rapides :</div>
          <div className="interviews-paragraphe">
            <p className="paragraphe-style p-2">
            Parcourez les profils des autres formateurs ainsi que le détail des formations pour vous inspirer et si vous voulez nous en suggérer une nouvelle, remplisser le formulaire de mission spécifique
            </p>
          </div>
        </Col>
        <Col
          xl={4}
          lg={4}
          md={12}
          xs={12}
          className="banner-interview display-mobile-logo"
        >
          {/* <Image id="banner" src={banner} className="width-screen"></Image> */}
        </Col>
      </Row>
    );
  }

  /* Interview Cosmonaute Main */
  function showInterviewContent() {
    return (
      <>
        {currentItems.map((el, index) => {
          return (
            <Card className="card-content mb-3" key={"interview" + index}>
              <Card.Body>
                <div className="d-flex justify-space-between align-items-center mb-3">
                  <div className="">
                    <label className="text-header mr-20 link" onClick={() => { redirectToMission(el.missionId); }}>
                      {el ? el.title : "- - -"}
                    </label>
                    {el.status
                      ?
                      <label className={"text-tag " + getStatus(el.status)?.class} >
                        {getStatus(el.status)?.label}
                      </label>
                      : null}
                  </div>
                  <Dropdown className="float-right" align="end">
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="icon-see-more"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {el.status === "pending" &&
                        formatDate(el?.firstDate) > formatDate(today) ? (
                        <Dropdown.Item className="text-calendar" as="button"
                          onClick={() => openModalInterview(el, "Accepter entretien", "acceptDate")}>
                          Accepter entretien
                        </Dropdown.Item>
                      ) : null}
                      {el.status !== "passed" && el.status !== "rejected" && el.status === "pending" && formatDate(el?.firstDate) > formatDate(today)
                        ?
                        <Dropdown.Item className="text-calendar" as="button"
                          onClick={() => openModalInterview(el, "Êtes-vous sûr de vouloir refuser l'entretien ?", "decline")}
                        >
                          Rejeter entretien
                        </Dropdown.Item>
                        : null}
                      {el.status !== "passed" && el.status !== "rejected" ? (
                        <Dropdown.Item
                          className="text-calendar" as="button"
                          onClick={() => openModalInterview(el, "Changer date entretien", "changeDate")}
                        >
                          Demander changement date
                        </Dropdown.Item>
                      ) : null}

                      <Dropdown.Item className="text-calendar" as="button" onClick={() => openModalContact(el)} >
                        Contacter administrateur
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <p className="company">{el.fullName}</p>
                <div className="text-detail d-flex mb-2">
                  <FaRegCalendarMinus />
                  <span className="ml-10 mr-20">
                    {el.status === "rejected"
                      ? "Entretien annulé"
                      :
                      el.selectedDate
                        ? (format(new Date(el.secondDate), "dd/MM/yyyy") + " à " + formatTime(el.selectedDate))
                        : el.firstDate && el.secondDate
                          ?
                          <>
                            <div className="date-interview">
                              Le {format(new Date(el.firstDate), "dd/MM/yyyy") + " à " + formatTime(el.firstDate) + " "}
                              ou bien Le {format(new Date(el.secondDate), "dd/MM/yyyy") + " à " + formatTime(el.secondDate)}
                            </div>
                          </>
                          : "- - -"
                    }
                  </span>
                </div>
                <div className="text-detail d-flex mb-2">
                  <FaMapMarkerAlt />
                  <span className="ml-10 mr-20">
                    {el.place ? el.place : "- - -"}
                  </span>
                </div>
                {el.status === "accepted" && el.selectedDate
                  ?
                  <div className="text-detail d-flex mb-3">
                    <FaLink />
                    {
                      (el.selectedDate === el.firstDate) && el.link
                        ? <span className="ml-10 mr-20 text-underline pointer" onClick={() => { openLink(el.link); }}>
                          {el.link}
                        </span>
                        :
                        (el.selectedDate === el.secondDate) && el.linkTwo
                          ? <span className="ml-10 mr-20 text-underline pointer" onClick={() => { openLink(el.linkTwo); }}>
                            {el.linkTwo}
                          </span>
                          : <span className="ml-10 mr-20"> - - -</span>
                    }
                  </div>
                  : null}
              </Card.Body>
            </Card>
          );
        })}
        <Paginate
          items={displayedData}
          setCurrentItems={setCurrentItems}
          itemsPerPage={10}
        />
      </>
    );
  }

  /* Open Link Interview */
  function openLink(link) {
    window.open(link);
  }

  /* Change Tab */
  function onSelectTab(tab) {
    setResetForm(true);
    if (allInterviews[tab] && allInterviews[tab].length)
      setDisplayedData(allInterviews[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }

  /* Prepare Style & Data Calendar */
  const selectedDates = {
    upcomming: allInterviews.upcomming
      ? allInterviews.upcomming.map((el) => new Date(el.selectedDate))
      : [],
    passed: allInterviews.passed
      ? allInterviews.passed.map((el) => new Date(el.selectedDate))
      : [],
  };

  const passedInterviews =
    allInterviews.passed && allInterviews.passed.length
      ? allInterviews.passed.map((work, index) => ({
        ...{
          id: index,
          title: work.title,
          selectedDate: work.selectedDate
            ? formatDate(work.selectedDate)
            : null,
        },
      }))
      : null;
  const upcomingInterviews =
    allInterviews.upcomming && allInterviews.upcomming.length
      ? allInterviews.upcomming.map((work, index) => ({
        ...{
          id: index,
          title: work.title,
          selectedDate: work.selectedDate
            ? formatDate(work.selectedDate)
            : null,
        },
      }))
      : null;
  const selectedDatesStyles = {
    upcomming: {
      color: "white",
      backgroundColor: "#D52BFF",
      width: "10%",
      marginLeft: "2px",
      borderRadius: "10px",
      cursor: "pointer",
    },
    passed: {
      color: "white",
      width: "10%",
      border: "1px solid #0f67d9",
      borderRadius: "10px",
      cursor: "pointer",
    },
  };
  const [showed, setShowed] = useState(false);
  const [title, setTitle] = useState(null);
  const target = useRef(null);
  const OpenModal = (e) => {
    const lengthPassed = passedInterviews ? passedInterviews.length : 0;
    const lengthUpcoming = upcomingInterviews ? upcomingInterviews.length : 0;
    if (selectedDates.passed) {
      for (var i = 0; i < lengthPassed; i++) {
        if (
          formatDy(e) === formatDy(selectedDates.passed[i]) &&
          formatDy(selectedDates.passed[i]) ===
          formatDy(passedInterviews[i].selectedDate)
        ) {
          setTitle(passedInterviews[i].title);
          setShowed(!showed);
        }
      }
    }
    if (selectedDates.upcomming) {
      for (var j = 0; j < lengthUpcoming; j++) {
        if (
          formatDy(e) === formatDy(selectedDates.upcomming[j]) &&
          formatDy(selectedDates.upcomming[j]) ===
          formatDy(upcomingInterviews[j].selectedDate)
        ) {
          setTitle(upcomingInterviews[j].title);
          setShowed(!showed);
        }
      }
    }
  };
  /* Calendar picker */
  function CalendarByDay() {
    return (
      <Row>
        <Overlay
          target={target.current}
          show={showed}
          title={title}
          placement="right"
          onEntered={timeOutCalendar(setShowed)}
        >
          <Tooltip className="tooltip-transform">{title}</Tooltip>
        </Overlay>
        <Col lg={12} md={12} xs={12}>
          <Calendar
            selectedDates={selectedDates}
            selectedDatesStyles={selectedDatesStyles}
            renderDay={OpenModal}
          />
        </Col>
        <Col lg={12} md={12} xs={12} className="mt-4">
          <Row>
            <Col className="text-calendar pink">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Entretiens à venir"
                  defaultChecked
                  disabled
                />
              </Form.Group>
            </Col>
            <Col className="text-calendar blue">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Entretiens passés"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      <ContactAdmin
        setModalContact={setModalContact}
        modalContact={modalContact}
        contactAdmin={contactAdmin}
        mission={selectedItem}
      />
      <ModalInterview
        modalDateInterviewShow={modalDateInterviewShow}
        setModalDateInterviewShow={setModalDateInterviewShow}
        title={titleModal}
        type={typeModal}
        mission={oneMission}
        declineInterviewCosmonaute={declineInterview}
        selectedItem={selectedItem}
        acceptInterviewCosmonaute={acceptInterviewCosmonaute}
        changeDateCosmonaute={changeDateCosmonaute}
      />

      <div id="header-Content" className="header-Content">
        {InterviewCosmonauteHeader()}
      </div>

      <div id="main" className="main">
        <Row>
          <Col className="mb-4" lg={8} md={12} xs={12}>
            <Row className="align-items-baseline align-md">
              <Col lg={3} md={12} xs={12} className="w-md-10 mb-2">
                <label className="title-page">Entretiens</label>
              </Col>
              <Col lg={9} md={12} xs={12} className="w-md-100">
                <InterviewsFilter
                  resetForm={resetForm}
                  setResetForm={setResetForm}
                  defaultTabValue={defaultTabValue}
                  allInterviews={allInterviews}
                  setDisplayedData={setDisplayedData}
                />
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="All"
              activeKey={defaultTabValue}
              onSelect={(k) => onSelectTab(k)}
              className="mb-3 w-100"
            >
              <Tab
                eventKey="All"
                title={
                  "Tous (" +
                  (allInterviews && allInterviews.All
                    ? allInterviews.All.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="proposed"
                title={
                  "En attente (" +
                  (allInterviews && allInterviews.proposed
                    ? allInterviews.proposed.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="changed"
                title={
                  "À changer (" +
                  (allInterviews && allInterviews.changed
                    ? allInterviews.changed.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="upcomming"
                title={
                  "À venir (" +
                  (allInterviews && allInterviews.upcomming
                    ? allInterviews.upcomming.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="passed"
                title={
                  "Passés (" +
                  (allInterviews && allInterviews.passed
                    ? allInterviews.passed.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="declined"
                title={
                  "Rejetés (" +
                  (allInterviews && allInterviews.declined
                    ? allInterviews.declined.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
            </Tabs>
          </Col>
          <Col lg={4} md={12} xs={12}>
            {CalendarByDay()}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default InerviewsCosmonaute;
