import React, { useEffect } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import "../TermsConditions/TemrsConditions";
import "./legalNotice.scss";
import terms from "../../assets/img/book3.png";
import terms1 from "../../assets/img/6.png";

import etoile from "../../assets/img/inscription/etoile.png";
import { Link, useLocation } from "react-router-dom";
export default function LegalNotice() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      <section className="terms termsLegale">
        <Header />
        {/* <img src={etoile} className="etoile" alt="etoile"></img> */}
        {/* <img src={terms} alt="planet" className="terms-planet1"></img> */}
        {/* <ScrollAnimation animateIn="flipInY" animateOut="flipOutY"> */}
          <div className="text-center fontSize-140 yellow-color text-uppercase roboto-condensed-bold padding-titre text-spacing-1">
            {t("lega.title")}
          </div>
        {/* </ScrollAnimation> */}
        {/* <img src={terms1} alt="planet" className="terms-planet2"></img> */}
        {/* <div className="stars"></div> */}
        {/* <ScrollAnimation
          animateIn="tada"
          initiallyVisible={true}
          className="padding-icon"
        > */}
          <div className="padding-icon scroll-downs mt-5 ">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        {/* </ScrollAnimation> */}
        <img src={terms} alt="planet" className="terms-planet3"></img>

      </section>
      <section className="conditions conditionsLegale">
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section1.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section1.description1")}</div>
            <div className="line-text mt-2">
              {t("lega.section1.description2")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section1.description3")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section1.description4")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section2.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section2.description1")}</div>
            <div className="line-text mt-2">
              {t("lega.section2.description2")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description3")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description4")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description5")}
            </div>
            <div className="line-text mt-2">
              {t("lega.section2.description6")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section3.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section3.description1")}</div>
            <div className="line-text mt-4">
              {t("lega.section3.description2")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section4.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section">
            <div className="line-text">{t("lega.section4.description1")}</div>
          </div>
          <div className="margin-section">
            <div className="line-text">{t("lega.section4.description2")}</div>
          </div>
        </div>
      </section>
      <section className="conditions-page1  conditionslegale-page1">
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section5.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section5.description1")}</div>
            <div className="line-text mt-4">
              {t("lega.section5.description2")}
            </div>
          </div>
        </div>
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section6.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section6.description1")}</div>
            <div className="line-text mt-3">
              {t("lega.section6.description2")}
            </div>
            <div className="line-text mt-3">
              {t("lega.section6.description3")}
            </div>
            <div className="line-text mt-3">
              {t("lega.section6.description4")}
            </div>
          </div>
        </div>
      </section>
      <section className="conditions-page2  conditionslegale-page2">
        <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section8.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text1")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description1")}
            </div>
          </div>
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text2")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description2")}
            </div>
          </div>
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text3")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description3")}
            </div>
          </div>
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text4")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description4")}
            </div>
          </div>
        </div>
        <div
          // style={{ paddingBottom: "330px" }}
          className="white-color  roboto-light fontSize-25"
        >
          <div className="margin-section mb-4">
            <div className="line-text">{t("lega.section8.text5")}</div>
            <div className="line-text mt-4">
              {t("lega.section8.description5")}
            </div>
            
          </div>
          <div className="margin-section green-color fontSize-50 roboto-condensed ptlegal-20">
          {t("lega.section7.title")}
        </div>
        <div className="white-color  roboto-light fontSize-25">
          <div className="margin-section">
            <div className="line-text">{t("lega.section7.description1")}</div>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description2")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description3")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description4")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description5")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description6")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description7")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description8")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description9")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description10")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description11")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description12")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description13")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description14")}
            </div><br/>
            <div className="line-text mt-2 ml-5">
              {t("lega.section7.description15")}
            </div>
          </div>
        </div>
        </div>
        
        <div className="groupe-legal pg-termlegale"></div>
        
      </section>
      <section id="section10">
          <Row className="line-height-row">
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
              </ScrollAnimation> */}
              <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold ml-big text-uppercase fontSize-100 yellow-color text-spacing-3 infini mt-80 pt-40">
                  {t("home.infini")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                  {t("home.contact")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.adress")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.phone")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.mail")}
                </p>
                <p className="roboto-light text-center fontSize-25 notice text-underline">
                  <Link to="/notice">{t("home.mention")}</Link>
                </p>
              </div>
            </Col>
          </Row>
        </section>
<Footer />
    </div>
  );
}
function Footer() {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
          <h4 className="footer-title">Acropolia Talents</h4>
          <p>Acropolia place l’humain au <br />coeur de ses préoccupations</p>
          <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a href="https://www.instagram.com/acropolia_academy/" target="_blank" rel="noopener noreferrer" className="social-icon instagram">IG</a>
              <a href="https://www.facebook.com/profile.php?id=61551011631802" target="_blank" rel="noopener noreferrer" className="social-icon facebook">FB</a>
              {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon linkedin">LN</a> */}
              <a href="https://www.youtube.com/@acropolia_academy" target="_blank" rel="noopener noreferrer" className="social-icon youtube">YT</a>
              {/* <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="social-icon x">X</a> */}
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
              {/* <li><Link to="/about-dino">L'histoire de Dino</Link></li> */}
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
              {/* <li><Link to="/terms">Conditions générales de services</Link></li> */}
              {/* <li><Link to="/privacy">Politique de confidentialité</Link></li> */}
              {/* <li><Link to="/cookies">Charte en matières de cookies</Link></li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
