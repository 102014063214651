/* get Token */
export const getToken = () => {
    return localStorage.getItem('token') || null;
};

/* get Refresh Token */
export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token') || null;
};

/* set Token */
export const setToken = (token) => {
    localStorage.setItem("token", token);
};

/* set Refresh refresh token */
export const setRefreshToken = (refreshToken) => {
    localStorage.setItem("refresh_token", refreshToken);
};