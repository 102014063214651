import { useFormik } from "formik";
import { Button, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import {
  isAdmin,
  isCosmonaute,
  isRecruiter,
} from "../../services/Controller/CurrentUserServices";
import {
  ErrorFeild,
  ErrorClass,
} from "../../services/Controller/ValidationForm";
export default function ModalUploadContract({
  modalShow,
  setShowModal,
  id,
  addContract,
  idRecruiter,
  envoieMailInvoiceContrat,
  envoieMailCraContrat,
  reload,
  name,
  importContratRecruiter,
}) {
  const validationFiles = Yup.object({
    file: Yup.mixed()
      .required("file est obligatoire")
      .test("fileType", "Format non supporté", function (values, event) {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
        ];
        return SUPPORTED_FORMATS.includes(formik.values.file?.type);
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: "",
    },
    validationSchema: validationFiles,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("contrat", values.file);
      formData.append("mission_id", id);
      const formDataVoices = new FormData();
      formDataVoices.append("contrat", values.file);
      formDataVoices.append("invoices", values.file);
      formDataVoices.append("recruiter_id", idRecruiter);
      const formDataEmail = new FormData();
      formDataEmail.append("contrat", values.file);
      formDataEmail.append("cra", values.file);
      formDataEmail.append("profil_id", idRecruiter);

      if (isAdmin() && name === "recruted") {
        envoieMailInvoiceContrat(formDataVoices);
      } else if (isRecruiter()) {
        importContratRecruiter(formData);
      } else if (isCosmonaute()) {
        addContract(formData);
      } else if (isAdmin() && name === "user") {
        envoieMailCraContrat(formDataEmail);
      }
      formik.resetForm();
      setShowModal(false);
    },
  });
  /* Close Modal Mission */
  const handleClose = () => {
    setShowModal(false);
    formik.resetForm();
  };
  return (
    <>
      <Modal show={modalShow} onHide={handleClose} size="lg" centered>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              {isAdmin() ? "Envoyer document" : "Importer votre Contrat"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group>
                <Form.Control
                  multiple
                  className={ErrorClass(formik, "file")}
                  type="file"
                  name="file"
                  id="file"
                  placeholder="Entrez votre contract"
                  onChange={(event) => {
                    formik.setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  onBlur={formik.handleBlur}
                />
                {ErrorFeild(formik, "file")}
              </Form.Group>
            </Row>
            <Row className="text-detail ml-3">Types autorisés: pdf et image</Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button className="save-button green" type="submit">
              {isAdmin() ? "Envoyer" : "Importer"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
