import { useLocation, useHistory } from "react-router-dom";
import { getUser, isAdmin, isRecruiter, isCosmonaute } from "../../services/Controller/CurrentUserServices";
/* Route Protect */
function RouteView() {

    // check phone or tablet mode
    /* use Location */
    const location = useLocation();
    /* use history */
    const history = useHistory();
    /* get Current User */
    const user = getUser(); 
    if (
        location.pathname === "/" ||
        location.pathname === "/inscription" ||
        location.pathname === "/propos" ||
        location.pathname === "/contact" ||
        location.pathname === "/recrute" ||
        location.pathname === "/recruter" ||
        location.pathname === "/terms" ||
        location.pathname === "/notice" ||
        location.pathname.indexOf("forgetpassword") !== -1 ||
        location.pathname.indexOf("resetpassword") !== -1
    ) {
        return "homePage";
    } else if (location.pathname === "/login") {
        if (user && user.isActive) {
            if (isAdmin()) history.push("/admin/dashboard");
            else if (isRecruiter()) history.push("/entreprise/dashboard");
            else if (isCosmonaute()) history.push("/user/dashboard");
            else return "homePage";
        } else return "homePage";
    } else {
        if (user && user.isActive) {
            if (isAdmin() && location.pathname.indexOf("/admin/") !== -1) {
                if (location.pathname.indexOf("/admin/dashboard") !== -1)
                    return "with-content";
                else return "no-content";
            } else if (isRecruiter() && location.pathname.indexOf("/entreprise/") !== -1) {
                if (location.pathname.indexOf("/entreprise/missions") !== -1)
                    return "no-content";
                else return "with-content";
            } else if (isCosmonaute() && location.pathname.indexOf("/user/") !== -1) {
                return "with-content";
            } else history.push("/");
        } else history.push("/");
    }
}


export { RouteView }