import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";
import Header from "../../components/Header/Header";
import InscriptionForm from "../../components/Inscription/InscriptionForm";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
// import overlay from "../../assets/img/inscription/bg-overlay-section3.png";
import overlay1 from "../../assets/img/inscription/bg-overlay-section6.png";
import "./DevenirInspirnaute.scss";
import { Link } from "react-router-dom";
import imgOne from "../../assets/img/5.png";
import imgTwo from "../../assets/img/2.png";
import imgThree from "../../assets/img/6.png";
import imgFour from "../../assets/img/book2.png";
import imgFive from "../../assets/img/sansbg.png";



/* Formateur */
export default function DevenirInpirnaute() {
  const { t } = useTranslation();
  /* */
  const scrollToSection = () => {
    scroller.scrollTo("active", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  return (
    <div className="iscriptionPage">
      <ScrollToTopArrow />
      {/* Header section */}
      <section className="bg-inspirnaute-sec1 pb-4" id="inscription1">
        <Header />
        {/* <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="mt-15"
        > */}
          <p className="mt-15 slogan-first-talent yellow-color fontSize-140 text-uppercase text-center roboto-condensed-bold">
            {t("inscription.section1.talent1")}
          </p>
        {/* </ScrollAnimation> */}
        {/* <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="mt-5"
        > */}
          <p className="mt-5 slogan-talent text-uppercase text-center yellow-color">
            {t("inscription.section1.talent2")}
          </p>
        {/* </ScrollAnimation> */}
        <img src={imgOne} alt="bubble" className="imgOnee" />

        {/* <img src={imgTwo} alt="bubble" className="imgTwoo" /> */}
        <div className="it-Description fontSize-25 roboto-light text-center ">
          {t("inscription.section1.description")}
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          {t("inscription.section1.description1")}
        </div>
        {/* <ScrollAnimation
          animateIn="flipInY"
          animateOut="flipOutY"
          className="padding-button-inscrit"
        > */}
          <div className="padding-button-inscrit d-flex justify-content-center">
            <Button
              onClick={scrollToSection}
              variant="secondary"
              type="button"
              className="text-center roboto-bold p-button"
            >
              {t("inscription.section1.button")}
            </Button>
          </div>
        {/* </ScrollAnimation> */}
        {/* <ScrollAnimation animateIn="tada" > */}
          <div className="scroll-downs mt-5 ">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
        {/* </ScrollAnimation> */}
        <div className="overlay-sections1"></div>
      </section>

      <section className="bg-inspirnaute-sec2" id="inscription2">
        <div className="overlay-section2"></div>
        <div>
          <p className="text-center roboto-condensed-bold fontSize-35 yellow-color text-uppercase">
            {t("inscription.section2.title")}
          </p>
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          {t("inscription.section2.description1")}
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          {t("inscription.section2.description2")}
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          {t("inscription.section2.description3")}
        </div>
        {/* <div className="d-flex justify-content-center">
          <h1 className="section2-h1 roboto-black yellow-color fontSize-35">
            {t("inscription.section2.title1")}
          </h1>
        </div> */}
        {/* <div className="description2 fontSize-25 roboto-light">
          {t("inscription.section2.desc1")}

        </div>
        
        <div className="description2 fontSize-25 roboto-light">
          {t("inscription.section2.desc2")}
        </div> */}
        <img alt="overlay" src={imgThree} className="imgThree"></img>
        <img alt="overlay" src={imgFour} className="imgFour"></img>
      </section>

      {/* <section className="bg-inspirnaute-section2" id="inscription3">
        <div className="slogan-first-planetes text-transparent text-uppercase text-center roboto-black" >
          <div>{t("inscription.section3.title1")}</div>
          <div>{t("inscription.section3.title2")}</div>
        </div>
      </section> */}

      {/* <section className="bg-inspirnaute-sec3" id="inscription4">
        <img alt="overlay" src={overlay} className="overlay-section3"></img>
        <p className="mission-sesction3 mb-5">
          {t("inscription.section3.mission")}
        </p>
      </section> */}
      
      {/* <section className="bg-inspirnaute-section2" id="inscription5">
        <div className="overlay-section4"></div>
        <div className="overlay-section-bg4"></div>
        <div className="description-section5">
          <div>
            {t("inscription.section4.text")}
          </div>
          <div>
            {t("inscription.section4.text1")}
          </div>
          <div>
            {t("inscription.section4.text2")}
          </div>
        </div>
      </section> */}
      <section className="bg-inspirnaute-section2" id="inscription6">
        {/* <div className="overlay-section5 animate-3"></div> */}
        <div className="title-section5 text-uppercase roboto-condensed-bold yellow-color" >
          <div>{t("inscription.section5.title")}</div>
          <div>{t("inscription.section5.title1")}</div>
          <div>{t("inscription.section5.title2")}</div>
          <div>{t("inscription.section5.title3")}</div>
        </div>
        <div className=" m-left-10 it-Description fontSize-25 roboto-light">
          <div>{t("inscription.section5.description")}</div>
          <div>{t("inscription.section5.description1")}</div>
          <div>{t("inscription.section5.description2")}</div>
        </div>
      </section>
      <section className="bg-inspirnaute-sec6" id="inscription7">
        {/* <div className="overlay-section6"></div> */}
        <div className="description-section6 yellow-color">
          <div >
            {t("inscription.section6.title")}
          </div>
          <div >
            {t("inscription.section6.title1")}
          </div>
          <div >
            {t("inscription.section6.title2")}
          </div>
          <img alt="imgFive" src={imgFive} className="imgFive"></img>
          <img alt="" src={imgThree} className="imgsix"></img>

        </div>

        <div className="m-left-15 mt-30 it-Description fontSize-25 roboto-light ">
          {t("inscription.section6.text")}
        </div>
        <div className="m-left-15 it-Description fontSize-25 roboto-light">
          {t("inscription.section6.text1")}
        </div>
        <div className="m-left-15  it-Description fontSize-25 roboto-light">
          {t("inscription.section6.text2")}
        </div>
        {/* <div className="overlay-section-bg-6"></div> */}
        <div className="overlay-section-rocks"></div>
      </section>
      <section className="bg-inspirnaute-sec7" id="inscription8">
        
        <div className="roboto-condensed-bold title-partenariat yellow-color">
          {t("inscription.section7.title")}
        </div>
        <div className="it-Description fontSize-25 roboto-light text-center">
          {t("inscription.section7.h1")}
        </div>
        {/* <div className="overlay-section8"></div> */}
      </section>
      {/*  */}
      <section className="bg-inspirnaute-sec8 pb-5" id="inscription9">
        <div className="text-spacing-5 text-uppercase yellow-color text-center roboto-condensed-bold fontSize-140 margin-title">
          <div>{t("inscription.section8.titre")}</div>
          <div>{t("inscription.section8.titre1")}</div>
        </div>
        <div id="form"> <InscriptionForm className="pb-5" /></div>
       
      </section>
      {/* contact Section */}
      <section id="section10">
          <Row className="line-height-row">
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
              </ScrollAnimation> */}
              <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold ml-big text-uppercase fontSize-100 yellow-color text-spacing-3 infini mt-80 pt-40">
                  {t("home.infini")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                  {t("home.contact")}
                </p>
              </ScrollAnimation>
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.adress")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.phone")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.mail")}
                </p>
                <p className="roboto-light text-center fontSize-25 notice text-underline">
                  <Link to="/notice">{t("home.mention")}</Link>
                </p>
              </div>
            </Col>
          </Row>
        </section>
<Footer />
    </div>
  );
}
function Footer() {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
          <h4 className="footer-title">Acropolia Talents</h4>
          <p>Acropolia place l’humain au <br />coeur de ses préoccupations</p>
          <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a href="https://www.instagram.com/acropolia_academy/" target="_blank" rel="noopener noreferrer" className="social-icon instagram">IG</a>
              <a href="https://www.facebook.com/profile.php?id=61551011631802" target="_blank" rel="noopener noreferrer" className="social-icon facebook">FB</a>
              {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon linkedin">LN</a> */}
              <a href="https://www.youtube.com/@acropolia_academy" target="_blank" rel="noopener noreferrer" className="social-icon youtube">YT</a>
              {/* <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="social-icon x">X</a> */}
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
              {/* <li><Link to="/about-dino">L'histoire de Dino</Link></li> */}
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
              {/* <li><Link to="/terms">Conditions générales de services</Link></li> */}
              {/* <li><Link to="/privacy">Politique de confidentialité</Link></li> */}
              {/* <li><Link to="/cookies">Charte en matières de cookies</Link></li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}