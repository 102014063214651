import React, { useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import { Context as CosmonauteContext } from "../../../../../context/reducers/CosmonauteReducer";
import { ErrorFeild, ErrorClass } from '../../../../../services/Controller/ValidationForm';
import "./LangueCosmo.scss";
import { Rating } from "@mui/material";

export default function LanguesCandidate({ cosmonauteLangues, allLangues , id }) {
    /* Context API */
    const { saveLanguesCandidte } = useContext(CosmonauteContext);
    /* All options for language */
    const LanguesOptions = allLangues ? allLangues.map((el) =>{ return {value: el.id , label: el.langue}}):[]
    /* Form Add langues */
    const LanguesForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            langues: cosmonauteLangues
                ? cosmonauteLangues.map((el) => {
                    return { value: el.id, label: el.name ,rating:el.rating}
                })
                : [],
        },
        onSubmit: (values) => {
            const data =  {langues : values.langues.map((el) =>{return {id:el.value,rating:el.rating}})};
            saveLanguesCandidte(data, id);
        },
    });
    /* Experience Add Form */
    function FormLangues() {
        return (
            <>
                {/* Add Experience */}
                <Form className="mb-5" onSubmit={LanguesForm.handleSubmit}>
                    <Button variant="primary" type="submit"
                        className="shadow-unset float-right save-button border-dark">
                        Sauvegarder
                    </Button>
                    <label className="text-header-cosmonaute mb-4">Ajouter une Langue</label>
                    <Form.Group className="mb-2 formGroup">
                        <Form.Label className="text-black">Langues</Form.Label>
                        <Select isMulti isSearchable placeholder=""
                            className={ErrorClass(LanguesForm, 'langues')}
                            name="langues" id="langues"
                            options={LanguesOptions}
                            closeMenuOnSelect={true}
                            value={LanguesForm.values.langues}
                            onChange={(event) => {
                             LanguesForm.setFieldValue("langues", event) }}
                            onBlur={LanguesForm.handleBlur}
                        />
                        {ErrorFeild(LanguesForm, 'langues')}
                    </Form.Group>
                    <FlexboxPage langue={LanguesForm.values.langues}></FlexboxPage>
                </Form>
            </>
        )
    }
    function FlexboxPage({ langue, index }) {
        return (
            <Row className="mt-3 mb-3">
                {langue.map((el, index) => {
                    return (
                        <Col className="mb-2" lg={6} md={6} xs={12} key={"langues" + index}>
                            <div className="d-flex dropdown-skills p-1 mb-2">
                                <div className="flex-grow-1 text-skills align-self-center">
                                    {el.label}
                                </div>
                                <div className="align-self-center">
                                    <Rating
                                        name={`langues[${index}].rating`}
                                        value={el.rating}
                                        defaultValue={0}
                                        precision={0.5}
                                        className="rating-style"
                                        onChange={(event,value) => {
                                            LanguesForm.setFieldValue(`langues[${index}].rating`, value);
                                        }}
                                    />
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        );
    }
    return (
        <>
            <div className="border-top mt-5 mb-5"></div>
            <div className="title-profile-cosmonaute mb-5">Langues</div>
            {FormLangues()}
        </>
    );
}
