import {
    API_HOST,
    API_ALL_MISSION_ADMINISTRATOR,
    API_ONE_MISSION_ADMINISTRATOR,
    API_DELETE_MISSION_ADMINISTRATOR,
    API_INTERRUPT_MISSION_ADMINISTRATOR,
    API_MATCHING_PROFILES_ADMINISTRATOR,
    API_AFFECT_PROFILE_MESSION_ADMINISTRATOR,
    API_REMOVE_PROFILE_MESSION_ADMINISTRATOR
} from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import {
    ALL_MISSIONS_ADMIN, ONE_MISSION_ADMIN,
    DELETE_MISSION_ADMIN, INTERRUPT_MISSION_ADMIN, DUMP_MISSION_ADMIN,
    PROFILES_MISSION_ADMIN, MATCH_PROFIL_MISSION_ADMIN, REMOVE_PROFIL_MISSION_ADMIN, DUMP_PROFIL_MISSION_ADMIN
} from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list Missions for Admin */
const getAllMissionsAdmin = (dispatch) => () => {
    // URL get list Missions for Admin
    const URL = API_HOST + API_ALL_MISSION_ADMINISTRATOR;
    // Call API list Missions for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ALL_MISSIONS_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste Missions" }
            });
        }
    );
}

/* Get one Mission for Admin */
const getOneMission = (dispatch) => (id) => {
    // URL get one Mission for Admin
    const URL = API_HOST + API_ONE_MISSION_ADMINISTRATOR + id;
    // Call API one Mission for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ONE_MISSION_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la Mission choisie" }
            });
        }
    );
}

/* Delete Mission */
const deleteMission = (dispatch) => (id) => {
    // Url DELETE Mission by id
    const url = API_HOST + API_DELETE_MISSION_ADMINISTRATOR + id;
    // Call Api delete Mission
    axiosServices.delete(url).then(
        (response) => {
            dispatch({
                type: DELETE_MISSION_ADMIN,
                payload: response.data,
                toastContent: { status: 'success', body: "Mission est ajoutée avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de suppression mission" }
            });
        });
}

/* Interrupt Mission */
const interruptMission = (dispatch) => (id) => {
    // Url Interrupt Mission by id
    const url = API_HOST + API_INTERRUPT_MISSION_ADMINISTRATOR + id;
    // Call Api Interrupt Mission
    axiosServices.post(url).then(
        (response) => {
            dispatch({
                type: INTERRUPT_MISSION_ADMIN,
                payload: response.data,
                toastContent: { status: 'success', body: "Mission est interrompue avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'interromption mission" }
            });
        });
}

/* Profiles Matched */
const getProfileMatchingList = (dispatch) => (id) => {
    // Url PROFILES MATCHING by id
    const url = API_HOST + API_MATCHING_PROFILES_ADMINISTRATOR + id;
    // Call Api profiles by skills
    axiosServices.get(url).then(
        (response) => {
            dispatch({
                type: PROFILES_MISSION_ADMIN,
                payload: response.data,
                toastContent: null
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'interromption mission" }
            });
        });
}

/* Match Profil */
const affectProfileToMission = (dispatch) => (data) => {
    // Url match profile to Mission
    const url = API_HOST + API_AFFECT_PROFILE_MESSION_ADMINISTRATOR;
    // Call Api matching profil to mission
    axiosServices.post(url, data).then(
        (response) => {
            dispatch({
                type: MATCH_PROFIL_MISSION_ADMIN,
                payload: response.data,
                toastContent: { status: 'success', body: "Profile est affecté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'affectation profile pour cette mission" }
            });
        });
}

/* Remove Profil */
const removeProfileFromMission = (dispatch) => (data) => {
    // Url Remove profile from Mission
    const url = API_HOST + API_REMOVE_PROFILE_MESSION_ADMINISTRATOR;
    // Call Api Removing profil from mission
    axiosServices.post(url, data).then(
        (response) => {
            dispatch({
                type: REMOVE_PROFIL_MISSION_ADMIN,
                payload: response.data,
                toastContent: { status: 'success', body: "Profile est retiré avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de retrait profile de cette mission" }
            });
        });
}

/* Dump Mission */
const dumpMission = (dispatch) => () => {
    dispatch({ type: DUMP_MISSION_ADMIN, payload: null, toastContent: null });
}

/* Dump Profile */
const dumpProfile = (dispatch) => () => {
    dispatch({ type: DUMP_PROFIL_MISSION_ADMIN, payload: null, toastContent: null });
}

/* Export Action Missions Administrator */
export {
    getAllMissionsAdmin, getOneMission, deleteMission, interruptMission, getProfileMatchingList, dumpMission,
    affectProfileToMission, removeProfileFromMission, dumpProfile
};
