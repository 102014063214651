import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./cookies.scss";
// import component 👇
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
// import cookies
import { useCookies } from 'react-cookie';

export default function CookiesPage() {
    const [isOpen, setIsOpen] = useState(false);
    const [cookies, setCookie] = useCookies();
    /* Accept cookies */
    const validateCookies = () => {
        setCookie('showCookies', true);
        setIsOpen(false);
    }
    /* show Accept cookies */
    useEffect(() => {
        if (!cookies || !cookies["showCookies"]) {
            setIsOpen(true);
        }
    }, [cookies]);
    return (
        <>
            <Drawer open={isOpen} direction='bottom' zIndex="90000" className="d-flex justify-content-center">
                <div className="d-flex align-items-center justify-space-between w-content-cookies">
                    <div className="cookies-content">
                        <h5 className="color-title mb-3">Ce site web utilise des cookies</h5>
                        Nous utilisons des cookies pour améliorer l'expérience utilisateur.
                        Choisissez les cookies que vous nous autorisez à utiliser.
                        <br />
                        Vous pouvez en savoir plus sur notre politique en matière de cookies dans
                        <Link to="/notice"> notre réglement général.</Link>
                    </div>
                    <div className="float-end">
                        <Button variant="magenta" size="lg" className="btn-refuse-cookies text-underline mx-2" onClick={validateCookies}>
                            Non, merci
                        </Button>
                        <Button variant="secondary" size="lg" className="btn-cookies" onClick={validateCookies}>
                            Accepter
                        </Button>
                    </div>
                </div>
            </Drawer>
        </>
    )
}