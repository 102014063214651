import {FORGET_PASSWORD, RESET_PASSWORD} from "../constants/Password";
import axios from "axios";
import {API_FORGET_PASSWORD, API_HOST,API_RESET_PASSWORD} from "../../Api";
import {SHOW_TOAST} from "../constants/Toast";

export const forgetPassword = (dispatch) => (data) => {
    const URL = API_HOST + API_FORGET_PASSWORD;
    axios.post(URL, data).then(
        (res) => {
            dispatch({
                type: FORGET_PASSWORD,
                payload: res.data,
                toastContent: { status: "success", body: "un e-mail a été envoyé à l'adresse e-mail fournie." },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: "error", body: "Echec de trouver votre compte" },
            });
        }
    );
};

export const resetPassword = (dispatch) => (data,token) => {
    const URL = API_HOST + API_RESET_PASSWORD+token;
    axios.post(URL, data).then(
        (res) => {
            dispatch({
                type: RESET_PASSWORD,
                payload: res.data,
                toastContent: { status: "success", body: "Mot de passe a été modifié avec succès." },
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: "error", body: "Echec de modification" },
            });
        }
    );
};