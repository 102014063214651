import { API_HOST, API_ALL_INSPIRNAUTES_ADMINISTRATOR, API_ONE_INSPIRNAUTE_ADMINISTRATOR} from "../../Api";
import { SHOW_TOAST } from '../constants/Toast';
import { ALL_INSPIRNAUTES_ADMIN, ONE_INSPIRNAUTE_ADMIN } from '../constants/Administrator';
import axiosServices from "../../services/Axios/axiosServices";

/* Get list Inspirnautes for Admin */
const getAllInspirnautes = (dispatch) => () => {
    // URL get list Inspirnautes for Admin
    const URL = API_HOST + API_ALL_INSPIRNAUTES_ADMINISTRATOR;
    // Call API list Inspirnautes for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ 
                type: ALL_INSPIRNAUTES_ADMIN, 
                payload: res.data, 
                toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération de la liste inspirnautes" }
            });
        }
    );
}

/* Get detail Formateur for Admin */
const getOneInspirnaute = (dispatch) => (id) => {
    // URL get detail Formateur for Admin
    const URL = API_HOST + API_ONE_INSPIRNAUTE_ADMINISTRATOR + id;
    // Call API detail Formateur for Admin
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ONE_INSPIRNAUTE_ADMIN, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de recupérer détail inspirnaute" }
            });
        }
    );
}


/* Export Action Inspirnautes Profils Administrator */
export { getAllInspirnautes, getOneInspirnaute };