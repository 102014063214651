import { Button, Modal, Form, Row } from 'react-bootstrap';
import { useRef } from 'react';
import './ModalUploadLogo.scss';

export default function ModalUploadLogo({ modalShow, setModalShow, logoUpload }) {
    /* Réf */
    const imageRef = useRef();

    /* select File */
    function onFileSelected(image) {
        imageRef.current = image;
    }

    /* upload Logo */
    const upload = () => {
        if (imageRef.current) {
            logoUpload(imageRef.current);
            setModalShow(false);
        }
    }

    return (
        <>
            <Modal show={modalShow} onHide={() => { setModalShow(false) }} size="lg" centered >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"> Changer image de profil </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group>
                            <Form.Control type="file" placeholder="Entrez votre logo"
                                onChange={(e) => { onFileSelected(e.target.files[0]) }} />
                        </Form.Group>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button className="save-button green" onClick={upload}>Importer</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

