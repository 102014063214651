import * as Yup from "yup";
import {useFormik} from "formik";
import {Button, Form, Modal} from "react-bootstrap";
import {ErrorClass, ErrorFeild} from "../../services/Controller/ValidationForm";

const validationdeclineForm = Yup.object({
    object: Yup.string().required('objet obligatoire '),
    subject: Yup.string().required('sujet obligatoire '),
});

export const  ContactAdmin =({modalContact,setModalContact,contactAdmin,mission})=> {
    /* Form Group Decline Interview */
    const contactForm = useFormik({
        initialValues: {
            object: "",
            subject:""
        },
        validationSchema: validationdeclineForm,
        onSubmit: values => {
            contactAdmin(values,mission)
            contactForm.resetForm()
            setModalContact(false)
        }
    })
    /* Form Cosmonaute */
    function FormContact() {
        return (
            <Form onSubmit={contactForm.handleSubmit}>
                <Modal.Body >
                    {/* Card of Interview information */}
                    <Form.Group className="mt-1 mb-2">
                        <Form.Label className="text-Label-modal required">Objet</Form.Label>
                        <Form.Control className={ErrorClass(contactForm, 'object')} as="input"
                                      name="object"
                                      id="object"
                                      placeholder="Saisir l'objet ..."
                                      onChange={contactForm.handleChange}
                                      onBlur={contactForm.handleBlur}
                                      value={contactForm.values.reason} />
                        {ErrorFeild(contactForm, 'object')}
                    </Form.Group>
                    {/* Form Decline */}
                    <Form.Group className="mt-3 mb-2">
                        <Form.Label className="text-Label-modal required">Sujet</Form.Label>
                        <Form.Control className={ErrorClass(contactForm, 'subject')} as="textarea" rows={10}
                                      name="subject"
                                      id="subject"
                                      placeholder="Saisir votre sujet ..."
                                      onChange={contactForm.handleChange}
                                      onBlur={contactForm.handleBlur}
                                      value={contactForm.values.reason} />
                        {ErrorFeild(contactForm, 'subject')}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <ConfirmForm />
                </Modal.Footer>
            </Form>
        )
    }

    /* Footer Decline */
    function ConfirmForm() {
        return (
            <>

                    <>
                        <Button className="save-button blue shadow-unset mr-64" type="submit">
                            Envoyer
                        </Button>
                        <Button className="save-button red shadow-unset" type="button"
                                onClick={() => {  setModalContact(false); contactForm.resetForm(); }}
                            >
                            Annuler
                        </Button>
                    </>
            </>
        )
    }

    return(
        <>
            <Modal show={modalContact} size="xl" centered
                   onHide={() => {
                       setModalContact(false);
                   }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="modal-title"> Contacter Administrateur</Modal.Title>
                </Modal.Header>
                {/* Modal Content */}
                {FormContact()}
            </Modal>
        </>

    )
}