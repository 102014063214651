import "./EducationCosmo.scss";
import "react-datepicker/dist/react-datepicker.css";
import React, { useContext, useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FaTrashAlt } from 'react-icons/fa';
import DatePicker from "react-datepicker";
import { useFormik, FieldArray, Formik } from "formik";
import * as Yup from "yup";
import { Context as CosmonauteContext } from "../../../../../context/reducers/CosmonauteReducer";
import { ErrorFeild, ErrorClass, ErrorArrayFeild, ErrorArrayClass } from '../../../../../services/Controller/ValidationForm';
import { formatDate } from "../../../../../services/Controller/DateFormatter";

/* validation form add education */
const validationCosmonauteEducation = Yup.object({
    school: Yup.string().required("Établissement est obligatoire"),
    degree: Yup.string().required("Diplôme est obligatoire"),
    toDateEdu: Yup.date().required("Date fin est obligatoire"),
    fromDateEdu: Yup.date()
        .when("toDateEdu", (toDateEdu, schema) => toDateEdu && schema.max(toDateEdu, "La date de fin ne peut pas être antérieure à la date de début")),
});

/* validation Form edit experience */
const validationCosmonauteEditExperience = Yup.object({
    educations: Yup.array().of(
        Yup.object({
            school: Yup.string().required("Établissement est obligatoire"),
            degree: Yup.string().required("Diplôme est obligatoire"),
            toDateEdu: Yup.date().required("Date fin est obligatoire"),
            fromDateEdu: Yup.date()
                .when("toDateEdu", (toDateEdu, schema) => toDateEdu && schema.max(toDateEdu, "La date de fin ne peut pas être antérieure à la date de début")),
        })
    ),
});

export default function EducationCandidate({ cosmonauteEducation, id }) {
    /* Context API */
    const { saveEducation, deleteEducation } = useContext(CosmonauteContext);

    /* States */
    const [showEducation, setshowEducation] = useState(null);

    /* arrary form education */
    const educationArray = cosmonauteEducation && cosmonauteEducation.length
        ? cosmonauteEducation.map((educat) => ({
            ...{
                id: educat.id,
                school: educat.school,
                degree: educat.degree,
                fromDateEdu: educat.fromDateEdu ? formatDate(educat.fromDateEdu) : null,
                toDateEdu: educat.toDateEdu ? formatDate(educat.toDateEdu) : null,
                descriptionEdu: educat.descriptionEdu,
            },
        }))
        : null

    /* edit education formik */
    const EditEducationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            educations: educationArray,
        },
        validationSchema: validationCosmonauteEditExperience,
        onSubmit: (values) => {
            saveEducation(values.educations, id);
            setshowEducation(null);
        },
    });

    /* add education formik */
    const EducationForm = useFormik({
        initialValues: {
            id: null,
            school: "",
            degree: "",
            fromDateEdu: "",
            toDateEdu: "",
            descriptionEdu: "",
        },
        validationSchema: validationCosmonauteEducation,
        onSubmit: (values) => {
            let data = EditEducationForm.values.educations;
            if (data && data.length) data.push(values);
            else data = [values];
            saveEducation(data, id);
            setshowEducation(null);
            EducationForm.resetForm();
        },
    });

    /* Add Education Form */
    function addEducationForm() {
        return (
            <>
                <Form onSubmit={EducationForm.handleSubmit}>
                    <Row className="mb-4">
                        <Col lg={8} md={8} sm={8} >
                            <label className="text-header-cosmonaute">
                                Ajouter une nouvelle éducation ou formation
                            </label>
                        </Col>
                        <Col lg={4} md={4} sm={4}>
                            <Button type="submit" className="shadow-unset float-right save-button border-dark">
                                Sauvegarder
                            </Button>
                        </Col>
                    </Row>
                    <Form.Group className="mb-4">
                        <Form.Label>Établissement </Form.Label>
                        <Form.Control
                            type="text" name="school" id="school"
                            className={ErrorClass(EducationForm, "school")}
                            onChange={EducationForm.handleChange}
                            value={EducationForm.values.school}
                            onBlur={EducationForm.handleBlur}
                            placeholder="Entrez le nom de votre école"
                        />
                        {ErrorFeild(EducationForm, "school")}
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Diplôme</Form.Label>
                        <Form.Control
                            type="text"
                            name="degree"
                            id="degree"
                            className={ErrorClass(EducationForm, "degree")}
                            onChange={EducationForm.handleChange}
                            value={EducationForm.values.degree}
                            onBlur={EducationForm.handleBlur}
                            placeholder="Entrez votre diplôme"
                        />
                        {ErrorFeild(EducationForm, "degree")}
                    </Form.Group>

                    <label className="period-edit">Période :</label>

                    <Form.Group className="mb-4">
                        <Form.Label className="text-muted"> À partir de :</Form.Label>
                        <DatePicker
                            id="fromDateEdu" name="fromDateEdu"
                            className={ErrorClass(EducationForm, "fromDateEdu")}
                            selected={EducationForm.values.fromDateEdu ? new Date(EducationForm.values.fromDateEdu) : ""}
                            onChange={(date) => EducationForm.setFieldValue("fromDateEdu", date ? date : "")}
                            onBlur={EducationForm.handleBlur}
                            dateFormat="yyyy" showYearPicker
                        />
                        {ErrorFeild(EducationForm, "fromDateEdu")}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className="text-muted"> Jusqu'à </Form.Label>
                        <DatePicker
                            id="toDateEdu" name="toDateEdu"
                            className={ErrorClass(EducationForm, "toDateEdu")}
                            selected={EducationForm.values.toDateEdu ? new Date(EducationForm.values.toDateEdu) : ""}
                            onChange={(date) => EducationForm.setFieldValue("toDateEdu", date ? date : "")}
                            onBlur={EducationForm.handleBlur}
                            dateFormat="yyyy" showYearPicker
                        />
                        {ErrorFeild(EducationForm, "toDateEdu")}
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea" rows={3} name="descriptionEdu" id="descriptionEdu"
                            placeholder="Écrivez sur votre diplôme"
                            className={ErrorClass(EducationForm, "descriptionEdu")}
                            onChange={EducationForm.handleChange}
                            value={EducationForm.values.descriptionEdu}
                            onBlur={EducationForm.handleBlur}
                        />
                        {ErrorFeild(EducationForm, "descriptionEdu")}
                    </Form.Group>
                </Form>
            </>
        )
    }

    /* Edit Education Form */
    function editEducationForm() {
        return (
            <Formik onSubmit={EditEducationForm.handleSubmit}>
                <Form>
                    <FieldArray
                        name="educations"
                        render={(arrayHelpers) =>
                            EditEducationForm.values.educations &&
                            EditEducationForm.values.educations.length &&
                            EditEducationForm.values.educations.map((education, index) =>
                                arrayFormEducation(education, index)
                            )
                        }
                    />
                </Form>
            </Formik>
        )
    }

    /* Array form Education*/
    const arrayFormEducation = (form, index) => {
        return (
            <div className="mb-2" key={"Education" + index}>
                <div className="border-top mt-3 mb-3"></div>
                <label className="text-roboto-bold blue">
                    {
                        educationArray && educationArray[index] && educationArray[index].degree
                            ? educationArray[index].degree
                            : "- - -"
                    }
                </label>
                <label className="text-regular blue text-underline float-right pointer"
                    onClick={() => setshowEducation(showEducation === index ? null : index)}>
                    {showEducation === index ? "voir moins" : "voir plus"}
                </label>
                {
                    showEducation === index
                        ?
                        <>
                            <Form.Group className="mb-4">
                                <Form.Label>Établissement</Form.Label>
                                <Form.Control
                                    className={ErrorArrayClass(EditEducationForm, "educations", index, "school")}
                                    type="text" name={`educations[${index}].school`} id={`educations[${index}].school`}
                                    onChange={(e) => { setValueFormEducation(e.target.value, index, 'school') }}
                                    value={form.school}
                                    onBlur={EditEducationForm.handleBlur}
                                />
                                {ErrorArrayFeild(EditEducationForm, "educations", index, "school")}
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label>Diplôme</Form.Label>
                                <Form.Control
                                    className={ErrorArrayClass(EditEducationForm, "educations", index, "degree")}
                                    type="text" name={`educations[${index}].degree`} id={`educations[${index}].degree`}
                                    onChange={EditEducationForm.handleChange}
                                    value={form.degree}
                                    onBlur={EditEducationForm.handleBlur}
                                />
                                {ErrorArrayFeild(EditEducationForm, "educations", index, "degree")}
                            </Form.Group>

                            <label className="period-edit">Période :</label>

                            <Form.Group className="mb-4">
                                <Form.Label className="text-muted"> À partir de : </Form.Label>
                                <DatePicker
                                    id={`educations[${index}].fromDateEdu`} name={`educations[${index}].fromDateEdu`}
                                    className={ErrorArrayClass(EditEducationForm, "educations", index, "fromDateEdu")}
                                    selected={form.fromDateEdu ? new Date(form.fromDateEdu) : ""}
                                    onChange={(date) => setValueFormEducation(date ? new Date(date) : "", index, 'fromDateEdu')}
                                    dateFormat={"yyyy"} showYearPicker
                                />
                                {ErrorArrayFeild(EditEducationForm, "educations", index, "fromDateEdu")}
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label className="text-muted"> Jusqu'à : </Form.Label>
                                <DatePicker
                                    id={`educations[${index}].toDateEdu`} name={`educations[${index}].toDateEdu`}
                                    className={ErrorArrayClass(EditEducationForm, "educations", index, "toDateEdu")}
                                    selected={form.toDateEdu ? new Date(form.toDateEdu) : ""}
                                    onChange={(date) => setValueFormEducation(date ? new Date(date) : "", index, 'toDateEdu')}
                                    dateFormat={"yyyy"} showYearPicker
                                />
                                {ErrorArrayFeild(EditEducationForm, "educations", index, "toDateEdu")}
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    className={ErrorArrayClass(EditEducationForm, "educations", index, "descriptionEdu")}
                                    as="textarea" rows={3} placeholder="Écrivez sur votre diplôme"
                                    name={`educations[${index}].descriptionEdu`} id={`educations[${index}].descriptionEdu`}
                                    onChange={EditEducationForm.handleChange}
                                    onBlur={EditEducationForm.handleBlur} 
                                    value={form.descriptionEdu}
                                />
                                {ErrorArrayFeild(EditEducationForm, "educations", index, "descriptionEdu")}
                            </Form.Group>

                            <Button variant="primary" type="submit" onClick={EditEducationForm.handleSubmit}
                                className="shadow-unset float-right mb-5 save-button border-dark">
                                Sauvegarder
                            </Button>
                            <span className="btn-action red pointer" onClick={() => deleteEducation(form.id)}>
                                < FaTrashAlt className="mr-5 mb-1" /> Supprimer
                            </span>
                        </>
                        : null
                }


            </div>
        );
    };

    /* Set Value Feild Experience Edit */
    function setValueFormEducation(e, index, control) {
        EditEducationForm.setFieldValue(`educations[${index}].${control}`, e);
    }

    return (
        <>
            <div className="title-profile-cosmonaute mb-5">EDUCATION</div>
            {addEducationForm()}
            {editEducationForm()}
        </>
    );
}
