import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Card,
  Tabs,
  Tab,
  Form,
  Dropdown,
  Overlay,
  Tooltip,
} from "react-bootstrap";
import { FaRegCalendarMinus, FaMapMarkerAlt, FaLink } from "react-icons/fa";
import { Context as RecruiterContext } from "../../../context/reducers/RecruiterReducer";
import ToastService from "../../../components/Toast/ToastService";
import ModalInterview from "../../../components/Interview/ModalInterview";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import InterviewsFilter from "../../../components/InterviewsFilter/InterviewsFilter";
import Paginate from "../../../components/Paginate/Paginate";
import Calendar from "../../../components/Calendar/Calendar";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import AvatarGroup from "../../../components/avatar/avatarGroup";
import {
  formatDate,
  formatDy,
  formatTime,
} from "../../../services/Controller/DateFormatter";
import { getStatus } from "../../../services/Controller/MissionServices";
import banner from "../../../assets/img/banner-interviews.png";
import "./InterviewRecruiter.scss";
import { useRef } from "react";
import { ContactAdmin } from "../../../components/ModalContact/ContactAdmin";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { timeOutCalendar } from "../../../services/Controller/CalendarService"
import { format } from "date-fns";
import MissionRecruiter from "../Mission/MissionRecruiter";

/* interview Recruiter Content */
function InterviewRecruiter() {
  /* Context API */
  const { t } = useTranslation();
  const history = useHistory();
  const {
    getAllInterviews,
    hideToast,
    state,
    rejectCandidate,
    recruteCandidate,
    proposeInterviewRecruiter,
    changeInterviewRecruiter,
    declineInterviewRecruiter,
    dumpInterview,
    contactAdmin,
  } = useContext(RecruiterContext);
  /* States */
  /* Interview Modal */
  const [modalDateInterviewShow, setModalDateInterviewShow] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  /* Confirm Modal show|hide */
  const [show, setShow] = useState(false);
  /* Modal Title */
  const [titleModal, setTitleModal] = useState(null);
  /* List interview */
  const [allInterviews, setAllInterview] = useState({
    All: [],
    upcomming: [],
    declined: [],
  });
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  /* Default Tab Value*/
  const [defaultTabValue, setDefaultTabValue] = useState("All");
  /* Filter Interview */
  const [resetForm, setResetForm] = useState(false);
  /* Mission Detail */
  const [oneMission, setOneMission] = useState(null);
  /* select Item */
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalContact, setModalContact] = useState(false);

  /*eslint-disable */
  useEffect(() => {
    getAllInterviews();
  }, []);
  /*eslint-enable */
  useEffect(() => {
    if (state.allInterviews) {
      let list = state.allInterviews;
      setAllInterview({
        All: list,
        proposed: list.filter((el) => el.status === "pending"),
        upcomming: list.filter((el) => el.status === "accepted"),
        passed: list.filter((el) => el.status === "passed"),
        declined: list.filter((el) => el.status === "rejected"),
        changed: list.filter((el) => el.status === "change_date"),
      });
      setDisplayedData(list);
    }
  }, [state]);
  /* refresh after change | Reject Interview */
  useEffect(() => {
    if (state.interview) {
      getAllInterviews();
      dumpInterview();
    }
  }, [dumpInterview, getAllInterviews, state.interview]);

  function openModalContact(mission) {
    setSelectedItem(mission);
    setModalContact(true);
  }
  useEffect(() => {
    if (history.location.state === "interviews") {
      setResetForm(true)
      setDisplayedData(allInterviews["upcomming"])
      setDefaultTabValue("upcomming")
    };
  }, [setResetForm, setDisplayedData, setDefaultTabValue, history.location.state, allInterviews])

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Détail Mission */
  function redirectToMission(id) {
    history.push(t("role.recruiter") + t("sidebar.missions.path") + "/" + id);
  }
  /* Interview Header */
  function ShowInterviewHeader() {
    return (
      <Row className="justify-content-center">
        <Col className="mt-5" lg={6} md={12} xs={12}>
          <div className="text-professionl mb-4">Formation :</div>

          <div className="interviews-paragraphe">
            <p className="paragraphe-style">
            Parcourez les profils de nos formateurs ainsi que le détail des formations, et si vous ne trouvez pas votre bonheur, postez nous une nouvelle mission !
              <br />
            </p>
          </div>
        </Col>
        <Col lg={1} md={12} xs={12}></Col>
        <Col lg={5} md={12} xs={12}>
          {/* <Image src={banner} className="w-100"></Image> */}
        </Col>
      </Row>
    );
  }

  /* Interview Content */
  function showInterviewContent() {
    return (
      <>
        {currentItems.map((el, index) => {
          return (
            <Card className="card-content mb-3" key={"interview" + index}>
              <Card.Body>
                <div className="d-flex justify-space-between align-items-center mb-3">
                  <div className="">
                    {el.lastname && el.name ? (
                      <AvatarGroup
                        className="float-left"
                        size="sm"
                        profils={[el]}
                      />
                    ) : null}
                    <label
                      className="text-header ml-20 mr-20 link"
                      onClick={() => {
                        redirectToMission(el.missionId);
                      }}
                    >
                      {el ? el.title : "- - -"}
                    </label>
                    {el.status ? (
                      <label
                        className={"text-tag " + getStatus(el.status)?.class}
                      >
                        {getStatus(el.status)?.label}
                      </label>
                    ) : null}
                  </div>
                  <Dropdown className="float-right" align="end">
                    <Dropdown.Toggle id="dropdown-basic">
                      <i className="icon-see-more"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {el.status === "rejected" ? (
                        <Dropdown.Item
                          className="text-calendar"
                          as="button"
                          onClick={() =>
                            openModalInterview(
                              el,
                              "Proposer Entretien",
                              "addInterview"
                            )
                          }
                        >
                          Proposer entretien
                        </Dropdown.Item>
                      ) : el.status === "pending" ||
                        el.status === "change_date" ? (
                        <Dropdown.Item
                          className="text-calendar"
                          as="button"
                          onClick={() =>
                            openModalInterview(
                              el,
                              "Changer Date Entretien",
                              "changeDate"
                            )
                          }
                        >
                          Changer date entretien
                        </Dropdown.Item>
                      ) : el.status === "accepted" ? (
                        <Dropdown.Item
                          className="text-calendar"
                          as="button"
                          onClick={() =>
                            openModalInterview(
                              el,
                              "Êtes-vous sûr de vouloir refuser l'entretien ?",
                              "decline"
                            )
                          }
                        >
                          Rejeter entretien
                        </Dropdown.Item>
                      ) : el.status === "passed" ? (
                        <>
                          <Dropdown.Item
                            className="text-calendar"
                            as="button"
                            onClick={() => selectInterview(el, "recruter")}
                          >
                            Recruter candidat
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="text-calendar"
                            as="button"
                            onClick={() => selectInterview(el, "rejeter")}
                          >
                            Rejeter candidat
                          </Dropdown.Item>
                        </>
                      ) : null}
                      <Dropdown.Item
                        className="text-calendar"
                        as="button"
                        onClick={() => openModalContact(el)}
                      >
                        Contacter administrateur
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <p className="company">{el.username}</p>
                <div className="text-detail d-flex mb-2">
                  <FaRegCalendarMinus />
                  <span className="ml-10 mr-20">
                    {el.status === "rejected" ? (
                      "Entretien annulé"
                    ) : el.selectedDate ? (
                      format(new Date(el.selectedDate), "dd/MM/yyyy") +
                      " à " +
                      format(new Date(el.selectedDate), "dd/MM/yyyy")
                    ) : el.firstDate && el.secondDate ? (
                      <>
                        <div className="date-interview">
                          Le{" "}
                          {format(new Date(el.firstDate), "dd/MM/yyyy") +
                            " à " +
                            formatTime(el.firstDate) +
                            " "}
                          ou bien Le{" "}
                          {format(new Date(el.secondDate), "dd/MM/yyyy") +
                            " à " +
                            formatTime(el.secondDate)}
                        </div>
                      </>
                    ) : (
                      "- - -"
                    )}
                  </span>
                </div>
                <div className="text-detail d-flex mb-2">
                  <FaMapMarkerAlt />
                  <span className="ml-10 mr-20">
                    {" "}
                    {el.place ? el.place : "- - -"}{" "}
                  </span>
                </div>
                <div className="text-detail d-flex mb-3">
                  <FaLink />
                  {
                    (el.selectedDate === el.firstDate) && el.link
                      ? <span className="ml-10 mr-20 text-underline pointer" onClick={() => { openLink(el.link); }}>
                        {el.link}
                      </span>
                      :
                      (el.selectedDate === el.secondDate) && el.linkTwo
                        ? <span className="ml-10 mr-20 text-underline pointer" onClick={() => { openLink(el.linkTwo); }}>
                          {el.linkTwo}
                        </span>
                        : <span className="ml-10 mr-20"> - - -</span>
                  }
                </div>
              </Card.Body>
            </Card>
          );
        })}
        <Paginate
          items={displayedData}
          setCurrentItems={setCurrentItems}
          itemsPerPage={10}
        />
      </>
    );
  }

  /* Open Link Interview */
  function openLink(link) {
    window.open(link);
  }

  /* recrute | reject */
  function selectInterview(el, type) {
    if (el.missionProfilId) {
      setSelectedItem({ action: type, missionProfilId: el.missionProfilId });
      setTitleModal("Vous êtes sûr de vouloir " + type + " ce candidat ?");
      setShow(true);
    }
  }

  /* confirm Action*/
  function confirmAction() {
    switch (selectedItem.action) {
      case "recruter":
        recruteCandidate(selectedItem.missionProfilId);
        break;
      case "rejeter":
        rejectCandidate(selectedItem.missionProfilId);
        break;
      default:
        return null;
    }
  }

  /* Open Modal Interview */
  function openModalInterview(interview, title, type) {
    setTitleModal(title);
    setSelectedItem(interview);
    setOneMission({
      title: interview.title,
      entreprise: {
        username: interview.username,
      },
      date_start: interview.dateStart,
      date_end: interview.dateEnd,
      place: interview.place,
    });
    setTypeModal(type);
    setModalDateInterviewShow(true);
  }

  /* Prepare Style & Data Calendar */
  const selectedDates = {
    upcomming: allInterviews.upcomming
      ? allInterviews.upcomming.map((el) => new Date(el.selectedDate))
      : [],
    passed: allInterviews.passed
      ? allInterviews.passed.map((el) => new Date(el.selectedDate))
      : [],
  };

  /* Array Form Experinces */
  const passedInterviews =
    allInterviews.passed && allInterviews.passed.length
      ? allInterviews.passed.map((work, index) => ({
        ...{
          id: index,
          lastname: work.lastname,
          name: work.name,
          title: work.title,
          selectedDate: work.selectedDate
            ? formatDate(work.selectedDate)
            : null,
        },
      }))
      : null;
  const upcomingInterviews =
    allInterviews.upcomming && allInterviews.upcomming.length
      ? allInterviews.upcomming.map((work, index) => ({
        ...{
          id: index,
          title: work.title,
          selectedDate: work.selectedDate
            ? formatDate(work.selectedDate)
            : null,
        },
      }))
      : null;

  const selectedDatesStyles = {
    upcomming: {
      color: "white",
      backgroundColor: "#D52BFF",
      width: "10%",
      borderRadius: "10px",
      cursor: "pointer",
      padding: "auto"
    },
    passed: {
      color: "white",
      width: "10%",
      border: "1px solid #0f67d9",
      borderRadius: "10px",
      cursor: "pointer",
      padding: "auto"
    },
  };
  const [showed, setShowed] = useState(false);
  const [title, setTitle] = useState(null);
  const target = useRef(null);
  const OpenModal = (e) => {
    const lengthPassed = passedInterviews ? passedInterviews.length : 0;
    const lengthUpcoming = upcomingInterviews ? upcomingInterviews.length : 0;
    if (selectedDates.passed) {
      for (var i = 0; i < lengthPassed; i++) {
        if (
          formatDy(e) === formatDy(selectedDates.passed[i]) &&
          formatDy(selectedDates.passed[i]) ===
          formatDy(passedInterviews[i].selectedDate)
        ) {
          setTitle(passedInterviews[i].title);
          setShowed(!showed);

        }
      }
    }
    if (selectedDates.upcomming) {
      for (var j = 0; j < lengthUpcoming; j++) {
        if (
          formatDy(e) === formatDy(selectedDates.upcomming[j]) &&
          formatDy(selectedDates.upcomming[j]) ===
          formatDy(upcomingInterviews[j].selectedDate)
        ) {
          setTitle(upcomingInterviews[j].title);
          setShowed(!showed);
          setTimeout(() => {
            setShowed(false);
          }, 2000);
        }
      }
    }
  };
  /* Calendar picker */
  function CalendarByDay() {
    return (
      <Row>
        <Overlay
          target={target.current}
          show={showed}
          title={title}
          placement="right"
          onEntered={timeOutCalendar(setShowed)}
        >
          <Tooltip className="tooltip-transform">{title}</Tooltip>
        </Overlay>
        <Col lg={12} md={12} xs={12}>
          <Calendar
            selectedDates={selectedDates}
            selectedDatesStyles={selectedDatesStyles}
            renderDay={OpenModal}
          />
        </Col>
        <Col lg={12} md={12} xs={12} className="mt-4">
          <Row>
            <Col className="text-calendar pink">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Formations à venir"
                  defaultChecked
                  disabled
                />
              </Form.Group>
            </Col>
            <Col className="text-calendar blue">
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Formations passés"
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  /* Change Tab */
  function onSelectTab(tab) {
    setResetForm(true);
    if (allInterviews[tab] && allInterviews[tab].length)
      setDisplayedData(allInterviews[tab]);
    else setDisplayedData([]);
    setDefaultTabValue(tab);
  }

  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      {/*interview popup*/}
      {/* Inerview Modal */}
      <ModalInterview
        title={titleModal}
        type={typeModal}
        mission={oneMission}
        selectedItem={selectedItem}
        modalDateInterviewShow={modalDateInterviewShow}
        setModalDateInterviewShow={setModalDateInterviewShow}
        proposeInterviewRecruiter={proposeInterviewRecruiter}
        changeInterviewRecruiter={changeInterviewRecruiter}
        declineInterviewRecruiter={declineInterviewRecruiter}
      />

      <ContactAdmin
        setModalContact={setModalContact}
        modalContact={modalContact}
        contactAdmin={contactAdmin}
        mission={selectedItem}
      />
      {/* Confirm Modal */}
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={titleModal}
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
      />
      {/* Interview Header */}
      <div id="header-Content" className="header-Content">
        <ShowInterviewHeader />
      </div>

      {/* Interview Listing */}
      <div id="main" className="main">
        <Row>
          <Col lg={8} md={12} xs={12}>
            {/* <Row className="align-items-baseline align-md">
              <Col lg={3} md={12} xs={12} className="w-md-100">
                <label className="title-page">Formations</label>
              </Col>
              
              <Col
                lg={9}
                md={12}
                xs={12}
                className="w-md-100 filter-interview-page"
              >
                <InterviewsFilter
                  resetForm={resetForm}
                  setResetForm={setResetForm}
                  defaultTabValue={defaultTabValue}
                  allInterviews={allInterviews}
                  setDisplayedData={setDisplayedData}
                />
              </Col>
            </Row> */}
            {/* <Tabs
              defaultActiveKey="All"
              activeKey={defaultTabValue}
              onSelect={(k) => onSelectTab(k)}
              className="mb-3 w-100"
            >
              <Tab
                eventKey="All"
                title={
                  "Tous (" +
                  (allInterviews && allInterviews.All
                    ? allInterviews.All.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="proposed"
                title={
                  "En attente (" +
                  (allInterviews && allInterviews.proposed
                    ? allInterviews.proposed.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="changed"
                title={
                  "À changer (" +
                  (allInterviews && allInterviews.changed
                    ? allInterviews.changed.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="upcomming"
                title={
                  "À venir (" +
                  (allInterviews && allInterviews.upcomming
                    ? allInterviews.upcomming.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="passed"
                title={
                  "Passés (" +
                  (allInterviews && allInterviews.passed
                    ? allInterviews.passed.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
              <Tab
                eventKey="declined"
                title={
                  "Rejetés (" +
                  (allInterviews && allInterviews.declined
                    ? allInterviews.declined.length
                    : 0) +
                  ")"
                }
              >
                {!displayedData || !displayedData.length ? (
                  <NothingToShow />
                ) : (
                  showInterviewContent()
                )}
              </Tab>
            </Tabs> */}
            <MissionRecruiter/>
          </Col>
          {/* Calendar */}
          <Col lg={4} md={12} xs={12}>
            {CalendarByDay()}
          </Col>
        </Row>
      </div>
    </>
  );
}
/* Export Profil component */
export default InterviewRecruiter;
