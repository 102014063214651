import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { FaSignOutAlt, FaBell } from 'react-icons/fa';
import { Context as AuthContext } from '../../context/reducers/AuthReducer';
import logo from '../../assets/img/brand/unnamed (6).png';
import fr from '../../assets/img/fr.png';
import './NavBar.scss';
export default function NavBar({ headerClass }) {
    const { signout } = useContext(AuthContext);
    const history = useHistory();
    return (
        <>
            <Navbar id="header-Section" className={"header-Section " + headerClass} bg="black" expand="lg" >
                <div id="nav-Content" className="nav-Content">
                    <Navbar.Brand href="/">
                        <img src={logo} alt="Inspire Talent" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navScroll" className="display-mobile-logo"/>
                    <Navbar.Collapse id="navScroll">
                        <Nav style={{ maxHeight: '531px' }} navbarScroll>
                            <Nav.Link>
                                < FaBell />
                            </Nav.Link>
                            <NavDropdown title={'Mon compte'} className="display-icon-media">
                                <NavDropdown.Item onClick={() => signout(history)}>
                                    <div className="btn-NavBar">
                                        < FaSignOutAlt /> <span className="ml-10"> Se déconnecter</span>
                                    </div>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link className="display-icon-media">
                                <img className="langue-icon float-right" src={fr} alt="Français" />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </>
    );
}
