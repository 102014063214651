import { API_HOST, API_ALL_INTERVIEW_RECRUITER, API_INTERVIEW_RECRUITER, API_REJECT_INTERVIEW_RECRUITER } from "../../Api";
import { ALL_INTERVIEWS_RECRUITER, PROPOSE_INTERVIEW_RECRUITER, CHANGE_INTERVIEW_RECRUITER, DECLINE_INTERVIEW_RECRUITER, DUMP_INTERVIEW } from '../constants/Recruiter';
import axiosServices from "../../services/Axios/axiosServices";
import { SHOW_TOAST } from '../constants/Toast';
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* Get all interviews */
const getAllInterviews = (dispatch) => () => {
    // URL all interviews Recruiter
    const URL = API_HOST + API_ALL_INTERVIEW_RECRUITER + getUserId();
    // Call API all interviews
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: ALL_INTERVIEWS_RECRUITER, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des entretiens" }
            });
        }
    );
}

/* PROPOSE INTERVIEW RECRUITER */
const proposeInterviewRecruiter = (dispatch) => (data, id) => {
    // Url Remove profile from Mission
    const url = API_HOST + API_INTERVIEW_RECRUITER + id;
    // Call Api Removing profil from mission
    axiosServices.post(url, data).then(
        (response) => {
            dispatch({
                type: PROPOSE_INTERVIEW_RECRUITER,
                payload: response.data,
                toastContent: { status: 'success', body: "Entretien est ajouté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de proposition d'entretien" }
            });
        });
}

/* CHANGE INTERVIEW RECRUITER */
const changeInterviewRecruiter = (dispatch) => (data, id) => {
    // Url Remove profile from Mission
    const url = API_HOST + API_INTERVIEW_RECRUITER + id;
    // Call Api Removing profil from mission
    axiosServices.put(url, data).then(
        (response) => {
            dispatch({
                type: CHANGE_INTERVIEW_RECRUITER,
                payload: response.data,
                toastContent: { status: 'success', body: "Entretien est modifié avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de modification d'entretien" }
            });
        });
}

/* DECLINE INTERVIEW RECRUITER */
const declineInterviewRecruiter = (dispatch) => (data, id) => {
    // Url decline interview
    const url = API_HOST + API_REJECT_INTERVIEW_RECRUITER + id;
    // Call Api declining interview
    axiosServices.put(url, data).then(
        (response) => {
            dispatch({
                type: DECLINE_INTERVIEW_RECRUITER,
                payload: response.data,
                toastContent: { status: 'success', body: "Entretien est rejeté avec succès" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de rejet d'entretien"    }
            });
        });
}

/* DUMP  INTERVIEW */
const dumpInterview = (dispatch) => () => {
    dispatch({ type: DUMP_INTERVIEW });
}

/* Export Action interviews Recruiter */
export { getAllInterviews, proposeInterviewRecruiter, changeInterviewRecruiter, declineInterviewRecruiter, dumpInterview };