import { useFormik } from "formik";
import { Button, Form, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import {
  isAdmin,
  isCosmonaute,
} from "../../services/Controller/CurrentUserServices";
import {
  ErrorFeild,
  ErrorClass,
} from "../../services/Controller/ValidationForm";

export default function ModalUploadCra({
  craShow,
  setCraShow,
  id,
  addCra,
  envoieMailCraContrat,
  idCosmonaute,
  name,
  sendInvoices,
  reload,
  savedContratId
}) {
  const validationFiles = Yup.object({
    file: Yup.mixed()
      .required("file est obligatoire")
      .test("fileType", "Format non supporté", function (values, event) {
        const SUPPORTED_FORMATS = [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "application/pdf",
        ];
        return SUPPORTED_FORMATS.includes(formik.values.file?.type);
      }),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: "",
    },
    validationSchema: validationFiles,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("cra", values.file);
      const formDataEmail = new FormData();
      formDataEmail.append("contrat", values.file);
      formDataEmail.append("cra", values.file);
      formDataEmail.append("profil_id", idCosmonaute);
      const formDataVoices = new FormData();
      formDataVoices.append("invoices", values.file);
      if (isCosmonaute()) {
        addCra(formData, id);
      } else if (isAdmin() && name === "user") {
        envoieMailCraContrat(formDataEmail);
      } else if (isAdmin() && name === "recruted") {
        sendInvoices(formDataVoices,savedContratId);
      }
      formik.resetForm();
      setCraShow(false);
    },
  });
  /* Close Modal Mission */
  const handleClose = () => {
    setCraShow(false);
    formik.resetForm();
  };

  return (
    <>
      <Modal show={craShow} onHide={handleClose} size="lg" centered>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {" "}
              {isAdmin() ? "Envoyer document" : "Importer votre CRA"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Form.Group>
                <Form.Control
                  multiple
                  className={ErrorClass(formik, "file")}
                  type="file"
                  name="file"
                  id="file"
                  placeholder="Entrez votre contract"
                  onChange={(event) => {
                    formik.setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  onBlur={formik.handleBlur}
                />
                {ErrorFeild(formik, "file")}
              </Form.Group>
            </Row>
            <Row className="text-detail ml-3">Types autorisés: pdf et image</Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button className="save-button green" type="submit">
              {isAdmin() ? "Envoyer" : "Importer"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
