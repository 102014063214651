import "./ModalConfirm.scss";
import { Button, Modal } from "react-bootstrap";

export default function ModalConfirm({
  show,
  setShow,
  setSelectedItem,
  message,
  Action,
  setReponse,
  modalType,
  handleTrue,
  handleFalse,
}) {
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        centered
        size="md"
      >
        <Modal.Header closeButton className="border-0 text-black">
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body className="row justify-content-center border-0 deleteModal">
          <div className="col-9">
            <p>{message}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="save-button red"
            onClick={() => {
              setShow(false);
              setSelectedItem(null);
              // Reset response only when cancelling
              setReponse(false);
            }}
          >
            Annuler
          </Button>
          <Button
            className="save-button blue"
            onClick={() => {
              Action();
              setSelectedItem(null);
              setShow(false);
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}