
import { API_GET_LANGUES, API_HOST, API_SAVE_LANGUES } from "../../Api";
import axiosServices from "../../services/Axios/axiosServices";
import { SAVE_LANGUAGES, DUMP_LANGUAGES, GET_LANGUES } from '../constants/Cosmonaute';
import { SHOW_TOAST } from '../constants/Toast';

/* Save langues */
export const saveLanguesCandidte = (dispatch) => (data , id) => {
    // URL save langues
    const URL = API_HOST + API_SAVE_LANGUES + id;
    // Call API save langues
    axiosServices.post(URL, data).then(
        (res) => {
            dispatch({
                type: SAVE_LANGUAGES,
                payload: res.data,
                toastContent: { status: 'success', body: "Succès d'enregistrement des langues" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'enregistrement des langues" }
            });
        }
    );
}

// get all langues 
export const getAllLangues = (dispatch) => () => {
    const url = API_HOST + API_GET_LANGUES;
    axiosServices.get(url).then(
        (res) => {
            dispatch({
                type: GET_LANGUES,
                payload: res.data,
                toastContent: { status: 'success', body: "Succès de récupération langues" }
            })
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération langues" }
            });
        }
    )
}
/* dump langues */
export const dumpLanguesCandidte = (dispatch) => () => {
    dispatch({ type: DUMP_LANGUAGES, payload: null, toastContent: null });
}