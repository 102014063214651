import React from "react";
import './avatarGroup.scss'


export default function AvatarGroup({ size, profils, maxitems }) {

    /* get Avatars */
    function getAvatars() {
        let nb = 0;
        return (
            profils.map((el, key) => {
                if (key < (maxitems ? maxitems : 4)) {
                    var letters = '0123456789ABCDEF';
                    var color = '#';
                    for (var i = 0; i < 6; i++) {
                        color += letters[Math.floor(Math.random() * 16)];
                    }
                    return (
                        <span className="avatar" key={key}>
                            <span className="avatar-text avatar-text-inv-primary rounded-circle" style={{ backgroundColor: `${color}` }}><span
                                className="initial-wrap"><span>{el.name?.charAt(0)}{el.lastname?.charAt(0)}</span></span>
                            </span>
                        </span>
                    )
                }
                else {
                    nb++;
                    if (key === profils.length - 1) {
                        return (
                            <span className="avatar" key={key}>
                                <span className="avatar-text avatar-text-inv-primary rounded-circle" style={{ backgroundColor: '#8394B4' }}><span
                                    className="initial-wrap"><span> + {nb}</span></span>
                                </span>
                            </span>
                        )
                    }
                    else return null;
                }
            }
            )
        )
    }

    return (
        <>
            <div className={`avatar-group avatar-group-overlapped proposed-Profile avatar-group-${size}`}>
                {getAvatars()}
            </div>
        </>
    )
}
