import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../assets/img/brand/unnamed (6).png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Header.scss";
import fr from "../../assets/img/fr.png";
import { getUser } from "../../services/Controller/CurrentUserServices";
export default function Header() {
  const { t } = useTranslation();
  const [isClass, setClass] = useState(null);
  useEffect(() => {
    if (getUser()) {
      setClass("login-text1");
    } else {
      setClass("login-text");
    }
  }, []);
  return (
    <Navbar bg="light" expand="lg" className="header">
      <Navbar.Brand href="/" className="pr-5">
        <img className="logo-site" src={logo} alt="Inspire Talent" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="ml-auto my-2 my-lg-0" style={{ maxHeight: "615px", justifyContent:"space-between",width:"90%" }} navbarScroll>
          {/* <Nav.Link as={Link} to="/recrute" className="mobile-padding-link">
            {t("header.nav.link1")}
          </Nav.Link> */}
          <Nav.Link as={Link} to="/recruter" className="mobile-padding-link">
            {t("header.nav.link2")}
          </Nav.Link>
          <Nav.Link as={Link} to="/inscription" className="mobile-padding-link">
            {t("header.nav.link3")}
          </Nav.Link>
          <Nav.Link as={Link} to="/propos" className="mobile-padding-link">
            {t("header.nav.about")}
          </Nav.Link>
          <Nav.Link as={Link} to="/contact" className="mobile-padding-link">
            {t("header.nav.contact")}
          </Nav.Link>
          <Nav.Link as={Link} to="/login" className={"btn-login login-mobile " + isClass}></Nav.Link>
          <Nav.Link id="link-lang">
            <img id="flag-icon" className="langue-icon" src={fr} alt="Français" />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
