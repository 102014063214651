import {
    API_THREE_FIRST_PROPOSED_MISSIONS_RECRUITER,
    API_THREE_FIRST_UPCOMMING_INTERVIEWS_RECRUITER,
    API_THREE_FIRST_ONGOING_MISSIONS_RECRUITER,
    API_DASHBOARD_RECRUITER,
    API_HOST,
    API_POURCENTAGE_RECRUITER
} from "../../Api";
import axiosServices from "../../services/Axios/axiosServices";
import {
    THREE_FIRST_PROPOSED_MISSIONS,
    THREE_FIRST_UPCOMMING_INTERVIEWS,
    THREE_FIRST_ONGOING_MISSIONS,
    DASHBOARD_RECRUITER,
    POURCENTAGE_RECRUITER
} from '../constants/Recruiter';
import { SHOW_TOAST } from '../constants/Toast';
import { getUserId } from "../../services/Controller/CurrentUserServices";

/* Get dashboard recruiter */
const getDashboardRecruiter = (dispatch) => () => {
    // URL get dashboard recruiter
    const URL = API_HOST + API_DASHBOARD_RECRUITER + getUserId();
    // Call API dashboard recruiter
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: DASHBOARD_RECRUITER, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des informations recruiter" }
            });
        }
    );
}

/* Get First Three proposed missions */
const getFirstThreeProposedMissions = (dispatch) => () => {
    // URLget proposed missions
    const URL = API_HOST + API_THREE_FIRST_PROPOSED_MISSIONS_RECRUITER + getUserId();
    // Call API proposed Mission
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: THREE_FIRST_PROPOSED_MISSIONS, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des missions proposées" }
            });
        }
    );
}

/* Get First Three Upgoing Interviews */
const getFirstThreeUpcommingInterview = (dispatch) => () => {
    // URL First Three up Interviews
    const URL = API_HOST + API_THREE_FIRST_UPCOMMING_INTERVIEWS_RECRUITER + getUserId();
    // Call API First Three up Interviews
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: THREE_FIRST_UPCOMMING_INTERVIEWS, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des trois premiers interviews" }
            });
        }
    );
}

/* Get First Three Ongoing Missions */
const getFirstThreeOngoingMission = (dispatch) => () => {
    // URL First Three Ongoing Missions
    const URL = API_HOST + API_THREE_FIRST_ONGOING_MISSIONS_RECRUITER + getUserId();
    // Call API First Three Ongoing Missions
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: THREE_FIRST_ONGOING_MISSIONS, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération des trois premiers ongoing missions" }
            });
        }
    );
}
/* Get First Three Ongoing Missions */
const pourcentageRecruiter = (dispatch) => () => {
    // URL First Three Ongoing Missions
    const URL = API_HOST + API_POURCENTAGE_RECRUITER+ getUserId();
    // Call API First Three Ongoing Missions
    axiosServices.get(URL).then(
        (res) => {
            dispatch({ type: POURCENTAGE_RECRUITER, payload: res.data, toastContent: null });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec de récupération pourcentage profil" }
            });
        }
    );
}

/* Export Action Dashboard Recruiter */
export { getFirstThreeProposedMissions, getFirstThreeUpcommingInterview, getFirstThreeOngoingMission, getDashboardRecruiter,pourcentageRecruiter };