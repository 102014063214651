import React, { useContext } from 'react'
import { Button, Row, Col, Image, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { Context as CosmonauteContext } from "../../../../context/reducers/CosmonauteReducer";
import { ErrorClass, ErrorFeild } from '../../../../../services/Controller/ValidationForm';
import { Context as CosmonauteContext } from "../../../../../context/reducers/CosmonauteReducer";


const TypeSkillsCosmo = ({allTypeSkills,id}) => {

    const validationSchema = Yup.object({
        domain: Yup.string().required("Champ obligatoire"),
        type: Yup.number().required("Champ obligatoire"),
        formations_possible: Yup.string().required("Champ obligatoire"),
      });

      const {saveTypeSkillsCandidte} = useContext(CosmonauteContext);



    const TypeSkills = useFormik({
        enableReinitialize: true,
        initialValues: {
         
          domain: "",
          type:null,
          formations_possible :"",
          
         
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
        
          var data = {
           
            type:values.type,
            domain: values.domain,
            formations_possible: values.formations_possible,
          };

          saveTypeSkillsCandidte(data,id);
         
        },
      });

  return (
    <Form onSubmit={TypeSkills.handleSubmit}>
    <Button type="submit" className="shadow-unset float-right save-button border-dark">
                Sauvegarder
            </Button>
    <div className="title-profile-cosmonaute mb-5">COMPÉTENCES</div>
       <Form.Group>
      <Form.Label className="title-inscri">
        Domaine d’expertise
      </Form.Label>
      <Form.Select
        name="domain"
        id="domain"
        onChange={TypeSkills.handleChange}
        onBlur={TypeSkills.handleBlur}
        value={TypeSkills.values.domain}
      >
        <option className="text-bold" defaultValue value="">
          {" "}
          Sélectionnez une option{" "}
        </option>
        <option value="Finance et Comptabilité">
          Finance et Comptabilité
        </option>
        <option value="Ressources Humaines">Ressources Humaines</option>
        <option value="Marketing et Communication">
          Marketing et Communication
        </option>
        <option value="Vente et Développement Commercial">
          Vente et Développement Commercial
        </option>
        <option value="Technologies de l'Information (IT)">
          Technologies de l'Information (IT)
        </option>
        <option value="Ingénierie et Production">
          Ingénierie et Production
        </option>
        <option value="Logistique et Supply Chain">
          Logistique et Supply Chain
        </option>
        <option value="Droit et Juridique">Droit et Juridique</option>
        <option value="Gestion de Projet">Gestion de Projet</option>
        <option value="Santé et Services Sociaux">
          Santé et Services Sociaux
        </option>
        <option value="Expertise numérique">Expertise numérique</option>
        <option value="Bien être">Bien être</option>
        <option value="Soft Skill">Soft Skill</option>
        <option value="Musique">Musique</option>
        <option value="Art">Art</option>
        <option value="Sport">Sport</option>
        <option value="Autre">Autre</option>
      </Form.Select>
      {ErrorFeild(TypeSkills, "domain")}
    </Form.Group>
   
    <Form.Group className="pt-4 ">
      <Form.Label className="text-Label-modal">
        {" "}
        Choisissez une catégorie de formation{" "}
      </Form.Label>
      <Form.Select
        className={ErrorClass(TypeSkills, "type")}
        name="type"
        id="type"
        // onChange={TypeSkills.handleChange}
        onChange={(event) => {
          TypeSkills.setFieldValue("type", Number(event.target.value));
      }}
        onBlur={TypeSkills.handleBlur}
        value={TypeSkills.values.type}
      >
        <>
        <option className="text-bold" defaultValue value="">
          {" "}
          Sélectionnez une option{" "}
        </option>
        {allTypeSkills && allTypeSkills.length
          ? allTypeSkills.map((el, index) => {
              return (
                <option
                  className="color-black"
                  key={"option" + index}
                  value={el.value}
                >
                  {el.label}
                </option>
              );
            })
          : null}
        </>
        
      </Form.Select>
      {ErrorFeild(TypeSkills, "type")}

    </Form.Group>
    <Form.Group className="pt-4">
      <Form.Label className="text-Label-modal">
        {" "}
        Formations possible {" "}
      </Form.Label>
      <Form.Control
        className={ErrorClass(TypeSkills, "formations_possible")}
        type="text"
        name="formations_possible"
        id="formations_possible"
        placeholder="Nom de la formation"
        onChange={(event) => {
          TypeSkills.handleChange(event);
        }}
        onBlur={TypeSkills.handleBlur}
        value={TypeSkills.values.formations_possible}
      />
      {ErrorFeild(TypeSkills, "formations_possible")}
    </Form.Group>
    </Form>
  )
}

export default TypeSkillsCosmo