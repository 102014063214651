import { SHOW_TOAST } from "../constants/Toast";
import axiosServices from "../../services/Axios/axiosServices";
import {
  API_ADD_INSPIRNAUTE,
  API_HOST,
  API_LIST_SKILLS,
  API_ADD_RECRUTER,
  API_DOMAIN_RECRUTER,
  API_CONTACT,
  API_USER_EMAIL,
} from "../../Api";
import {
  ADD_INSPIRNAUTE,
  GET_SKILLS,
  ADD_RECRUTER,
  GET_DOMAIN_RECRUTER,
  ENVOIE_CONTACT,
} from "../constants/Inscription";

/* inscription inspirnaute*/
const inscriptionInspirnaute = (dispatch) => (body) => {
  const URL = API_HOST + API_ADD_INSPIRNAUTE;
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ADD_INSPIRNAUTE,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "Demande envoyée avec succès, un email de validation sera envoyé.",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'ajout inspirnaute" },
      });
    }
  );
};

const inscriptionRecruter = (dispatch) => (body) => {
  const URL = API_HOST + API_ADD_RECRUTER;
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ADD_RECRUTER,
        payload: res.data,
        toastContent: {
          status: "success",
          body: "Demande envoyée avec succès, un email de validation sera envoyé.",
        },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", body: "Echec d'ajout recruteur" },
      });
    }
  );
};
const sendContact = (dispatch) => (body) => {
  const URL = API_HOST + API_CONTACT;
  axiosServices.post(URL, body).then(
    (res) => {
      dispatch({
        type: ENVOIE_CONTACT,
        payload: res.data,
        toastContent: { status: "success", body: "Email envoyé avec succès", type: "Success_CONTACT" },
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: { status: "error", type: "Error_CONTACT", body: "Erreur lors de l'envoi du mail" },
      });
    }
  );
};
/* Get list skills by type */
const getListSkills = (dispatch) => () => {
  // URL get list Missions for Admin
  const URL = API_HOST + API_LIST_SKILLS;
  // Call API list Missions for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({ type: GET_SKILLS, payload: res.data, toastContent: null });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération liste des compétences",
        },
      });
    }
  );
};
/* Get list skills by type */
const getDomainRecruter = (dispatch) => () => {
  // URL get list Missions for Admin
  const URL = API_HOST + API_DOMAIN_RECRUTER;
  // Call API list Missions for Admin
  axiosServices.get(URL).then(
    (res) => {
      dispatch({
        type: GET_DOMAIN_RECRUTER,
        payload: res.data,
        toastContent: null,
      });
    },
    (err) => {
      dispatch({
        type: SHOW_TOAST,
        toastContent: {
          status: "error",
          body: "Echec de récupération liste des domains",
        },
      });
    }
  );
};
/* check email exist or not*/
const checkEmail = (dispatch) => (data) => {
  const URL = API_HOST + API_USER_EMAIL;
  return axiosServices.post(URL, data).then((res) => { return res.data });
};

/* Export Action FollowUP Missions/Interviews for Administrator */
export {
  inscriptionInspirnaute,
  getListSkills,
  inscriptionRecruter,
  getDomainRecruter,
  sendContact,
  checkEmail,
};
