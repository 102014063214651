import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContactFormateur from "../../components/contact/ContactInspirnaute";
import Header from "../../components/Header/Header";
import ScrollToTopArrow from "../ArrowScrollToTop/ScrollToTopArrow";
import "./Contact.scss";
import {Link} from "react-router-dom";
import img10 from "../../assets/img/Colonnes copie (2) (1).png";

export default function Contact() {
  const { t } = useTranslation();
  
  return (
    <div className="iscriptionPage">
      {/* Arrow Scroll Top */}
      <ScrollToTopArrow />
      {/* Header section */}
      <section className="contact-section1">
        <Header></Header>
        {/* <ScrollAnimation animateIn='flipInY'> */}
        <div className=" text-uppercase text-center roboto-condensed-bold fontSize-140 padding-contact1 text-spacing-3 padding-top-title yellow-color">
          {t("contact.section1.title")}
        </div>

        {/* </ScrollAnimation> */}
        <img src={img10} alt="img10" className="img10" />

        {/* <ScrollAnimation animateIn='flipInY'> */}
        <div className="yellow-color text-uppercase text-center roboto-black fontSize-50 line-height-description mt-30 pt-20">
          {t("contact.section1.text")}
        </div>
        <div className="yellow-color text-uppercase text-center roboto-black fontSize-50 mb-5 line-height-description">
          {t("contact.section1.text1")}
        </div>
        {/* </ScrollAnimation> */}
        <div className="it-Description fontSize-25 roboto-light text-center">
          <div> {t("contact.section1.description")}</div>
          <div>{t("contact.section1.description1")}</div>
          <div>{t("contact.section1.description2")}</div>
          <div className="mb-5">{t("contact.section1.description3")}</div>
          
        </div>
        {/* <ScrollAnimation animateIn='tada'> */}
          <div className="scroll-downs mt-5 scroll-padding">
            <div className="mousey">
              <div className="scroller"></div>
            </div>
          </div>
          {/* </ScrollAnimation> */}
        {/* <div className="stars"></div> */}
        {/* <div className="overlay-section1"></div> */}

      </section>
      <section className="contact-section2">
        <div className="overlay-contact"></div>
        {/* <div className="stars1"></div> */}
        <div className="overlay-plane"></div>
        {/* <div className="overlay-contacts2"> </div> */}
        <ContactFormateur></ContactFormateur>
      </section>
      <section id="section10">
          <Row className="line-height-row">
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
              </ScrollAnimation> */}
              <p className="roboto-condensed-bold text-uppercase fontSize-70 yellow-color float-left text-spacing-7 pt-20 ">
                  {t("home.univers")}
                </p>
            </Col>
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              > */}
                <p className="roboto-condensed-bold ml-big text-uppercase fontSize-100 yellow-color text-spacing-3 infini mt-80 pt-40">
                  {t("home.infini")}
                </p>
              {/* </ScrollAnimation> */}
            </Col>
            <Col lg={12} md={12} xs={12}>
              {/* <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              > */}
                <p className="roboto-condensed-bold text-uppercase fontSize-100 text-yellow-transparent contact text-center">
                  {t("home.contact")}
                </p>
              {/* </ScrollAnimation> */}
            </Col>
            <Col lg={12} md={12} xs={12}>
              <div>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.adress")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.phone")}
                </p>
                <p className="roboto-light text-center fontSize-35 margin-contact-mobile">
                  {t("home.mail")}
                </p>
                <p className="roboto-light text-center fontSize-25 notice text-underline">
                  <Link to="/notice">{t("home.mention")}</Link>
                </p>
              </div>
            </Col>
          </Row>
        </section>
        <Footer />
    </div>
  );
}
function Footer() {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          <Col lg={3} md={6} xs={12} className="footer-col">
          <h4 className="footer-title">Acropolia Talents</h4>
          <p>Acropolia place l’humain au <br />coeur de ses préoccupations</p>
          <p>+33 9 50 20 18 15</p>
            <p>hi@acropolia.academy</p>
            <div className="social-icons">
              <a href="https://www.instagram.com/acropolia_academy/" target="_blank" rel="noopener noreferrer" className="social-icon instagram">IG</a>
              <a href="https://www.facebook.com/profile.php?id=61551011631802" target="_blank" rel="noopener noreferrer" className="social-icon facebook">FB</a>
              {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon linkedin">LN</a> */}
              <a href="https://www.youtube.com/@acropolia_academy" target="_blank" rel="noopener noreferrer" className="social-icon youtube">YT</a>
              {/* <a href="https://x.com" target="_blank" rel="noopener noreferrer" className="social-icon x">X</a> */}
            </div>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Au plus proche de vous</h4>
            <ul>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/recruter">Trouver un formateur</Link></li>
              <li><Link to="/inscription">Devenir formateur</Link></li>
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Autres pages</h4>
            <ul>
              <li><Link to="/">Accueil</Link></li>
              <li><Link to="/propos">A propos</Link></li>
              <li><Link to="/login">Connexion</Link></li>
              {/* <li><Link to="/about-dino">L'histoire de Dino</Link></li> */}
            </ul>
          </Col>

          <Col lg={3} md={6} xs={12} className="footer-col">
            <h4 className="footer-title">Liens légaux</h4>
            <ul>
              <li><Link to="/notice">Mentions légales</Link></li>
              {/* <li><Link to="/terms">Conditions générales de services</Link></li> */}
              {/* <li><Link to="/privacy">Politique de confidentialité</Link></li> */}
              {/* <li><Link to="/cookies">Charte en matières de cookies</Link></li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}