/* Error Message */
function ErrorFeild(formGroup, control) {
    if (formGroup.submitCount > 0 && formGroup.touched[control] && formGroup.errors[control]) {
        return <p className="error_feild mt-1"> {formGroup.errors[control]} </p>
    }
}

/* Error Class */
function ErrorClass(formGroup, control) {
    if (formGroup.submitCount > 0 && formGroup.touched[control] && formGroup.errors[control]) return " error";
    else return "";
}

/* Error FormArray Message */
function ErrorArrayFeild(formGroup, formArray, index, control) {
    if (
        formGroup.submitCount > 0 &&
        formGroup.touched[formArray] &&
        formGroup.touched[formArray][index] &&
        formGroup.touched[formArray][index][control] &&
        formGroup.errors[formArray] &&
        formGroup.errors[formArray][index] &&
        formGroup.errors[formArray][index][control]
    ) {
        return <p className="error_feild mt-1"> {formGroup.errors[formArray][index][control]} </p>
    }
}

/* Error formArray Class */
function ErrorArrayClass(formGroup, formArray, index, control,) {
    if (
        formGroup.submitCount > 0 &&
        formGroup.touched[formArray] &&
        formGroup.touched[formArray][index] &&
        formGroup.touched[formArray][index][control] &&
        formGroup.errors[formArray] &&
        formGroup.errors[formArray][index] &&
        formGroup.errors[formArray][index][control]
    ) {
        return "error";
    }
    else return "";
}

export { ErrorFeild, ErrorClass, ErrorArrayFeild, ErrorArrayClass }