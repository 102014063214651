import React from 'react';
import { useFormik} from 'formik';
import {Button, Card, Form} from "react-bootstrap";
import * as Yup from "yup";
import './PasswordForm.scss'
import {useHistory} from "react-router-dom";
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Email est invalide')
        .required('Email est obligatoire'),
});
const validationReset = Yup.object({
    password: Yup.string().required('champ obligatoire').min(8,'mot de passe faible'),
    plainPassword:Yup.string().required('champ obligatoire').oneOf([Yup.ref("password")], "Les mots de passe ne correspondent pas"),
});
export default function PasswordForm({token=null,ForgetPassword=null,ResetPassword=null}) {
    const history=useHistory()
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema:validationSchema,
        onSubmit:values => {
            const data =values;
            ForgetPassword(data)
            formik.resetForm({
                values:{
                    email: '',
                }
            })
        }
    })
    const reset = useFormik({
        initialValues: {
            password: '',
            plainPassword: '',
        },
        validationSchema:validationReset,
        onSubmit:values => {
            const data =values;
            ResetPassword(data,token)
            reset.resetForm({
                values:{
                    password: '',
                    plainPassword: '',
                }
            })
            history.push('/login');
        }
    })
    function forgetPassword(){
            return(
                <Card className="text-black  form-section" bg='default'>
                    <div className="form-section">
                        <Card.Title className="text-primary"><p className="text-justify title yellow-color">Réinitialiser Mot De Passe</p></Card.Title>
                        <Card.Body className="p-4">
                            <Form onSubmit={formik.handleSubmit} >
                                <Form.Group className="mb-5 formGroup" >
                                    <Form.Label className="text-navy form-label" >Email</Form.Label>
                                    <Form.Control type="email"
                                                name="email"
                                                id="email"
                                                className="loginInput"
                                                placeholder="Enter your email"
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                    />
                                    {formik.errors.email? (<p className="text-danger validation-text mt-2">
                                        {formik.errors.email}
                                    </p>):null}
                                </Form.Group>
                                <div className="d-flex flex-column  align-items-center col-12 mb-3">
                                    <Button className="LoginSubmitBtn" variant="primary" type="submit">
                                        Réinitialiser
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </div>

                </Card>
            )
    }
    function resetPassword(){
        return(
            <Card className="text-black  form-section" bg='default'>
                <div className="form-section">
                    <Card.Title className="text-primary"><p className="text-justify title">Réinitialiser Mot De Passe</p></Card.Title>
                    <Card.Body className="p-4">
                        <Form onSubmit={reset.handleSubmit} >
                            <Form.Group className="mb-3 formGroup" >
                                <Form.Label className="text-navy form-label">Mot de passe</Form.Label>
                                <Form.Control type="password"
                                              name="password"
                                              id="password"
                                              className=" loginInput"
                                              placeholder="Saisir votre mot de passe"
                                              onChange={reset.handleChange}
                                              value={reset.values.password}
                                />
                                {reset.errors.password? (<p className="text-danger validation-text mt-2">
                                    {reset.errors.password}
                                </p>):null}
                            </Form.Group>
                            <Form.Group className="mb-5 formGroup" >
                                <Form.Label className="text-navy form-label">Confirmer mot de passe</Form.Label>
                                <Form.Control type="password"
                                              name="plainPassword"
                                              id="plainPassword"
                                              className=" loginInput"
                                              placeholder="Confirmer mot de passe"
                                              onChange={reset.handleChange}
                                              value={reset.values.plainPassword}
                                />
                                {reset.errors.plainPassword? (<p className="text-danger validation-text mt-2">
                                    {reset.errors.plainPassword}
                                </p>):null}
                            </Form.Group>
                            <div className="d-flex flex-column  align-items-center col-12 mb-3">
                                <Button className="LoginSubmitBtn" variant="primary" type="submit">
                                    Réinitialiser
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </div>
            </Card>
        )
    }
    return (
        <>
            {
                 token ? resetPassword() :
                     forgetPassword()
            }
        </>
    );
};


