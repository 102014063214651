import { useContext, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import Paginate from "../../../components/Paginate/Paginate";
import { Context as DocumentContext } from "../../../context/reducers/DocumentReducer";
export default function DocumentAdmin() {
  const [listeCandidat, setListeCandidat] = useState(null);
  const [listeEntreprise , setlisteEntreprise ] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentRecruters, setCurrentRecruters] = useState([]);
  const { state, getAllCandidatRecruted, getAllRecrutedRecruiter } =
    useContext(DocumentContext);
  /* use History */
  const history = useHistory();
  const someEventHandler = (id) => {
    history.push("/admin/candidat/" + id);
  };
  const recruterEventHandler = (id) => {
    history.push("/admin/recruter/" + id);
  };
  /* eslint-disable */
  useEffect(() => {
    getAllCandidatRecruted();
    getAllRecrutedRecruiter();
  }, []);
  /* eslint-enable */
  useEffect(() => {
    setListeCandidat(state.candidatRecruted);
    setlisteEntreprise (state.recruiterRecruted);
  }, [state]);
  function ListeCandidates() {
    return currentItems.map((item, index) => (
      <tr key={index}>
        <td className="text-font-td pt-4 pb-4">
          {item ? item.user_name : "..."}
        </td>
        <td className="pt-4 pb-4">{item ? item.nbrDoc : "..."}</td>
        <td className="pt-4 pb-4">
          <div className="text-center pointer d-flex">
            {/* Tooltip */}
            <ReactTooltip id="Consult" place="bottom">
              {" "}
              Consulter{" "}
            </ReactTooltip>
            <span
              className="btn-detail blue"
              onClick={() => {
                someEventHandler(item.user_id);
              }}
            >
              <FaEye data-tip data-for="Consult" />
            </span>
          </div>
        </td>
      </tr>
    ));
  }
  function ListeRecruters() {
    return currentRecruters.map((item, index) => (
      <tr key={index}>
        <td className="text-font-td pt-4 pb-4">
          {item ? item.recruiter_name : "..."}
        </td>
        <td className="pt-4 pb-4">{item ? item.nbrDoc : "..."}</td>
        <td className="pt-4 pb-4">
          <div className="text-center pointer d-flex">
            {/* Tooltip */}
            <ReactTooltip id="Consult" place="bottom">
              {" "}
              Consulter{" "}
            </ReactTooltip>
            <span
              className="btn-detail blue"
              onClick={() => {
                recruterEventHandler(item.user_id);
              }}
            >
              <FaEye data-tip data-for="Consult" />
            </span>
          </div>
        </td>
      </tr>
    ));
  }
  return (
    <>
      <div id="main-without-header" className="main-without-header">
        <Row className="title-page mb-5">Documents des Formateurs et des Entreprises</Row>
        <Row className="display-row-admin">
          <Col className="border-Col-document" lg={6} md={6} xs={12}>
            <div className="text-mission-document color-1 margin-buttom-col-candidat">Documents par Formateur ({listeCandidat ? listeCandidat.length :0})</div>
            <Table className="table user-table">
              <thead>
                <tr>
                  <th className="document-th-40">Formateur</th>
                  <th className="document-th-30">Nombre de documents</th>
                  <th className="document-th-30">Détail Formateur</th>
                </tr>
              </thead>
              <tbody>
                {listeCandidat && listeCandidat.length
                  ? ListeCandidates()
                  : null}
              </tbody>
            </Table>
            {!listeCandidat || !listeCandidat.length ? (
              <NothingToShow type="array" />
            ) : (
              <Paginate
                items={listeCandidat}
                setCurrentItems={setCurrentItems}
                itemsPerPage={4}
              />
            )}
          </Col>
          <Col className="border-Col-document" lg={6} md={6} xs={12}>
            <div className="text-mission-document color-2 margin-bottom-th">Documents par Entreprise  ({listeEntreprise  ? listeEntreprise .length:0})</div>
            <Table className="table user-table">
              <thead>
                <tr>
                  <th className="document-th-40">Entreprise </th>
                  <th className="document-th-30">Nombre de documents</th>
                  <th className="document-th-30">Détail Entreprise </th>
                </tr>
              </thead>
              <tbody>
                {listeEntreprise  && listeEntreprise .length
                  ? ListeRecruters()
                  : null}
              </tbody>
            </Table>
            {!listeEntreprise  || !listeEntreprise .length ? (
              <NothingToShow type="array" />
            ) : (
              <Paginate
                items={listeEntreprise }
                setCurrentItems={setCurrentRecruters}
                itemsPerPage={4}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
