
import { API_HOST, SAVE_ALL_TYPE_SKILLS } from "../../Api";
import axiosServices from "../../services/Axios/axiosServices";
// import { GET_ALL_SKILLS, SAVE_SKILLS, DUMP_SKILLS } from '../constants/Cosmonaute';
import { SHOW_TOAST } from '../constants/Toast';

/* Save skills with Ratings */
export const saveTypeSkillsCandidte = (dispatch) => (data,id) => {
    // URL save Skills
    const URL = API_HOST + SAVE_ALL_TYPE_SKILLS + id;
    // Call API save Skills
    axiosServices.post(URL, data).then(
        (res) => {
            dispatch({
                // type: SAVE_SKILLS,
                payload: res.data,
                toastContent: { status: 'success', body: "Succès d'enregistrement des compétences" }
            });
        },
        (err) => {
            dispatch({
                type: SHOW_TOAST,
                toastContent: { status: 'error', body: "Echec d'enregistrement des compétences" }
            });
        }
    );
}
