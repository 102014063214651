import React, { useContext, useEffect, useState } from "react";
import "./RecruiterAdmin.scss";
import { Row, Col, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import ModalDetail from "../../../components/ModalDetail/ModalDetail";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Paginate from "../../../components/Paginate/Paginate";
import RecruterFilter from "../../../components/RecruterFilter/RecruterFilter";

/* Recruiters Profils Page */
function RecruiterAdmin() {
  /* History */
  const history = useHistory();

  /* use Translation */
  const { t } = useTranslation();

  /* Context API */
  const { getAllRecruiters, hideToast, state } =
    useContext(AdministratorContext);
  /* filter Input */
  const [resetForm, setResetForm] = useState(false);
  /* recruiters lists */
  const [allRecruiters, setAllRecruiters] = useState([]);

  /* Table Content */
  const displayedColumns = [
    "Nom entreprise",
    "Domaine entreprise",
    "Nom Entreprise ",
    "Poste",
    "Lieu",
    "Téléphone",
    "Email",
    "",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);

  /* Detail Profil */
  const [modalShow, setModalShow] = useState(false);
  const [DataToShow, setDataToShow] = useState(null);
  /* eslint-disable */
  useEffect(() => {
    getAllRecruiters();
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.allRecruiters) {
      setAllRecruiters(state.allRecruiters);
      setDisplayedData(state.allRecruiters);
    }
  }, [state]);

  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Show Profil Detail */
  function functionshowProfileDetail(item) {
    setDataToShow({
      Title: "Détail Profil Entreprise ",
      data: [
        {
          title: "Nom entreprise",
          value: item.company_name ? item.company_name : "- - -",
        },
        {
          title: "Domaine entreprise",
          value: item.domain_recruiter
            ? item.domain_recruiter[0].name
            : "- - -",
        },
        {
          title: "Téléphone",
          value: item.phone_number ? item.phone_number : "- - -",
        },
        { title: "Email", value: item.email ? item.email : "- - -" },
        { title: "Lieu", value: item.country ? item.country : "- - -" },
        { title: "Adresse", value: item.address ? item.address : "- - -" },
        { title: "URL", value: item.link ? item.link : "- - -" },
        {
          title: "Horaire de travail",
          value:
            item.start_at && item.finish_at
              ? "Commence en " +
                item.start_at +
                " et termine en " +
                item.finish_at
              : "- - -",
        },
        {
          title: "Description",
          value: item.description ? item.description : "- - -",
        },
        {
          title: "Nom Entreprise ",
          value: item ? item?.firstname +' '+item?.lastname: "- - -",
        },
        {
          title: "Email Entreprise ",
          value: item.email ? item.email : "- - -",
        },
      ],
    });
    setModalShow(true);
  }

  /* Content Profils List */
  function showProfilesContent() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length ? getListRecruiter() : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }
  /* list Recruiter Map */
  function getListRecruiter() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Recruiter" + index}>
          <td className="w-250">
            <p
              className="link"
              onClick={() => {
                redirectTo(el);
              }}
            >
              {el.company_name ? el.company_name : "- - -"}
            </p>
          </td>
          <td className="w-250">
            <p>{el.domain_recruiter ? el.domain_recruiter[0].name : "- - -"}</p>
          </td>
          <td className="w-250">
            <p>{el ? el?.firstname +" "+ el?.lastname: "- - -"}</p>
          </td>
          <td className="w-250">
            <p>{el.poste ? el.poste : "- - -"}</p>
          </td>
          <td className="w-250">
            <p>{el.country ? el.country : "- - -"}</p>
          </td>
          <td className="w-250">
            <p>{el.phone_number ? el.phone_number : "- - -"}</p>
          </td>
          <td className="w-250">
            <p>{el.email ? el.email : "- - -"}</p>
          </td>
          <td>
            <div className="float-right pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip id="Consult" place="bottom">
                {" "}
                Consulter{" "}
              </ReactTooltip>
              <span
                className="btn-detail blue"
                onClick={() => {
                  functionshowProfileDetail(el);
                }}
              >
                <FaEye data-tip data-for="Consult" />
              </span>
            </div>
          </td>
        </tr>
      );
    });
  }

  /* redirectTo Recruiter Detail */
  function redirectTo(el) {
    history.push(
      t("role.administrator") + t("sidebar.recruiters.path") + "/" + el.id_user
    );
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      <ModalDetail
        modalShow={modalShow}
        setModalShow={setModalShow}
        DataToShow={DataToShow}
      />
      <div id="main-without-header" className="main-without-header">
        <Row className="align-md">
          <Col lg={3} md={12} xs={12}>
            <label className="title-page mb-4">Entreprise s ({displayedData && displayedData.length?  displayedData.length: "0"})</label>
          </Col>
        </Row>
        <Col lg={12} md={12} xs={12}>
            <RecruterFilter
              resetForm={resetForm}
              setResetForm={setResetForm}
              allMissions={allRecruiters}
              setDisplayedData={setDisplayedData}
            />
          </Col>
        {showProfilesContent()}
      </div>
    </>
  );
}
/* Export recruiters list component */
export default RecruiterAdmin;
