import React, { useContext, useEffect, useState } from "react";
import { Table, Row, Col } from "react-bootstrap";
import { FaRegCalendarMinus, FaMapMarkerAlt, FaUserCheck, FaUserTimes, FaUserLock } from "react-icons/fa";
import { Context as AdministratorContext } from "../../../context/reducers/AdminReducer";
import ToastService from "../../../components/Toast/ToastService";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import AvatarGroup from "../../../components/avatar/avatarGroup";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate, getPeriode } from "../../../services/Controller/DateFormatter";
import ReactTooltip from "react-tooltip";
import Paginate from "../../../components/Paginate/Paginate";
import { Rating } from "@mui/material";
import "./DetailMissionAdmin.scss";

/* Detail Mission Page */
function DetailMission() {
  /* use Translation */
  const { t } = useTranslation();
  /* use History */
  const history = useHistory();
  /* use Location */
  const location = useLocation();
  const paramId = location.pathname.split("/")[3];
  localStorage.setItem("m", "m");
  localStorage.setItem("id", paramId);
  /* Context API */
  const {
    getOneMission,
    getProfileMatchingList,
    affectProfileToMission,
    removeProfileFromMission,
    dumpProfile,
    hideToast,
    state,
  } = useContext(AdministratorContext);
  /* Mission Detail */
  const [oneMission, setOneMission] = useState(null);
  /* eslint-disable */
  useEffect(() => {
    const id = location.pathname.split("/")[3];
    getOneMission(id);
    getProfileMatchingList(id);
  }, []);
  /* eslint-enable */
  useEffect(() => {
    if (state.oneMission) {
      setOneMission(state.oneMission);
    }
    if (state.listProfilMatching) setDisplayedData(state.listProfilMatching);
  }, [state]);
  /* refresh list profiles */
  useEffect(() => {
    if (state.profilMission) {
      const id = location.pathname.split("/")[3];
      getOneMission(id);
      getProfileMatchingList(id);
      dumpProfile();
    }
  }, [
    dumpProfile,
    getOneMission,
    getProfileMatchingList,
    state.profilMission,
    location,
  ]);
  /* mission ongoing */
  function isOngoing() {
    if (state.oneMission) {
      if (
        formatDate(new Date()) >= formatDate(state.oneMission?.date_start) &&
        // formatDate(new Date()) <= formatDate(state.oneMission?.date_end) &&
        state.oneMission?.mission_profils?.profile?.find(
          (item) => item?.status === "recruited"
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  /*mission finished*/
  function isInterrupted() {
    if (state.oneMission?.is_interrupted === true) {
      return true;
    } else {
      return false;
    }
  }
  /*mission finished*/
  // function isFinished() {
  //   if (formatDate(new Date()) > formatDate(state.oneMission?.date_end)) {
  //     return true;
  //   }
  //   return false;
  // }

  /* Confirm Modal */
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [titleModal, setTitleModal] = useState("");
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent) hideToast();
  }, [hideToast, state.toastContent]);
  /* Table Content */
  const displayedColumns = [
    "Candidat",
    "Profile",
    "Expérience",
    "Lieu",
    "Disponibilité",
    "",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  /* Content Missions Detail For Admin */
  function showMissionDetailAdmin() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length
              ? getListProfilsAdmin()
              : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length ? (
          <NothingToShow type="array" />
        ) : (
          <Paginate
            items={displayedData}
            setCurrentItems={setCurrentItems}
            itemsPerPage={10}
          />
        )}
      </div>
    );
  }

  /* list Missions Mapping */
  function getListProfilsAdmin() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Profil" + index}>
          <td className="w-300">
            <Row className="align-items-center margin-unset">
              <Col lg={2} md={6} xs={12}><AvatarGroup size="md" profils={[el]} /></Col>
              <Col className="margin-unset" lg={10} md={6} xs={12}>
                <div className="title-candidat-name link mb-2" onClick={() => { redirectTo(el); }}>
                  {el.name} {el.lastname}
                </div>
                {el.status === "recruited" ? <span className="text-tag green">Recruté(e)</span> : null}
                {el.status === "rejected" ? <span className="text-tag red">Recruté(e)</span> : null}
              </Col>
            </Row>
          </td>
          <td className="w-200">
            <p>{el.poste ? el.poste : null}</p>
          </td>
          <td className="w-200">
            <p>{el.nbrAnneeExp ? el.nbrAnneeExp : 0} ans</p>
          </td>
          <td className="w-200">
            <p>{el.country ? el.country : null}</p>
          </td>
          <td className="w-200">
            <p>
              Du {el.disponibilityDate ? formatDate(new Date(el.disponibilityDate)) + " " : null}
            </p>
          </td>
          <td>
            <div className="float-right pointer d-flex">
              {/* Tooltip */}
              <ReactTooltip place="bottom" />
              {/* Actions */}
              {(isOngoing() || isInterrupted()) &&
                !isAffected(el) ? (
                <FaUserLock />
              ) : (isOngoing() || isInterrupted()) &&
                isAffected(el) ? (
                <span className="btn-detail green">
                  <FaUserCheck />
                </span>
              ) : isAffected(el) ? (
                <span
                  className="btn-detail red"
                  data-tip="Retirer"
                  onClick={() => {
                    matchProfile(el, "Retreive");
                  }}
                >
                  <FaUserTimes />
                </span>
              ) : (
                <span
                  className="btn-detail green"
                  data-tip="Affecter"
                  onClick={() => {
                    matchProfile(el, "Affect");
                  }}
                >
                  <FaUserCheck />
                </span>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }
  /* profiles matching */
  function matchProfile(el, type) {
    setSelectedItem({
      action: type,
      mission: oneMission.id,
      profil: el.profilId,
    });
    setTitleModal("Vous êtes sûr de vouloir " + (type === "Affect" ? "affecter" : "retirer") + " ce candidat ?");
    setShow(true);
  }
  /* get profile affected */
  function isAffected(candidat) {
    const listProfiles =
      oneMission &&
        oneMission.mission_profils &&
        oneMission.mission_profils?.profile.length
        ? oneMission.mission_profils?.profile.map((el) => el.id)
        : [];
    if (listProfiles.indexOf(candidat.profilId) !== -1) return true;
    else return false;
  }

  /* confirm Action*/
  function confirmAction() {
    const data = {
      mission: selectedItem.mission,
      profil: selectedItem.profil,
    };
    if (selectedItem.action === "Affect") affectProfileToMission(data);
    else removeProfileFromMission(data);
  }
  /* redirectTo Formateur Detail */
  function redirectTo(el) {
    history.push(t("role.administrator") + t("sidebar.inspirnautes.path") + "/" + el.userId);
  }
  /* return back to list missions */
  function returnBack() {
    history.push(t("role.administrator") + t("sidebar.missions.path"));
  }
  /* innher HTML */
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      {/* Toast */}
      <ToastService data={state.toastContent} />
      {/* Confirm Modal */}
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={titleModal}
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
      />
      {/* Mission */}
      <div id="main-without-header" className="main-without-header">
        {/* Mission Information */}
        <div className="arrow-back-mission pointer mb-3" onClick={returnBack}>
          <i className="icon-back-arrow"></i>
        </div>
        <div className="periode mb-1">
          Publié il y a{" "}
          {oneMission && oneMission.created_at
            ? getPeriode(oneMission?.created_at).periode +
            " " +
            getPeriode(oneMission?.created_at).type
            : null}
        </div>
        <div className="title-page">
          <span className="mr-3">{oneMission && oneMission.title ? oneMission.title : null}</span>
          {
            oneMission && oneMission.renewable
              ? <span className="text-detail ml-5">({oneMission.renewable})</span>
              : null
          }
        </div>
        <div className="sub-title-page mb-1">
          {oneMission ? oneMission.nbr_years_experience : 0} ans d'expriences
        </div>
        <label className="text-tag blue mb-4">
          {oneMission ? oneMission.mission_profils.length : 0} profils proposés
        </label>
        <div className="text-detail d-flex mb-2">
          <FaRegCalendarMinus />
          <span className="ml-10 mr-20">
            Du{" "}
            {oneMission && oneMission.date_start
              ? formatDate(oneMission.date_start)
              : null}
            {/* Au{" "}
            {oneMission && oneMission.date_end
              ? formatDate(oneMission.date_end)
              : null} */}
          </span>
        </div>
        <div className="text-detail d-flex">
          <FaMapMarkerAlt />
          <span className="ml-10 mr-20">
            {" "}
            {oneMission && oneMission.place ? oneMission.place : null}{" "}
          </span>
        </div>

        {/* Mission Details */}
        <div className="border-top mt-5 mb-3"></div>
        <div className="title-detail mb-2">Description du poste</div>
        <div
          className="color-black"
          dangerouslySetInnerHTML={
            oneMission && oneMission.description
              ? createMarkup(oneMission.description)
              : null
          }
        />
        <strong className="color-black mb-2">Compétences obligatoires</strong>
        {oneMission?.skills && oneMission.skills?.length ? (
          <>
            {oneMission.skills
              .filter((item) => item.priority === 1)
              .map((typeSkill, key) => {
                return (
                  <div key={key} className="margin-bottom-skills">
                    <div className="d-flex">
                      <label className="text-skill-label mr-10">
                        {typeSkill.name ? typeSkill.name : null}
                      </label>
                      <Rating
                        value={typeSkill.rating ? typeSkill.rating : 0}
                        className="rating-style"
                        disabled
                      />
                    </div>
                    )
                  </div>
                );
              })}
          </>
        ) : (
          <p className="text-skill-label text-center">
            Pas de résultat à afficher{" "}
          </p>
        )}
        <div className="mt-3">
          <strong className="color-black mb-2">
            {" "}
            Compétences supplémentaires
          </strong>
        </div>
        {oneMission?.skills && oneMission.skills?.length ? (
          <>
            {oneMission.skills
              .filter((item) => item.priority === 0)
              .map((typeSkill, key) => {
                return (
                  <div className="margin-bottom-skills" key={key}>
                    <div className="d-flex">
                      <label className="text-skill-label mr-10">
                        {typeSkill.name ? typeSkill.name : null}
                      </label>
                      <Rating
                        value={typeSkill.rating ? typeSkill.rating : 0}
                        className="rating-style"
                        disabled
                      />
                    </div>
                    )
                  </div>
                );
              })}
          </>
        ) : (
          <p className="text-skill-label text-center">
            Pas de résultat à afficher{" "}
          </p>
        )}
        {/* Profils List */}
        <div className="border-top mt-5 mb-3"></div>
        <div className="title-detail mb-2">Liste des candidats</div>
        {showMissionDetailAdmin()}
      </div>
    </>
  );
}
/* Export Detail Mission component */
export default DetailMission;
