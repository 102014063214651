import React from 'react';
import DayPicker from "react-day-picker";
import './Calendar.scss'

const MONTHS = [
    'Janvier',
    'février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
];
const WEEKDAYS_LONG = [
    'Dimanche',
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
];
const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];


export default function Calendar({selectedDates, selectedDatesStyles,renderDay} ) {
    return (
        <>
            <DayPicker className="agenda-picker w-100"
                       showOutsideDays
                       modifiers={selectedDates}
                       modifiersStyles={selectedDatesStyles}
                       locale="fr"
                       months={MONTHS}
                       weekdaysLong={WEEKDAYS_LONG}
                       weekdaysShort={WEEKDAYS_SHORT}
                       onDayClick={renderDay}    
            />
        </>
    );
};

