import React, { useReducer } from "react";
import {
  ADD_NEW_EXPERIENCE,
  DELETE_EXPERIENCE,
  EDIT_EXPERIENCE,
  SAVE_EXPERIENCE,
  DUMP_EXPERIENCE,
  RETRIEVE_COSMONAUTE,
  UPDATE_COSMONAUTE,
  UPDATE_COSMONAUTE_DESCRIPTION,
  ADD_NEW_EDUCATION,
  DELETE_EDUCATION,
  SAVE_EDUCATION,
  DUMP_EDUCATION,
  SAVE_LANGUAGES,
  DUMP_LANGUAGES,
  GET_ALL_SKILLS,
  SAVE_SKILLS,
  DUMP_SKILLS,
  UPLOAD_LOGO_CANDIDATE,
  GET_LOGO_CANDIDATE,
  DUMP_LOGO_CANDIDATE,
  COSMONAUTE_RESUME,
  GET_RESUME,
  GET_MISSIONS_ACCEPTED,
  GET_MISSIONS_ASSIGNEDS,
  GET_MISSIONS_PAST,
  GET_MISSIONS_DECLINED,
  GET_CURRENT_MISSIONS,
  GET_THREE_INTERVIEWS,
  GET_DECLINED_INTERVIEWS,
  GET_ALL_INTERVIEWS,
  DECLINE_INTERVIEW_COSMONAUTE,
  DUMP_INTERVIEW,
  ACCEPT_INTERVIEW_COSMONAUTE,
  CHANGE_DATE_COSMONAUTE,
  GET_DASHBOARD_DETAIL,
  GET_DASHBOARD_CALENDER,
  GET_ALL_MISSION,
  DECILNE_MESSION_COSMONAUTE,
  DUMP_MISSION,
  POURCENTAGE_CONDIDAT,
  ONE_MISSION_USER,
  POST_MISSION_FAVORITE,
  GET_MISSION_FAVORITE,
  GET_LANGUES,
  DUMP_FAVORITE,
  GET_ALL_TYPE_SKILLS,
  GET_ALL_WANTED_MISSION,
} from "../constants/Cosmonaute";
import { ALL_SKILLS_ADMIN, ALL_TYPE_SKILLS_ADMIN } from '../constants/Administrator';
import { getAllSkills } from '../actions/SkillsAdministrator';
import { CONTACT_ADMIN } from "../constants/ContactAdmin";
import { getAllTypeSkills } from "../../context/actions/TypeSkillsAdministrator";
import { SHOW_TOAST, HIDE_TOAST } from "../constants/Toast";
import {
  UpdateCosmonaute,
  ProfilCosmonaute,
  UpdateCosmonauteDecription,
  ResumeUpload, printResume, GetResume,
  pourcentageCondidat,
} from "../actions/ProfilCosmonaute";
import {
  logoUploadCandidate,
  getLogoCandidate,
  dumpLogoCandidate,
} from "../actions/LogoCosmonaute";
import {
  addExperience,
  editExperience,
  deleteExperience,
  saveExperience,
  dumpExperience,
} from "../actions/ExperienceCosmonaute";
import {
  addEducation,
  deleteEducation,
  saveEducation,
  dumpEducation,
} from "../actions/EducationCosmonaute";
import {
  getListSkills,
  saveSkillsCandidte,
  dumpSkillsCandidte,
} from "../actions/SkillsCosmonaute";
import {
  saveLanguesCandidte,
  getAllLangues,
  dumpLanguesCandidte,
} from "../actions/LangueCosmonaute";
import {
  UpcomingInterviews,
  AssignedMissions,
  GET_Missions_Accepted,
  PastMissions,
  DeclinedMissions,
  CurrentMissions,
  DeclinedInterviews,
  getDashboardDetail,
  getCalenderDetail,
  getAllMissions,
  declineMission,
  dumpMission,
  getOneMissionUser,
  postMissionFavorite,
  getMissionFavorite,
  dumpMissionFavorite,
  getAllWantedMissions,
} from "../actions/DashbordCosmonaute";
import {
  GET_ALL_INTERVIEW,
  declineInterview,
  dumpInterview,
  acceptInterviewCosmonaute,
  changeDateCosmonaute,
} from "../actions/InterviewsCosmonaute";
import { contactAdmin } from "../actions/ContactAdmn";
import { hideToast } from "../actions/Toast";
import { getListRegions } from "../actions/Regions";
import { saveTypeSkillsCandidte } from "../actions/CosmonauteTypeSkills";
import { addMission, deleteMission, editMission } from "../actions/MissionRecriter";

/* Cosmonaute Reducer */
const CosmonauteReducer = (state, action) => {
  switch (action.type) {
    /* Logo Candidate */
    case GET_LOGO_CANDIDATE:
      return { ...state, logoCandidate: action.payload, toastContent: null };
    case UPLOAD_LOGO_CANDIDATE:
      return {
        ...state,
        logo: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_LOGO_CANDIDATE:
      return { ...state, logo: null, toastContent: null };
    /* Profil candidate */
    case RETRIEVE_COSMONAUTE:
      return {
        ...state,
        CosmonauteProfil: action.payload,
        WorkExperiences: action.payload?.workExperiences,
        educationsCandidate: action.payload?.education,
        Langues: action.payload?.langues,
        skillsprofil: action.payload?.profilSkills,
        toastContent: null,
      };
    case UPDATE_COSMONAUTE:
      return {
        ...state,
        CosmonauteProfil: action.payload,
        toastContent: action.toastContent,
      };
    case UPDATE_COSMONAUTE_DESCRIPTION:
      return {
        ...state,
        CosmonauteProfil: action.payload,
        toastContent: action.toastContent,
      };
    /* EXPERIENCE */
    case ADD_NEW_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
        toastContent: action.toastContent,
      };
    case DELETE_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
        toastContent: action.toastContent,
      };
    case EDIT_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
        toastContent: action.toastContent,
      };
    case SAVE_EXPERIENCE:
      return {
        ...state,
        experience: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_EXPERIENCE:
      return { ...state, experience: null, toastContent: null };
    /* EDUCATION */
    case ADD_NEW_EDUCATION:
      return {
        ...state,
        educations: action.payload,
        toastContent: action.toastContent,
      };
    case DELETE_EDUCATION:
      return {
        ...state,
        educations: action.payload,
        toastContent: action.toastContent,
      };
    case SAVE_EDUCATION:
      return {
        ...state,
        educations: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_EDUCATION:
      return { ...state, educations: null, toastContent: null };
    /* CV */
    case GET_RESUME:
      return { ...state, resume: action.payload, toastContent: null };
    case COSMONAUTE_RESUME:
      return {
        ...state,
        toastContent: action.toastContent,
        cosmonauteresume: action.payload,
      };
    /* Languages */
    case SAVE_LANGUAGES:
      return {
        ...state,
        languesCandidate: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_LANGUAGES:
      return { ...state, languesCandidate: null, toastContent: null };
    /* Skills */
    case GET_ALL_SKILLS:
      return { ...state, allSkills: action.payload, toastContent: null };
    case SAVE_SKILLS:
      return {
        ...state,
        skillsCandidate: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_SKILLS:
      return { ...state, skillsCandidate: null, toastContent: null };

    case ALL_TYPE_SKILLS_ADMIN:
      return { ...state, allTypeSkills: action.payload };
      
    /* Dashboard */
    case GET_THREE_INTERVIEWS:
      return {
        ...state,
        interviews: action.payload.interviews,
        toastContent: null,
      };
    case GET_ALL_MISSION:
      return { ...state, allMissions: action.payload, toastContent: null };
    case GET_ALL_WANTED_MISSION:
      return { ...state, allWantedMissions: action.payload, toastContent: null };
    case GET_MISSIONS_ASSIGNEDS:
      return { ...state, AssignedMission: action.payload, toastContent: null };
    case GET_MISSIONS_ACCEPTED:
      return { ...state, AcceptedMission: action.payload, toastContent: null };
    case GET_MISSIONS_PAST:
      return { ...state, PastMission: action.payload, toastContent: null };
    case GET_MISSIONS_DECLINED:
      return { ...state, DeclinedMission: action.payload, toastContent: null };
    case GET_CURRENT_MISSIONS:
      return { ...state, CurrentMission: action.payload, toastContent: null };
    case GET_DECLINED_INTERVIEWS:
      return { ...state, DECLINED: action.payload, toastContent: null };
    /* Interviews */
    case GET_ALL_INTERVIEWS:
      return {
        ...state,
        allInterviewsCosmonaute: action.payload,
        toastContent: null,
      };
    case DECLINE_INTERVIEW_COSMONAUTE:
      return {
        ...state,
        interviewCosmonaute: action.payload,
        toastContent: action.toastContent,
      };
    case ACCEPT_INTERVIEW_COSMONAUTE:
      return {
        ...state,
        interviewCosmonaute: action.payload,
        toastContent: action.toastContent,
      };
    case CHANGE_DATE_COSMONAUTE:
      return {
        ...state,
        interviewCosmonaute: action.payload,
        toastContent: action.toastContent,
      };
    case GET_DASHBOARD_DETAIL:
      return {
        ...state,
        detailDashboard: action.payload,
        toastContent: action.toastContent,
      };
    case GET_DASHBOARD_CALENDER:
      return {
        ...state,
        calender: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_INTERVIEW:
      return { ...state, interviewCosmonaute: null, toastContent: null };
    /* */
    case DECILNE_MESSION_COSMONAUTE:
      return {
        ...state,
        missionCosmonaute: action.payload,
        toastContent: action.toastContent,
      };
    case ONE_MISSION_USER:
      return {
        ...state,
        oneMission: action.payload,
        toastContent: action.toastContent,
      };
    case CONTACT_ADMIN:
      return { ...state, toastContent: action.toastContent };
    case POURCENTAGE_CONDIDAT:
      return {
        ...state,
        pourcentage: action.payload,
        toastContent: action.toastContent,
      };
    case POST_MISSION_FAVORITE:
      return {
        ...state,
        missionFavorite: action.payload,
        toastContent: action.toastContent,
      };
    case DUMP_FAVORITE:
      return {
        ...state,
        missionFavorite: null,
        toastContent: null
      };
    case GET_MISSION_FAVORITE:
      return {
        ...state,
        missionFavoriteData: action.payload,
        toastContent: action.toastContent,
      };
    case GET_LANGUES:
      return {
        ...state,
        allLangues: action.payload,
        toastContent: action.toastContent,
      }
    case DUMP_MISSION:
      return { ...state, missionCosmonaute: null, toastContent: null };
    /* Skills */
    case ALL_SKILLS_ADMIN:
      return { ...state, listSkills: action.payload };
    /* Show | Hide Toast */
    case SHOW_TOAST:
      return { ...state, toastContent: action.toastContent };
    case HIDE_TOAST:
      return { ...state, toastContent: null };
    default:
      return state;
  }
};

/* Cosmonaute State */
const CosmonauteState = (reducer, actions, defaultValue) => {
  const Context = React.createContext();
  const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const boundActions = {};
    for (let key in actions) {
      boundActions[key] = actions[key](dispatch);
    }

    return (
      <Context.Provider value={{ state, ...boundActions }}>
        {children}
      </Context.Provider>
    );
  };
  return { Context, Provider };
};

/* Cosmonaute Provider Context */
export const { Provider, Context } = CosmonauteState(
  CosmonauteReducer,
  {
    ProfilCosmonaute,
    UpdateCosmonaute,
    UpdateCosmonauteDecription,
    addExperience,
    editExperience,
    deleteExperience,
    saveExperience,
    dumpExperience,
    addEducation,
    saveEducation,
    deleteEducation,
    dumpEducation,
    logoUploadCandidate,
    getLogoCandidate,
    dumpLogoCandidate,
    ResumeUpload, GetResume, printResume,
    saveLanguesCandidte,
    dumpLanguesCandidte,
    getListSkills,
    saveSkillsCandidte,
    saveTypeSkillsCandidte,
    dumpSkillsCandidte,
    AssignedMissions,
    GET_Missions_Accepted,
    UpcomingInterviews,
    PastMissions,
    DeclinedMissions,
    CurrentMissions,
    DeclinedInterviews,
    GET_ALL_INTERVIEW,
    declineInterview,
    dumpInterview,
    hideToast,
    acceptInterviewCosmonaute,
    changeDateCosmonaute,
    getDashboardDetail,
    getCalenderDetail,
    getAllMissions,
    getAllWantedMissions,
    declineMission,
    dumpMission,
    contactAdmin,
    pourcentageCondidat,
    getOneMissionUser,
    postMissionFavorite,
    getMissionFavorite,
    dumpMissionFavorite,
    getListRegions,
    getAllLangues,
    getAllSkills,
    getAllTypeSkills,
    editMission,
    deleteMission,
  },
  {
    toastContent: null,
    CosmonauteProfil: null,
    WorkExperiences: null,
    experience: null,
    educations: null,
    Langues: null,
    languesCandidate: null,
    allSkills: null,
    skillsCandidate: null,
    resume: null,
    allInterviewsCosmonaute: null,
    interviewCosmonaute: null,
    CurrentMission: null,
    DECLINED: null,
    detailDashboard: null,
    calender: null,
    allMissions: null,
    allWantedMissions: null,
    missionCosmonaute: null,
    pourcentage: null,
    oneMission: null,
    missionFavorite: null,
    missionFavoriteData: null,
    listSkills: null,
    allTypeSkills : null
  }
);
