import './InterviewsFilter.scss';
import { Row, Col, Form } from 'react-bootstrap';
import { FaSearch, FaMapMarkerAlt } from 'react-icons/fa';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { formatDate } from '../../services/Controller/DateFormatter';

export default function InterviewsFilter({ resetForm, setResetForm, defaultTabValue, allInterviews, setDisplayedData }) {
    /* Form Filter */
    const formFilter = useFormik({
        initialValues: {
            title: "",
            DateInterview: "",
            place: "",
        },
        onSubmit: values => {
            const filterData = allInterviews[defaultTabValue] ? allInterviews[defaultTabValue] : [];
            const list = filterData.filter((el) => filterCondition(values, el));
            setDisplayedData(list);
        }
    })

    /* reset Form */
    useEffect(() => {
        if (resetForm) {
            formFilter.resetForm();
            setResetForm(false);
        }
    }, [resetForm, setResetForm, formFilter]);

    /* All Conditions Filter */
    function filterCondition(FormValues, interview) {
        return (
            (FormValues.title === "" || (FormValues.title !== "" && interview.title?.toLowerCase()?.indexOf(FormValues.title) !== -1))
            && (FormValues.place === "" || (FormValues.place !== "" && interview.place?.toLowerCase()?.indexOf(FormValues.place) !== -1))
            && (FormValues.DateInterview === "" || (FormValues.DateInterview !== "" && formatDate(FormValues.DateInterview) === formatDate(interview.selectedDate)))
        )
    }

    return (
        <>
            <Form onChange={formFilter.handleSubmit}>
                <Row>
                    <Col lg={4} md={12} xs={12}>
                        <Form.Group className="position-relative">
                            <FaSearch className="position-absolute searsh-Input" />
                            <Form.Control className="input-filter" type="text" placeholder="Rechercher par titre"
                                name="title" id="title"
                                onChange={formFilter.handleChange}
                                value={formFilter.values.title.toLowerCase()}
                                onBlur={formFilter.handleBlur} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={12} xs={12}>
                        <Form.Group>
                            <Form.Control className="input-filter" type="date" name="DateInterview" id="DateInterview"
                                onChange={formFilter.handleChange}
                                value={formFilter.values.DateInterview}
                                onBlur={formFilter.handleBlur} />
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={12} xs={12}>
                        <Form.Group className="position-relative">
                            <FaMapMarkerAlt className="position-absolute searsh-Input" />
                            <Form.Control className="input-filter" type="text" placeholder="Rechercher par lieu"
                                name="place" id="place"
                                onChange={formFilter.handleChange}
                                value={formFilter.values.place.toLowerCase()}
                                onBlur={formFilter.handleBlur} />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

        </>
    );
};

