import "./ModalInterview.scss";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Card,
  InputGroup,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  isRecruiter,
  isCosmonaute,
} from "../../services/Controller/CurrentUserServices";
import {
  formatDate,
  formatTime,
  formatDateTime,
} from "../../services/Controller/DateFormatter";
import {
  ErrorFeild,
  ErrorClass,
} from "../../services/Controller/ValidationForm";
import { FaMapMarkerAlt, FaRegCalendarMinus } from "react-icons/fa";
import { format } from "date-fns";

/* Date of ToDay */
const today = new Date();
/* Validation Form Group Recruiter */
const validationInterviewDateCosmonaute = Yup.object({
  selectedDate: Yup.date().required("Date obligatoire"),
});

/* Validation Form Group Decline Inerview */
const validationInterviewForm = Yup.object({
  reason: Yup.string().required("Raison obligatoire"),
});

/* */
export default function ModalInterview({
  modalDateInterviewShow,
  setModalDateInterviewShow,
  mission,
  title,
  type,
  selectedItem,
  proposeInterviewRecruiter,
  changeInterviewRecruiter,
  declineInterviewRecruiter,
  acceptInterviewRecruiter,
  declineInterviewCosmonaute,
  acceptInterviewCosmonaute,
  changeDateCosmonaute,
}) {
  const isSameOrBefore = (startTime, endTime, date1, date2) => {
    if (date1 === date2) {
      return (startTime) !== ((endTime));
    }
    else {
      return true
    }
  }
  /* Validation Form Group Cosmonaute */
  const validationInterviewDateRecruiter = Yup.object({
    dateInterview1: Yup.date().required("Date obligatoire"),
    timeInterview1: Yup.string().required("Horaire obligatoire"),
    dateInterview2: Yup.date().required("Date obligatoire"),
    timeInterview2: Yup.string().test(
      'not empty',
      'Horaire obligatoire',
      function (value) {
        return !!value;
      }
    ).test(
      "start_time_test",
      "Les deux horaires doivent être différents",
      function (value) {
        const end_time = interviewDateFormRecruiter.values.timeInterview1;
        const date1 = interviewDateFormRecruiter.values.dateInterview1;
        const date2 = interviewDateFormRecruiter.values.dateInterview2;
        return isSameOrBefore(value, end_time, date1, date2);
      }
    ),
    link: Yup.string().required("Lien 1 obligatoire").url("Lien 1 invalide"),
    linkTwo: Yup.string().required("Lien 2 obligatoire").url("Lien 2 invalide"),
  });
  /* Form Group Recruiter */
  const interviewDateFormRecruiter = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateInterview1:
        selectedItem && selectedItem.firstDate
          ? formatDate(selectedItem.firstDate)
          : "",
      timeInterview1:
        selectedItem && selectedItem.firstDate
          ? formatTime((selectedItem.firstDate))
          : "",
      dateInterview2:
        selectedItem && selectedItem.secondDate
          ? formatDate(selectedItem.secondDate)
          : "",
      timeInterview2:
        selectedItem && selectedItem.secondDate
          ? formatTime(selectedItem.secondDate)
          : "",
      link: selectedItem && selectedItem.link ? selectedItem.link : "",
      linkTwo: selectedItem && selectedItem.linkTwo ? selectedItem.linkTwo : "",
    },
    validationSchema: validationInterviewDateRecruiter,
    onSubmit: (values) => {
      const data = {
        firstDate: values.dateInterview1 + " " + values.timeInterview1,
        secondDate: values.dateInterview2 + " " + values.timeInterview2,
        link: values.link,
        linkTwo: values.linkTwo,
      };
      if (type === "addInterview")
        proposeInterviewRecruiter(data, selectedItem.missionProfilId);
      else changeInterviewRecruiter(data, selectedItem.interviewId);
      setModalDateInterviewShow(false);
      interviewDateFormRecruiter.resetForm();
    },
  });

  /* Form Group Cosmonaute */
  const interviewDateFormCosmonaute = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedDate: "",
    },
    validationSchema: validationInterviewDateCosmonaute,
    onSubmit: (values) => {
      if (isRecruiter()) acceptInterviewRecruiter(values, selectedItem.interviewId);
      else acceptInterviewCosmonaute(values, selectedItem.interviewId);
      setModalDateInterviewShow(false);
      interviewDateFormCosmonaute.resetForm();
    },
  });

  /* Form Group Decline Interview */
  const declineInterviewForm = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: validationInterviewForm,
    onSubmit: (values) => {
      if (isRecruiter())
        declineInterviewRecruiter(values, selectedItem.interviewId);
      else {
        if (type === "changeDate")
          changeDateCosmonaute(values, selectedItem.interviewId);
        else declineInterviewCosmonaute(values, selectedItem.interviewId);
      }
      setModalDateInterviewShow(false);
      declineInterviewForm.resetForm();
    },
  });

  /* Form Recruiter */
  function FormInterviewRecruiter() {
    return (
      <>
        {/* Form Save interview Recruiter */}
        <Form onSubmit={interviewDateFormRecruiter.handleSubmit}>
          <Modal.Body>
            {/* Card of Interview information */}
            <DetailMission />
            {/* Link 1 interview */}
            <Row className="mb-2 mt-5">
              <Col id="url-input">
                <Form.Group>
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="required">URL 1</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="link"
                      id="link"
                      onChange={interviewDateFormRecruiter.handleChange}
                      className={ErrorClass(interviewDateFormRecruiter, "link")}
                      value={interviewDateFormRecruiter.values.link}
                      onBlur={interviewDateFormRecruiter.handleBlur}
                      placeholder="Entrez le lien de votre réunion en ligne ( Skype, Zoom, Meet etc. )"
                    />
                  </InputGroup>
                  {ErrorFeild(interviewDateFormRecruiter, "link")}
                </Form.Group>
              </Col>
            </Row>
            {/* Link 2 interview */}
            <Row className="mb-2 mt-5">
              <Col id="url-input">
                <Form.Group>
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="required">URL 2</InputGroup.Text>
                    <Form.Control
                      type="text"
                      name="linkTwo"
                      id="linkTwo"
                      onChange={interviewDateFormRecruiter.handleChange}
                      className={ErrorClass(interviewDateFormRecruiter, "linkTwo")}
                      value={interviewDateFormRecruiter.values.linkTwo}
                      onBlur={interviewDateFormRecruiter.handleBlur}
                      placeholder="Entrez le lien de votre réunion en ligne ( Skype, Zoom, Meet etc. )"
                    />
                  </InputGroup>
                  {ErrorFeild(interviewDateFormRecruiter, "linkTwo")}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="text-Label-modal required">
                    Date 1
                  </Form.Label>
                  <Form.Control
                    className={ErrorClass(
                      interviewDateFormRecruiter,
                      "dateInterview1"
                    )}
                    type="date"
                    name="dateInterview1"
                    id="dateInterview1"
                    min={format(today, "yyyy-MM-dd")}
                    onChange={interviewDateFormRecruiter.handleChange}
                    onBlur={interviewDateFormRecruiter.handleBlur}
                    value={interviewDateFormRecruiter.values.dateInterview1}
                  />
                  {ErrorFeild(interviewDateFormRecruiter, "dateInterview1")}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="text-Label-modal required">
                    temps 1
                  </Form.Label>
                  <Form.Control
                    className={ErrorClass(
                      interviewDateFormRecruiter,
                      "timeInterview1"
                    )}
                    type="time"
                    name="timeInterview1"
                    id="timeInterview1"
                    min="08:00"
                    onChange={interviewDateFormRecruiter.handleChange}
                    onBlur={interviewDateFormRecruiter.handleBlur}
                    value={interviewDateFormRecruiter.values.timeInterview1}
                  />
                  {ErrorFeild(interviewDateFormRecruiter, "timeInterview1")}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="text-Label-modal required">
                    Date 2
                  </Form.Label>
                  <Form.Control
                    className={ErrorClass(
                      interviewDateFormRecruiter,
                      "dateInterview2"
                    )}
                    type="date"
                    name="dateInterview2"
                    id="dateInterview2"
                    min={format(today, "yyyy-MM-dd")}
                    onChange={interviewDateFormRecruiter.handleChange}
                    onBlur={interviewDateFormRecruiter.handleBlur}
                    value={interviewDateFormRecruiter.values.dateInterview2}
                  />
                  {ErrorFeild(interviewDateFormRecruiter, "dateInterview2")}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="text-Label-modal required">
                    temps 2
                  </Form.Label>
                  <Form.Control
                    className={ErrorClass(
                      interviewDateFormRecruiter,
                      "timeInterview2"
                    )}
                    type="time"
                    name="timeInterview2"
                    id="timeInterview2"
                    min="08:00"
                    max="18:00"
                    onChange={interviewDateFormRecruiter.handleChange}
                    onBlur={interviewDateFormRecruiter.handleBlur}
                    value={interviewDateFormRecruiter.values.timeInterview2}
                  />
                  {ErrorFeild(interviewDateFormRecruiter, "timeInterview2")}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {SaveInterviewFooter()}
          </Modal.Footer>
        </Form>
      </>
    );
  }

  /* Form Cosmonaute */
  function FormAcceptInterviewCandidat() {
    return (
      <Form onSubmit={interviewDateFormCosmonaute.handleSubmit}>
        <Modal.Body>
          {/* Card of Interview information */}
          <DetailMission />
          {/* Form Save interview Candidate */}
          <Form.Group className="mt-5 mb-2">
            <Form.Label className="text-Label-modal required">
              Choisir Date Interview{" "}
            </Form.Label>
            <Form.Select
              className={ErrorClass(interviewDateFormCosmonaute, "selectedDate")}
              name="selectedDate"
              id="selectedDate"
              onChange={interviewDateFormCosmonaute.handleChange}
              onBlur={interviewDateFormCosmonaute.handleBlur}
              value={interviewDateFormCosmonaute.values.selectedDate}
            >
              <option key="0" value="" defaultValue>
                Selectionnez une date
              </option>
              <option key="1" value={selectedItem?.firstDate}>
                {formatDateTime(selectedItem?.firstDate)}
              </option>
              <option key="2" value={selectedItem?.secondDate}>
                {formatDateTime(selectedItem?.secondDate)}
              </option>
            </Form.Select>
            {ErrorFeild(interviewDateFormCosmonaute, "selectedDate")}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <SaveInterviewFooter />
        </Modal.Footer>
      </Form>
    );
  }

  /* Form Cosmonaute */
  function FormDeclineInterview() {
    return (
      <Form onSubmit={declineInterviewForm.handleSubmit}>
        <Modal.Body>
          {/* Card of Interview information */}
          <DetailMission />
          {/* Form Decline */}
          <Form.Group className="mt-5 mb-2">
            {type === "changeDate" ? (
              <Form.Label className="text-Label-modal required">
                Raison du changement de date
              </Form.Label>
            ) : (
              <Form.Label className="text-Label-modal required">
                Raison du refus
              </Form.Label>
            )}
            <Form.Control
              className={ErrorClass(declineInterviewForm, "reason")}
              as="textarea"
              rows={6}
              name="reason"
              id="reason"
              placeholder="Saisir votre commentaire ..."
              onChange={declineInterviewForm.handleChange}
              onBlur={declineInterviewForm.handleBlur}
              value={declineInterviewForm.values.reason}
            />
            {ErrorFeild(declineInterviewForm, "reason")}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <DeclineInterviewFooter />
        </Modal.Footer>
      </Form>
    );
  }

  /* Detail Mission */
  function DetailMission() {
    return (
      <Card className="card-modal">
        <Card.Body>
          <label className="text-header-modal">
            {mission && mission.title ? mission.title : "- - -"}
          </label>
          <p className="text-subheader-modal">
            {mission && mission.entreprise && mission.entreprise.company_name
              ? mission.entreprise.company_name
              : "- - -"}
          </p>
          <div className="text-detail d-flex mb-1">
            <FaRegCalendarMinus />
            <span className="ml-10 mr-20">
              Du{" "}
              {mission && mission.date_start
                ? formatDate(mission.date_start)
                : "- - -"}
              {" "} Au{" "}
              {mission && mission.date_end
                ? formatDate(mission.date_end)
                : "- - -"}
            </span>
          </div>
          <div className="text-detail d-flex mb-2">
            <FaMapMarkerAlt />
            <span className="ml-10 mr-20">
              {" "}
              {mission && mission.place ? mission.place : "- - -"}{" "}
            </span>
          </div>
        </Card.Body>
      </Card>
    );
  }

  /* Footer Decline */
  function DeclineInterviewFooter() {
    return (
      <>
        {type === "changeDate" ? (
          <>
            <Button
              className="save-button green shadow-unset mr-64"
              type="submit"
            >
              Envoyer
            </Button>
          </>
        ) : (
          <>
            <Button
              className="save-button red shadow-unset mr-64"
              type="submit"
            >
              Confirmer
            </Button>
            <Button
              className="save-button blue shadow-unset"
              type="button"
              onClick={() => {
                setModalDateInterviewShow(false);
                declineInterviewForm.resetForm();
              }}
            >
              Annuler
            </Button>
          </>
        )}
      </>
    );
  }

  /* Footer Save */
  function SaveInterviewFooter() {
    return (
      <Button className="save-button green shadow-unset" type="submit">
        Sauvegarder
      </Button>
    );
  }

  return (
    <>
      <Modal
        show={modalDateInterviewShow}
        size="lg"
        centered
        onHide={() => {
          setModalDateInterviewShow(false);
          interviewDateFormRecruiter.resetForm();
          interviewDateFormCosmonaute.resetForm();
          declineInterviewForm.resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="modal-title"
          >
            {" "}
            {title}{" "}
          </Modal.Title>
        </Modal.Header>
        {/* Modal Content */}
        {type === "decline" || (type === "changeDate" && isCosmonaute())
          ? FormDeclineInterview()
          : isRecruiter()
            ? FormInterviewRecruiter()
            : FormAcceptInterviewCandidat()}
      </Modal>
    </>
  );
}
