import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import { FaRegCalendarMinus, FaMapMarkerAlt, FaTrashAlt } from "react-icons/fa";
import { Context as RecruiterContext } from "../../../context/reducers/RecruiterReducer";
import ModalInterview from "../../../components/Interview/ModalInterview";
import NothingToShow from "../../../components/NothingToShow/NothingToShow";
import ModalConfirm from "../../../components/ModalConfirm/ModalConfirm";
import Paginate from "../../../components/Paginate/Paginate";
import AvatarGroup from "../../../components/avatar/avatarGroup";
import { formatDate, formatTime, getPeriode } from "../../../services/Controller/DateFormatter";
import { getStatus } from "../../../services/Controller/MissionServices";
import { Rating } from "@mui/material";
import "./DetailMissionRecruiter.scss";
import AlertModal from "../../../components/alert/alert";

/* Detail Mission Page */
function DetailMissionRecruiter() {
  /* use Translation */
  const { t } = useTranslation();

  /* use History */
  const history = useHistory();

  /* use Location */
  const location = useLocation();

  /* Context API */
  const {
    getOneMissionRecruiter,
    getProposedProfilesMission,
    removeProfileFromMission,
    dumpMission,
    rejectCandidate,
    recruteCandidate,
    proposeInterviewRecruiter,
    changeInterviewRecruiter,
    declineInterviewRecruiter,
    dumpInterview,
    state,
  } = useContext(RecruiterContext);

  /* Mission Detail */
  const [oneMission, setOneMission] = useState(null);

  /* eslint-disable */
  useEffect(() => {
    const id = location.pathname.split("/")[3];
    getOneMissionRecruiter(id);
    getProposedProfilesMission(id);
  }, []);
  /* eslint-enable */

  useEffect(() => {
    if (state.oneMission) setOneMission(state.oneMission);
    if (state.allProposedProfils) setDisplayedData(state.allProposedProfils);
  }, [state]);

  /* refresh after remove canidate from mission */
  useEffect(() => {
    if (state.mission) {
      const id = location.pathname.split("/")[3];
      getOneMissionRecruiter(id);
      getProposedProfilesMission(id);
      dumpMission();
    }
  }, [
    dumpMission,
    getOneMissionRecruiter,
    getProposedProfilesMission,
    state.mission,
    location,
  ]);

  /* refresh after propose| change Interview */
  useEffect(() => {
    if (state.interview) {
      const id = location.pathname.split("/")[3];
      getOneMissionRecruiter(id);
      getProposedProfilesMission(id);
      dumpInterview();
    }
  }, [
    dumpInterview,
    getOneMissionRecruiter,
    getProposedProfilesMission,
    state.interview,
    location,
  ]);
  /* States */
  /* Table Content */
  const displayedColumns = [
    "Candidat",
    "Expérience",
    "État candidat",
    "Date entretien",
    "État entretien",
    "État recrutement",
    "",
  ];
  const [displayedData, setDisplayedData] = useState([]);
  /* displayed items by pagination*/
  const [currentItems, setCurrentItems] = useState([]);
  /* Confirm Modal show|hide */
  const [show, setShow] = useState(false);
  /* Interview modal show|hide */
  const [modalDateInterviewShow, setModalDateInterviewShow] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  /* select Item */
  const [selectedItem, setSelectedItem] = useState(null);
  /* title Modal */
  const [titleModal, setTitleModal] = useState("");
  /* proposed interview */
  const [showed, setShowed] = useState(false)
  const [showedMode, setShowedMode] = useState(false)
  /* Hide Alert */
  useEffect(() => {
    if (state.toastContent?.body === "Entretien est ajouté avec succès") {
      setShowed(true)
    }
    else if (state.toastContent?.body === "Entretien est modifié avec succès") {
      setShowedMode(true)
    }
  }, [state.toastContent]);

  /* Content Missions Detail For Recruiter */
  function showMissionDetailRecruiter() {
    return (
      <div className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xl mt-5">
        <Table responsive="sm md" className="w-100">
          <thead>
            <tr className="table-Header">
              {displayedColumns.map((el, index) => {
                return <th key={"Column" + index}>{el}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {displayedData && displayedData.length
              ? getListProfilsRecruiter()
              : null}
          </tbody>
        </Table>
        {!displayedData || !displayedData.length
          ? <NothingToShow type="array" />
          : <Paginate items={displayedData} setCurrentItems={setCurrentItems} itemsPerPage={10} />
        }
      </div>
    );
  }
  /* list Missions Mapping */
  function getListProfilsRecruiter() {
    return currentItems.map((el, index) => {
      return (
        <tr className="table-content table-padding" key={"Profil" + index}>
          <td className="w-350">
            <AvatarGroup size="md" profils={[el]} />
            {state.oneMission?.is_interrupted ? <span
              className="ml-20"
            >
              {el.missionStatus === "recruited"
                ? el.name + " " + el.lastname
                : el.name?.charAt(0).toUpperCase() +
                " " +
                el.lastname?.charAt(0).toUpperCase()}
            </span> : <span
              className="ml-20 link"
              onClick={() => {
                redirectTo(el);
              }}
            >
              {el.missionStatus === "recruited"
                ? el.name + " " + el.lastname
                : el.name?.charAt(0).toUpperCase() +
                " " +
                el.lastname?.charAt(0).toUpperCase()}
            </span>}
          </td>
          <td className="w-150">
            <p>{el.nbrYearsExp ? el.nbrYearsExp : 0} ans</p>
          </td>
          <td className="w-200">
            {el.missionStatus ? (
              <label
                className={"text-tag " + getStatus(el.missionStatus)?.class}
              >
                {getStatus(el.missionStatus)?.label}
              </label>
            ) : (
              null
            )}
          </td>
          <td className="w-250">{state.oneMission?.is_interrupted ? null : getpossibleAction(el)}</td>
          <td className="w-200">
            {el.missionStatus === "recruited" || el.missionStatus === "rejected"
              ? null
              : el.InterviewStatus
                ?
                <label className={"text-tag " + getStatus(el.InterviewStatus)?.class}>
                  {getStatus(el.InterviewStatus)?.label}
                </label>
                : null
            }
          </td>
          <td className="w-350">
            {el.missionStatus !== "recruited" &&
              el.missionStatus !== "rejected"
              ?
              <div className="d-flex">
                <Button type="button" className="mission-recrute green mr-20" disabled={el.InterviewStatus !== "passed"} onClick={() => { selectItem(el, "recruter"); }} >
                  Oui, je recrute
                </Button>
                <Button type="button" className="mission-recrute red" disabled={el.InterviewStatus !== "passed"} onClick={() => { selectItem(el, "rejeter"); }}>
                  Non, je rejette
                </Button>
              </div>
              : null}
          </td>
          <td>
            {el.missionStatus === "pending"
              ?
              <div className="remove-mission pointer mb-3 float-right" disabled={el.missionStatus !== "pending"} onClick={() => { selectItem(el, "retirer"); }}>
                <FaTrashAlt />
              </div>
              : null}
          </td>
        </tr>
      );
    });
  }
  /* get possible Action by status */
  function getpossibleAction(interview) {
    if (
      interview.missionStatus === "recruited" ||
      interview.missionStatus === "rejected"
    )
      return null;
    else {
      if (
        !interview.InterviewStatus ||
        interview.InterviewStatus === "rejected"
      ) {
        return (
          <p
            className="propose-interview pointer"
            onClick={() =>
              openModalInterview(
                interview,
                "Proposer entretien",
                "addInterview"
              )
            }
          >
            Proposer entretien
          </p>
        );
      } else if (
        interview.InterviewStatus === "pending" ||
        interview.InterviewStatus === "change_date"
      ) {
        return (
          <>
            <div className="date-interview">
              Le {formatDate(interview.firstDate) + " à " + formatTime(interview.firstDate)}
            </div>
            <div className="date-interview">
              Le {formatDate(interview.secondDate) + " à " + formatTime(interview.secondDate)}
            </div>
            <p className="propose-interview pointer"
              onClick={() => openModalInterview(interview, "Changer date entretien", "changeDate")}
            >
              Modifier entretien
            </p>
          </>
        );
      } else if (interview.InterviewStatus === "accepted") {
        return (
          <>
            <div className="date-interview">
              Le {formatDate(interview.selectedDate) + " à " + formatTime(interview.selectedDate)}
            </div>
            <p className="propose-interview pointer"
              onClick={() => openModalInterview(interview, "Rejeter entretien", "decline")}>
              Rejeter entretien
            </p>
          </>
        );
      } else return null;
    }
  }

  /* Open Modal Interview */
  function openModalInterview(el, title, type) {
    setSelectedItem(el);
    setTitleModal(title);
    setTypeModal(type);
    setModalDateInterviewShow(true);
  }

  /* redirectTo Formateur Detail */
  function redirectTo(el) {
    if (el.userId) {
      history.push({
        pathname: t("role.recruiter") + t("sidebar.inspirnautes.path") + "/" + el.userId,
        state: { idMission: oneMission.id },
      });
    }
  }

  /* recrute | reject | Remove */
  function selectItem(el, type) {
    if (oneMission && oneMission.id && el.missionProfilId && !state.oneMission?.is_interrupted) {
      setSelectedItem({
        action: type,
        mission: oneMission.id,
        profil: el.missionProfilId,
      });
      setTitleModal("Vous êtes sûr de vouloir " + type + " ce candidat ?");
      setShow(true);
    }
  }

  /* confirm Action*/
  function confirmAction() {
    switch (selectedItem.action) {
      case "retirer":
        removeProfileFromMission(selectedItem.profil);
        break;
      case "recruter":
        recruteCandidate(selectedItem.profil);
        break;
      case "rejeter":
        rejectCandidate(selectedItem.profil);
        break;
      default:
        return null;
    }
  }

  /* return back to list missions */
  function returnBack() {
    history.goBack();
  }
  /* innher HTML */
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      <AlertModal show={showed} setShow={setShowed} content={"Entretien ajouté avec succès"} />
      <AlertModal show={showedMode} setShow={setShowedMode} content={"Entretien modifié avec succès"} />
      {/* Confirm Modal */}
      <ModalConfirm
        show={show}
        setShow={setShow}
        message={titleModal}
        setSelectedItem={setSelectedItem}
        Action={confirmAction}
      />

      {/* Interview Modal */}
      <ModalInterview
        title={titleModal}
        type={typeModal}
        mission={oneMission}
        selectedItem={selectedItem}
        modalDateInterviewShow={modalDateInterviewShow}
        setModalDateInterviewShow={setModalDateInterviewShow}
        proposeInterviewRecruiter={proposeInterviewRecruiter}
        changeInterviewRecruiter={changeInterviewRecruiter}
        declineInterviewRecruiter={declineInterviewRecruiter}
      />

      {/* Mission */}
      <div id="main-without-header" className="main-without-header">
        {/* Mission Information */}
        <div className="arrow-back-mission pointer mb-3" onClick={returnBack}>
          <i className="icon-back-arrow"></i>
        </div>
        <div className="periode mb-1">
          Publié il y a
          {oneMission && oneMission.created_at ? " " + getPeriode(oneMission?.created_at).periode + " " + getPeriode(oneMission?.created_at).type : " - - -"}
        </div>
        <div className="title-page">
          <span className="mr-3">{oneMission && oneMission.title ? oneMission.title : null}</span>
          {
            oneMission && oneMission.renewable
              ? <span className="text-detail ml-5">({oneMission.renewable})</span>
              : null
          }
        </div>
        <div className="sub-title-page mb-1">
          <span className="mr-3">
            {oneMission && oneMission.nbr_years_experience ? oneMission.nbr_years_experience : 0}
          </span> ans d'expriences
        </div>
        <label className="text-tag blue mb-4">
          {oneMission && oneMission.mission_profils ? oneMission.mission_profils.length : 0} profiles proposés
        </label>
        <div className="text-detail d-flex mb-2">
          <FaRegCalendarMinus />
          <span className="ml-10 mr-20">
            Du {oneMission && oneMission.date_start ? formatDate(oneMission.date_start) : null}
            {" "}Au {oneMission && oneMission.date_end ? formatDate(oneMission.date_end) : null}
          </span>
        </div>
        <div className="text-detail d-flex">
          <FaMapMarkerAlt />
          <span className="ml-10 mr-20">
            {oneMission && oneMission.place ? oneMission.place : null}
          </span>
        </div>

        {/* Mission Details */}
        <div className="border-top mt-5 mb-3"></div>
        <div className="title-detail mb-2">Description du poste</div>
        {oneMission && oneMission.description
          ? <div className="color-black" dangerouslySetInnerHTML={createMarkup(oneMission.description)} />
          : <NothingToShow />
        }
        <strong className="color-black mb-2">Compétences obligatoires</strong>
        {oneMission?.skills && oneMission.skills?.length ? (
          <>
            {oneMission.skills
              .filter((item) => item.priority === 1)
              .map((typeSkill, key) => {
                return (
                  <div key={key} className="margin-bottom-skills">
                    <div className="d-flex">
                      <label className="text-skill-label mr-10">
                        {typeSkill.name ? typeSkill.name : null}
                      </label>
                      <Rating
                        value={typeSkill.rating ? typeSkill.rating : 0}
                        className="rating-style"
                        disabled
                      />
                    </div>
                    )
                  </div>
                );
              })}
          </>
        ) : (
          <p className="text-skill-label text-center">
            Pas de résultat à afficher{" "}
          </p>
        )}
        <strong className="color-black mb-2">
          Compétences supplémentaires
        </strong>
        {oneMission?.skills && oneMission.skills?.length ? (
          <>
            {oneMission.skills
              .filter((item) => item.priority === 0)
              .map((typeSkill, key) => {
                return (
                  <div className="margin-bottom-skills" key={key}>
                    <div className="d-flex">
                      <label className="text-skill-label mr-10">
                        {typeSkill.name ? typeSkill.name : null}
                      </label>
                      <Rating
                        value={typeSkill.rating ? typeSkill.rating : 0}
                        className="rating-style"
                        disabled
                      />
                    </div>
                    )
                  </div>
                );
              })}
          </>
        ) : (
          <p className="text-skill-label text-center">
            Pas de résultat à afficher{" "}
          </p>
        )}
        {/* Profils List */}
        <div className="border-top mt-5 mb-3"></div>
        <div className="title-detail mb-2">Liste des candidats</div>
        {showMissionDetailRecruiter()}
      </div>
    </>
  );
}

/* Export Detail Mission component */
export default DetailMissionRecruiter;
